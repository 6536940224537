import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    maxWidth: { xs: 'unset', md: '28.125rem' },
    height: { xs: '100vh', md: '40rem' },
    maxHeight: { xs: '100vh', md: 'calc(100vh - 2rem)' },
    position: 'fixed',
    top: { xs: 0, md: '50%' },
    left: { xs: 0, md: '50%' },
    transform: { xs: 'unset', md: 'translate(-50%, -50%)' },
    borderRadius: { xs: 0, md: '0.5rem' },
    backgroundColor: (th) => th.palette.surface.default,
    transition: 'opacity 0.3s',
    zIndex: 6,
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    backgroundColor: (th) => th.palette.surface.default,
    borderBottom: (th) => `1px solid ${th.palette.surface.paper}`,
    px: '1rem',
    py: '1rem',
    zIndex: 2,
  },
  backdrop: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#00000021',
    transition: 'opacity 0.3s',
    zIndex: 6,
  },
});

export default styles;
