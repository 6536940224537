import { STATE_CONFIRMED_ASSIGNED } from "@/pages/inspection-job-details-dynamic/configs";
import { DoneOrders } from "@/services/api/inspection-jobs";
import cookie from "@/utils/cookie";
import { siteUrl, authorizationCookieKey } from "@/utils/env";
import { makeUrl } from "@/utils/routing";
import {
  Button,
  Chip,
  Divider,
  Modal,
  Stack,
  Typography,
  alpha
} from "@mui/material";
import { useEffect, useState, type FC } from "react";
import { useNavigate } from "react-router";

const JobDoneItem: FC<DoneOrders> = (props) => {
  const tokenOfCookie = cookie.get<string>(authorizationCookieKey);
  const {
    visit_place: address,
    brand_model: car,
    id,
    selected_package_name: inspectionPackage,
    expert_share: share,
    state,
    done_at,
    visit_city,
    customer_name,
    phone_number
  } = props;

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen)
      setTimeout(() => {
        const iframe = document.querySelector("iframe");
        iframe?.contentWindow?.postMessage(
          { key: "karnameh-access-token", value: tokenOfCookie },
          siteUrl
        );
      }, 5000);
  }, [isModalOpen, tokenOfCookie]);

  return (
    <Stack
      spacing={2}
      sx={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.16)",
        p: 2,
        borderRadius: 2
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="1rem" fontWeight={600}>
          {done_at ? new Date(done_at).toLocaleDateString("fa-IR") : "-"}
        </Typography>

        {state !== STATE_CONFIRMED_ASSIGNED ? (
          <Chip
            size="small"
            sx={(theme) => ({
              borderRadius: 8,
              paddingRight: 1,
              paddingLeft: 1,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              height: theme.spacing(4)
            })}
            label={id}
          />
        ) : null}
      </Stack>

      <Typography>{address}</Typography>
      <Typography>{car}</Typography>
      <Typography>{inspectionPackage}</Typography>
      <Typography>
        سهم شما:{" "}
        <Typography component="span" fontWeight={600}>
          {typeof share === "number"
            ? `${share.toLocaleString("fa-IR")} تومان`
            : share}
        </Typography>
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setIsModalOpen(true);
            // window.open(
            //  `https://karnameh.com/inspection-report?inspection_token=${props.car_inspection_token}`,
            // "_parent"
            //);
          }}
        >
          مشاهده‌ی گزارش
        </Button>
        <Button
          // size="large"
          onClick={() => {
            navigate(
              makeUrl(
                `/inspection/inspection-jobs-dynamic/new-order?city_id=${visit_city}&phone_number=${phone_number}&fullname=${customer_name}`
              )
            );
          }}
          sx={{ flex: 1 }}
        >
          درخواست مجدد
        </Button>
      </Stack>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            height: "90%",
            p: 2,
            borderRadius: theme.spacing(1),
            maxWidth: "512px"
          })}
          gap={1}
        >
          <iframe
            id="inspection-report"
            style={{
              height: "-webkit-fill-available",
              border: "none"
            }}
            src={`${siteUrl}/inspection-report?inspection_token=${props.car_inspection_token}`}
          />
          <Stack width={"100%"} direction={"row"} gap={1}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => setIsModalOpen(false)}
            >
              بستن
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default JobDoneItem;
