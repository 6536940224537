import createStyle from "@/utils/createStyle";

const styles = createStyle({
  backdrop: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: "#0000006b",
    transition: "opacity 0.3s",
    zIndex: 100
  },
  container: {
    width: "100%",
    maxHeight: { xs: "100vh", md: "calc(100vh - 2rem)" },
    backgroundColor: (th) => th.palette.surface.default,
    borderBottomLeftRadius: { xs: "0", md: "1.5rem" },
    borderBottomRightRadius: { xs: "0", md: "1.5rem" },
    position: "absolute",
    left: { xs: 0, md: "50%" },
    top: { xs: "unset", md: "50%" },
    bottom: { xs: "0", md: "unset" },
    transition: "opacity 0.3s, transform 0.3s",
    overflow: "auto",
    zIndex: 100
  }
});

export default styles;
