import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: '0.75rem',
    cursor: 'pointer',
  },
});

export default styles;
