import { IconButton, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

type PropType = {
  title: string;
  value?: string | number;
  icon?: ReactNode;
  iconOnClick?: () => void;
};

const CustomerReportDetailCard: FC<PropType> = ({
  title,
  value,
  icon,
  iconOnClick
}) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      bgcolor={"white"}
      p={1}
      border={"1px solid #EBEBEB"}
      borderRadius={"6px"}
      height={"100%"}
    >
      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        <Typography
          sx={{
            color: (th) => th.palette.success.main
          }}
          fontSize={"20px"}
          fontWeight={600}
        >
          {value}
        </Typography>
        <Typography
          fontSize={"12px"}
          fontWeight={400}
          sx={{
            textWrap: "nowrap"
          }}
        >
          {title}
        </Typography>
      </Stack>
      {icon && (
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            iconOnClick && iconOnClick();
          }}
        >
          {icon}
        </IconButton>
      )}
    </Stack>
  );
};

export default CustomerReportDetailCard;
