import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";
import { useBottomSheet } from "@/components/BottomSheetModal/configs";
import { FC, ReactNode } from "react";

interface Props {
  title: string;
  children?: ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  withTopSpace?: boolean;
}

const BottomSheetHeader: FC<Props> = ({
  children,
  title,
  withTopSpace = false
}) => {
  const { close } = useBottomSheet();

  return (
    <Stack
      borderBottom={(t) => `1px solid ${t.palette.surface.paper}`}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      pb="0.5rem"
      position="sticky"
      top="0"
      zIndex={2}
      sx={{ backgroundColor: (th) => th.palette.common.white }}
      pt={{ xs: withTopSpace ? "4rem" : "0.75rem", md: "0.75rem" }}
    >
      <Stack flexDirection="row" alignItems="center">
        <IconButton onClick={close}>
          <CloseIcon sx={{ color: (t) => t.palette.iconDefault.main }} />
        </IconButton>
        <Typography fontSize={16} fontWeight={600} ml={0.75}>
          {title}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center">
        {children}
      </Stack>
    </Stack>
  );
};

export default BottomSheetHeader;
