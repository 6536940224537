import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    py: '1rem',
    px: '1rem',
  },
  scrollableZone: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  itemContainer: {
    mr: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    ':last-of-type': {
      mr: 'unset',
    },
  },
});

export default styles;
