import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { CompareArrowsRounded } from "@mui/icons-material";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import RestoreIcon from "@mui/icons-material/Restore";

export interface ConcierSaleMenuItems {
  title: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | null;
  path: string;
  is_external_link: boolean;
  is_login_divar?: boolean;
}

export const conciergeSaleMenu: ConcierSaleMenuItems[] = [
  {
    title: "آفرها",
    icon: AddTaskIcon,
    path: "/concierge-sale/offers",
    is_external_link: false
  },
  {
    title: "لیست لیدها",
    icon: AssignmentOutlinedIcon,
    path: "/concierge-sale/requests",
    is_external_link: false
  },
  {
    title: "اتصال به اکانت دیوار",
    icon: CompareArrowsRounded,
    path: "/concierge-sale/divar-login",
    is_external_link: false,
    is_login_divar: true
  },
  {
    title: "گزارش عملکرد",
    icon: TimelineIcon,
    path: "/concierge-sale/assistant-performance-report",
    is_external_link: false
  },
  {
    title: "نظرسنجی و امتیازات",
    icon: StarsOutlinedIcon,
    path: "/concierge-sale/customer-report",
    is_external_link: false
  },
  {
    title: "پیام‌ها",
    icon: RestoreIcon,
    path: "/notification",
    is_external_link: false
  }
  // {
  //   title: "استعلام سوابق",
  //   icon: RestoreOutlinedIcon,
  //   path: "https://karnameh.com/services/car-authentication/car-info",
  //   is_external_link: true
  // }
];
