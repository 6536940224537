import { httpAPIGW } from "@/services/http-clients";
import { postStorageEndPoints } from "@/services/http-clients/configs";
import { UseQueryOptions, useQuery } from "react-query";

//#region Get Carpost State

interface GetCarPostStateResponse {
  label: string;
  value: string;
}

const getCarPostState = async () => {
  return httpAPIGW<GetCarPostStateResponse[]>({
    method: "GET",
    url: postStorageEndPoints.CAR_POST_STATES
  }).then((res) => {
    return res.data;
  });
};

const getCarPostStateKey = "getCarPostState";

export const useGetCarPostState = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCarPostState>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getCarPostState>>
  >
) => {
  return useQuery({
    queryKey: [getCarPostStateKey],
    staleTime: 0,
    queryFn: () => {
      return getCarPostState();
    },
    ...options
  });
};

//#endregion
