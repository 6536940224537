import { httpAPIGW } from "@/services/http-clients";
import { dealersEndPoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { useMutation, useQuery } from "react-query";

//#region Get Check Repetitive
interface GetRevertOrdertResponse {
  is_repetitive: boolean;
  go_to_state: string;
}

export const getCheckRepetitive = (id: string, params: { source: string }) => {
  return httpAPIGW<GetRevertOrdertResponse>({
    method: "GET",
    url: endPointUrlNormalizer(dealersEndPoints.CHECK_REPETITIVE, { id }),
    params: {
      source: params.source
    }
  });
};

const getCheckRepetitiveKey = "getCheckRepetitive";

export const useGetCheckRepetitive = (
  id: string,
  params: { source: string }
) => {
  return useQuery({
    queryKey: [getCheckRepetitiveKey, id],
    staleTime: 0,
    enabled: !!id,
    queryFn: () => {
      return getCheckRepetitive(id, params).then((res) => {
        return res.data;
      });
    }
  });
};

//#endregion

//#region Post Revert Order

interface PostRevertOrderBody {
  concierge_sale_id: number;
  source: "backoffice" | "app";
}

const postRevertOrder = (data: PostRevertOrderBody) => {
  return httpAPIGW({
    method: "POST",
    url: dealersEndPoints.REVERT,
    data
  });
};

const postRevertOrderKey = "postRevertOrder";

export const usePostRevertOrder = () => {
  return useMutation({
    mutationKey: [postRevertOrderKey],
    mutationFn: (data: PostRevertOrderBody) => {
      return postRevertOrder(data);
    }
  });
};

//#endregion
