import { ChangeEvent, FC, SyntheticEvent } from "react";
import {
  Stack,
  Box,
  Button,
  SwipeableDrawer,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CallMissedOutlinedIcon from "@mui/icons-material/CallMissedOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import useDocumentHeight from "@/hooks/useDocumentHeight";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

interface Props {
  submitHandler: (event: SyntheticEvent) => void;
  changeHandler: (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  category: string;
  content: string;
  title: string;
  loading: boolean;
}

const NoteModal: FC<Props> = ({
  submitHandler,
  open,
  onOpen,
  onClose,
  changeHandler,
  category,
  content,
  title,
  loading
}) => {
  const { height: vpHeight, offset } = useDocumentHeight();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.modalBox}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={onClose} />
        </Box>
        <form onSubmit={submitHandler} style={{ width: "100%", height: "80%" }}>
          <Stack
            sx={{
              ...styles.formContainer,
              position: "sticky",
              bottom: `calc(100dvh - ${vpHeight}px - ${offset}px)`
            }}
          >
            <FormControl
              size="small"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <InputLabel id="demo-select-small-label">تگ یادداشت</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                sx={{ mb: 2.5 }}
                label="تگ یادداشت"
                name="category"
                onChange={changeHandler}
              >
                <MenuItem value="note">
                  <Box sx={styles.selectItem}>
                    <DescriptionOutlinedIcon sx={styles.descIcon} />
                    یادداشت{" "}
                  </Box>
                </MenuItem>
                <MenuItem value="missed-call">
                  <Box sx={styles.selectItem}>
                    <CallMissedOutlinedIcon sx={styles.missCallIcon} />
                    تماس بی پاسخ
                  </Box>
                </MenuItem>
                <MenuItem value="visit">
                  <Box sx={styles.selectItem}>
                    <EventNoteOutlinedIcon sx={styles.commentIcon} />
                    بازدید
                  </Box>
                </MenuItem>
                <MenuItem value="price-agreement">
                  <Box sx={styles.selectItem}>
                    <MonetizationOnOutlinedIcon sx={styles.priceIcon} />
                    توافق قیمت
                  </Box>
                </MenuItem>
                <MenuItem value="note-call">
                  <Box sx={styles.selectItem}>
                    <PhoneEnabledOutlinedIcon sx={styles.customerCallIcon} />
                    تماس با مشتری
                  </Box>
                </MenuItem>
              </Select>
              <TextField
                id="outlined-multiline-static"
                sx={styles.textArea}
                label="متن یادداشت"
                multiline
                rows={4}
                value={content}
                name="content"
                onChange={changeHandler}
              />
            </FormControl>
          </Stack>
          <Button
            disabled={Boolean(!category) || Boolean(!content) || loading}
            type="submit"
            sx={styles.modalBtn}
          >
            {!loading && "ثبت یادداشت"}
            {loading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </form>
      </Box>
    </SwipeableDrawer>
  );
};

export default NoteModal;
