import { GetSalesExpertReport } from "@/services/api/concierge-sale/sales-expert-report";
import { Box, Button, Tab, Tabs } from "@mui/material";
import React, { FC, useState } from "react";

type PropType = {
  value: number;
  setValue: React.Dispatch<
    React.SetStateAction<GetSalesExpertReport["offerLevel"]>
  >;
};

const LevelTab: FC<PropType> = ({ value, setValue }) => {
  const handleChange = (newValue: GetSalesExpertReport["offerLevel"]) => {
    setValue(newValue);
  };
  return (
    <Tabs
      variant={"scrollable"}
      value={value - 1} // Adjust value to match zero-based indexing of Tabs
      onChange={(_, newValue) => handleChange(newValue + 1)}
      textColor="secondary"
      TabIndicatorProps={{
        style: { display: "none" }
      }}
      sx={{
        display: "flex",
        py: 2,
        mx: 1
      }}
    >
      {Array.from({ length: 5 }, (_, i) => i + 1).map((level) => (
        <Tab
          sx={{
            mx: 0.5,
            minHeight: "40px",
            fontSize: "12px",
            fontWeight: 600,
            border: "1px solid",
            backgroundColor: (th) =>
              value === level ? "#EDEBF4" : "transparent",
            borderRadius: "8px"
          }}
          key={level}
          label={`سطح ${level}`}
        />
      ))}
    </Tabs>
  );
};

export default LevelTab;
