import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FC } from "react";

const UserPerformanceByLevel: FC<any> = ({ data, isLoading }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", border: "none" }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        sx={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                padding: 2
              }
            }}
          >
            <TableCell
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8"
              }}
            >
              عنوان
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8",
                textWrap: "nowrap"
              }}
            >
              تعداد شرکتی
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8",
                textWrap: "nowrap"
              }}
            >
              تعداد شخصی
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8"
              }}
            >
              وزن
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                padding: 2
              }
            }}
          >
            <TableCell
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8",
                textWrap: "nowrap"
              }}
            >
              لیدهای دریافتی
            </TableCell>
            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.karnameh_lead_count
              )}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.personal_lead_count
              )}
            </TableCell>

            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.leads_score
              )}
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                padding: 2
              }
            }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#F3F2F8"
              }}
            >
              فروش
            </TableCell>
            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.karnameh_deal_count
              )}
            </TableCell>{" "}
            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.personal_deal_count
              )}
            </TableCell>{" "}
            <TableCell
              align="center"
              sx={{
                border: "none",
                background: "white",
                fontSize: "12px",
                fontWeight: 400
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 400 }}
                />
              ) : (
                data?.deal_score
              )}
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                padding: 2
              }
            }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#EBF8F1"
              }}
            >
              درصد فروش
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none", background: "#EBF8F1" }}
            />
            <TableCell
              align="center"
              sx={{ border: "none", background: "#EBF8F1" }}
            />
            <TableCell
              align="center"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                border: "none",
                background: "#EBF8F1"
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "12px", fontWeight: 600 }}
                />
              ) : (
                data?.cr_score + "%"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserPerformanceByLevel;
