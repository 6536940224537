import { useGetProviderPackages } from "@/services/api/leasing-order";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import InstalmentMonths from "../InstalmentMonths";
import PackageItem from "../PackageItem";

interface Props {
  car_brand_model: string;
  car_manufacturer_year: string;
  car_price: number;
  channel: string;
  onChange: (
    instalmentMonth: GetLeasingInstalmentMonthsConfigItem,
    selctedPackage: GetProviderPackagesItem
  ) => void;
}

function SelectionPackage({
  car_brand_model,
  car_price,
  car_manufacturer_year,
  onChange,
  channel
}: Props) {
  const [instalemtnState, setInstalemtnState] =
    useState<GetLeasingInstalmentMonthsConfigItem | null>(null);
  const [selectedPackage, setSelectedPackage] =
    useState<GetProviderPackagesItem | null>(null);

  const { data: packages, isLoading } = useGetProviderPackages({
    car_price,
    car_brand_model,
    car_manufacturer_year,
    channel: channel,
    installment_month_count: instalemtnState?.installment_month_count,
    prepayment: instalemtnState?.max_leasing_amount,
    show_package_names: true
  });
  const instalmentMonthChangeHandler = (
    _data: GetLeasingInstalmentMonthsConfigItem
  ) => {
    setInstalemtnState(_data);
    setSelectedPackage(null);
  };
  const selectedPackageHandler = (data: GetProviderPackagesItem) => {
    setSelectedPackage(data);
    if (instalemtnState) onChange(instalemtnState, data);
  };

  useEffect(() => {
    if (packages && selectedPackage === null) {
      if (packages.length > 0 && instalemtnState) {
        setSelectedPackage(packages[0]);
        onChange(instalemtnState, packages[0]);
      }
    }
  }, [packages, selectedPackage, instalemtnState, onChange]);

  return (
    <>
      <InstalmentMonths
        car_brand_model={car_brand_model}
        car_manufacturer_year={car_manufacturer_year}
        car_price={car_price}
        onChange={instalmentMonthChangeHandler}
      />
      {isLoading && (
        <CircularProgress
          size="30px"
          sx={{
            width: "1.25rem",
            height: "1.25rem",
            alignSelf: "center",
            mt: "2rem"
          }}
        />
      )}
      {packages && (
        <>
          {packages.map((item) => (
            <PackageItem
              setSelectedPackageState={selectedPackageHandler}
              selectedPackageState={selectedPackage}
              key={item.id}
              {...item}
              carPrice={car_price}
            />
          ))}
        </>
      )}
    </>
  );
}

export default SelectionPackage;
