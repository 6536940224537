import store from "@/store";
import { setAuthInfo } from "@/store/slices/auth";
import { getAuthCookie, getIsLoggedIn } from "@/utils/auth";
import {
  getAccessTokenFromCookie,
  removeCookie,
  setAccessTokenCookie
} from "@/utils/cookieManager";
import { isGlobalErrorModel } from "@/utils/error-handling";
import {
  removeAccessTokenFromLocalStorage,
  setAccessTokenToLocalStorage
} from "@/utils/localStorageManager";
import axios, { type AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { baseUrls, httpAPIGW } from ".";
import { CheckOtpResponseDTO } from "../api/auth/Auth.dto";
import { switchEndpoints } from "./configs";
import { authorizationCookieKey } from "@/utils/env";
import { DEVICE_ID, FCM_TOKEN } from "@/utils/consts";

const httpClientCreator = (baseURL: string) => {
  const axiosInstance = axios.create({
    baseURL: baseURL
  });

  axiosInstance.interceptors.request.use((config) => {
    const isLoggedIn = getIsLoggedIn();

    if (isLoggedIn) {
      const token = getAuthCookie();
      config.headers["authorization"] = `Bearer ${token}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(null, (error) => {
    return new Promise((resolve, reject) => {
      if (axios.isAxiosError(error)) {
        if (isGlobalErrorModel(error)) {
          error.response?.data.messages.map((messageItem) => {
            if (messageItem.type === "notif") {
              messageItem.message.forEach((messageText) => {
                toast.error(messageText, {
                  position: "top-right",
                  autoClose: 20000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light"
                });
              });
            }
          });
        }
        if (
          // refresh api issue scenario
          error.config?.url?.includes("auth/refresh") &&
          (error.response?.status === 401 ||
            error.response?.status === 403 ||
            (error.response?.status ?? 0) / 5 > 1)
        ) {
          removeCookie(authorizationCookieKey);
          removeCookie("refresh"); // clear refresh token
          removeAccessTokenFromLocalStorage();
          removeCookie(FCM_TOKEN);
          removeCookie(DEVICE_ID);
          window.location.replace(`/login${window.location.search}`);
          return;
        }
        // Inspection Apis send 403 as unauthorized and
        // for specify 403 set access_denied to true; this shows it is real 403 status code.
        if (
          error.response?.status === 401 ||
          (/https:\/\/rest(\.test-([1-9]|10))?\.karnameh\.com.*/.test(
            error.config?.baseURL ?? ""
          ) &&
            error.response?.status === 403 &&
            error.response?.data?.access_denied !== "true")
        ) {
          if (!getAccessTokenFromCookie()) {
            window.location.replace(`/login${window.location.search}`);
            return;
          }
          httpAPIGW<CheckOtpResponseDTO>({
            method: "POST",
            url: switchEndpoints.REFRESH_TOKEN
          }).then((response) => {
            setAccessTokenCookie(response?.data?.access);
            setAccessTokenToLocalStorage(response?.data?.access);
            store.dispatch(setAuthInfo());
          });
        } else {
          reject(error);
        }
      } else {
        reject(error);
      }
    });
  });

  const httpClient = async <Response>(
    config: AxiosRequestConfig,
    baseURL?: keyof typeof baseUrls
  ) => {
    if (typeof baseURL !== undefined) {
      config.baseURL = baseUrls[baseURL as keyof typeof baseUrls];
    }
    const res = await axiosInstance<Response>(config);
    return res;
  };

  return httpClient;
};

export default httpClientCreator;
