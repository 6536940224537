import { FC, useEffect, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress
  //   Button,
  //   CircularProgress,
  //   TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { colorsResponse } from "@/services/api/sales";
import { useUpdateInfoData } from "@/services/api/sales";
import { useParams } from "react-router";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  colorData: colorsResponse;
  currentColor: string;
}

const EditCarColor: FC<Props> = ({
  open,
  showModal,
  title,
  colorData,
  currentColor
}) => {
  const { id } = useParams();
  const { mutateAsync, isLoading } = useUpdateInfoData(String(id));
  const [carColor, setCarColor] = useState<string | null>(null);

  const submitHandler = () => {
    mutateAsync({ car_color: carColor as string }).then(() => {
      showModal(false);
    });
  };

  const colorKey = Object.keys(colorData ?? {}).find(
    (item) => colorData[item] === currentColor
  );

  useEffect(() => {
    if (currentColor) {
      if (colorKey) setCarColor(colorKey);
    }
  }, [currentColor, colorKey]);

  const colorsList = colorData
    ? Object?.entries(colorData)?.map((item) => item)
    : [];

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl sx={{ mb: "20px" }} fullWidth size="small">
            <InputLabel id="demo-simple-select-label">رنگ *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={carColor ?? ""}
              label="رنگ *"
              name="color"
              onChange={(event) => setCarColor(event.target.value)}
            >
              {colorsList.map((item) => {
                return (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            disabled={Boolean(!carColor) || isLoading}
            onClick={submitHandler}
          >
            {!isLoading && "ثبت تغییرات"}
            {isLoading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>{" "}
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default EditCarColor;
