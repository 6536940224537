import type { ThemeOptions } from "@mui/material";

const typography: ThemeOptions["typography"] = {
  fontFamily: "IRANSans, sans-serif",
  fontSize: 16,
  displayLarge: { fontSize: "48px", fontWeight: "600", lineHeight: "58px" },
  displayMedium: { fontSize: "40px", fontWeight: "600", lineHeight: "48px" },
  displaySmall: { fontSize: "32px", fontWeight: "600", lineHeight: "38px" },
  headlineLarge: { fontSize: "28px", fontWeight: "600", lineHeight: "40px" },
  headlineMedium: { fontSize: "24px", fontWeight: "600", lineHeight: "34px" },
  headlineSmall: { fontSize: "20px", fontWeight: "600", lineHeight: "28px" },
  headlineXSmall: { fontSize: "16px", fontWeight: "600", lineHeight: "22px" },
  labelLarge: { fontSize: "18px", fontWeight: "600", lineHeight: "32px" },
  labelMedium: { fontSize: "16px", fontWeight: "600", lineHeight: "28px" },
  labelSmall: { fontSize: "14px", fontWeight: "600", lineHeight: "26px" },
  labelXSmall: { fontSize: "12px", fontWeight: "600", lineHeight: "22px" },
  label2XSmall: { fontSize: "10px", fontWeight: "600", lineHeight: "18px" },
  bodyLarge: { fontSize: "18px", fontWeight: "400", lineHeight: "32px" },
  bodyMedium: { fontSize: "16px", fontWeight: "400", lineHeight: "28px" },
  bodySmall: { fontSize: "14px", fontWeight: "400", lineHeight: "26px" },
  bodyXSmall: { fontSize: "12px", fontWeight: "400", lineHeight: "22px" },
  body2XSmall: { fontSize: "10px", fontWeight: "400", lineHeight: "18px" }
};

export default typography;
