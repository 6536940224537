import { httpAPIGW } from "@/services/http-clients";
import {
  api2Endpoints,
  leasingEndpoints
} from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { UseQueryOptions, useMutation, useQuery } from "react-query";
//#region Get Leasing Note

export interface GetNoteListResponse {
  id: number;
  category: string;
  content: string;
  uuid: string;
  created_at: string;
  last_modified: string;
  agent_fullname: string;
  follow_up_date?: string;
}

const getLeasingNote = async (id: string) => {
  return httpAPIGW<GetNoteListResponse[]>({
    method: "GET",
    url: leasingEndpoints.ORDER_NOTE,
    params: { order_id: id }
  }).then((res) => res.data);
};

const getLeasingNoteKey = "getLeasingNote";

export const useGetLeasingNote = (
  id: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getLeasingNote>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getLeasingNote>>
  >
) => {
  return useQuery({
    queryKey: [getLeasingNoteKey, id],
    staleTime: 0,
    queryFn: () => {
      return getLeasingNote(id);
    },
    ...options
  });
};

//#endregion

//#region Post Leasing Note

interface PostLeasingNoteBody {
  order_id: string;
  category: string;
  content: string;
  follow_up_date?: string;
}

const postLeasingNote = async (data: PostLeasingNoteBody) => {
  return httpAPIGW<PostLeasingNoteBody>({
    method: "POST",
    url: leasingEndpoints.ORDER_NOTE,
    data
  });
};

const postLeasingNoteKey = "postLeasingNote";

export const usePostLeasingNote = (id: string) => {
  return useMutation({
    mutationKey: [postLeasingNoteKey, id],
    mutationFn: (data: PostLeasingNoteBody) => {
      return postLeasingNote(data);
    }
  });
};

//#endregion
