import { FC, useEffect, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Button,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import { useGetSaleDetailV2, useUpdateInfoData } from "@/services/api/sales";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const SelectStatusModal: FC<Props> = ({ open, showModal, title }) => {
  const [status, setStatus] = useState("");
  const { id } = useParams();
  const { data, refetch } = useGetSaleDetailV2(Number(id), {
    enabled: Boolean(id)
  });
  const { mutateAsync, isLoading } = useUpdateInfoData(String(id));

  useEffect(() => {
    if (data) {
      setStatus(data?.status);
    }
  }, [data?.status]);

  const submitHandler = () => {
    mutateAsync({
      status
    }).then(() => {
      refetch();
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ flexDirection: "column" }}>
          <Stack
            sx={{
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            <Box
              onClick={() => setStatus("SC")}
              sx={{
                color: status === "SC" ? "#107BC1" : "#A9A8AA",
                background: status === "SC" ? "#E5F8FC" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              تماس اولیه
            </Box>

            {/* <Box
              onClick={() => setStatus("PE")}
              sx={{
                color: status === "PE" ? "#D62497" : "#A9A8AA",
                background: status === "PE" ? "#FFE8F7" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              قیمت گذاری{" "}
            </Box> */}

            <Box
              onClick={() => setStatus("P")}
              sx={{
                color: status === "P" ? "#F4770D" : "#A9A8AA",
                background: status === "P" ? "#FDEBDC" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              مذاکره قیمت{" "}
            </Box>

            <Box
              onClick={() => setStatus("D")}
              sx={{
                color: status === "D" ? "#6514FF" : "#A9A8AA",
                background: status === "D" ? "#F2EBFF" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              آگهی شده{" "}
            </Box>

            <Box
              onClick={() => setStatus("Z")}
              sx={{
                color: status === "Z" ? "#00B906" : "#A9A8AA",
                background: status === "Z" ? "#E0FFE1" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              پیگیری مجدد مذاکره قیمت{" "}
            </Box>

            {/* <Box
              onClick={() => setStatus("C")}
              sx={{
                color: status === "C" ? "#CD0000" : "#A9A8AA",
                background: status === "C" ? "#FFF4F4" : "#EEEEEE",
                borderRadius: 5,
                textAlign: "center",
                py: "6px",
                mb: 2,
                fontSize: "14px"
              }}
            >
              لغو شده
            </Box> */}
          </Stack>

          <Button disabled={isLoading} onClick={submitHandler}>
            {!isLoading && "ثبت تغییرات"}
            {isLoading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default SelectStatusModal;
