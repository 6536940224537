import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import styles from './styles';

interface PropTypes {
  label?: string;
  onClick: () => void;
  hasBorderBottom?: boolean;
}

const BackItem: FC<PropTypes> = (props) => {
  const { label = 'بازگشت', onClick, hasBorderBottom } = props;

  const theme = useTheme();

  return (
    <Stack
      sx={styles.container}
      style={{
        borderBottom: hasBorderBottom
          ? `1px solid ${theme.palette.surface.paper}`
          : 'unset',
      }}
    >
      <IconButton onClick={onClick}>
        <ArrowForwardIcon sx={styles.backIcon} />
      </IconButton>
      <Typography ml="0.5rem">{label}</Typography>
    </Stack>
  );
};

export default BackItem;
