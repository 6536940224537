import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import styles from './styles';

interface PropTypes {
  title: string;
  onClick: () => void;
  hasBorderBottom?: boolean;
}

const ItemWithSubList: FC<PropTypes> = (props) => {
  const { title, onClick, hasBorderBottom = false } = props;

  const theme = useTheme();

  return (
    <Stack
      component="div"
      onClick={onClick}
      sx={styles.container}
      style={{
        borderBottom: hasBorderBottom
          ? `1px solid ${theme.palette.surface.paper}`
          : 'unset',
      }}
    >
      <Typography>{title}</Typography>
      <ChevronLeftRoundedIcon />
    </Stack>
  );
};

export default ItemWithSubList;
