import { GetSalesExpertReportResponse } from "@/services/api/concierge-sale/sales-expert-report";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Rectangle,
  ResponsiveContainer,
  YAxis,
  Legend,
  LegendProps,
  LabelList
} from "recharts";
import { monthFormatter } from "./SingleBarChart";

const LEGEND_CONSTANT: { [key: string]: string } = {
  deal_count: "تعداد لید فروخته شده",
  total_deal_amount: "سهم کارنامه",
  expert_commission_amount: "سهم شما"
};

const renderLegend = (props: LegendProps) => {
  const { payload } = props;
  return (
    <Stack alignItems={"start"} flexDirection={"row"}>
      {payload?.map((entry, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            color: "#000", // Set text color explicitly
            fontSize: "14px" // Optional: Adjust font size
          }}
        >
          {/* Icon */}
          <svg width="12" height="12" style={{ marginRight: 5 }}>
            <rect width="12" height="12" fill={entry.color} />
          </svg>
          {/* Text */}
          <Typography
            fontSize={"10px"}
            fontWeight={400}
            sx={{
              textWrap: "nowrap"
            }}
          >
            {LEGEND_CONSTANT[entry.value]}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

const toRialFormat = (value: number) => `${value.toLocaleString("fa-IR")}`;

const MixBarChart: React.FC<{ data: GetSalesExpertReportResponse[] }> = ({
  data
}) => {
  return (
    <ResponsiveContainer width={"100%"} height={350}>
      <BarChart
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <XAxis
          dataKey="month"
          fontSize={"12px"}
          fontWeight={"400"}
          tickFormatter={(mnth) => monthFormatter(mnth)}
        />
        <YAxis
          width={1}
          tickFormatter={() => ""} // Remove value display
          axisLine={true} // Keep Y-axis line
          tickLine={false} // Hide the tick lines
        />
        <Legend content={renderLegend as any} />
        <Bar
          dataKey={"expert_commission_amount"}
          fill="#00A754"
          activeBar={<Rectangle fill="#694FB4" stroke="blue" />}
          stackId="a"
        >
          <LabelList
            fontSize={"14px"}
            fontWeight={600}
            fill="white"
            position="center"
            dataKey={"expert_commission_amount"}
            formatter={(value: number) => toRialFormat(value)}
          />
        </Bar>
        <Bar
          dataKey="total_deal_amount"
          fill="#694FB4"
          activeBar={<Rectangle fill="#694FB4" stroke="blue" />}
          radius={[8, 8, 0, 0]}
          stackId="a"
        >
          {data ? (
            <>
              <LabelList
                fontSize={"14px"}
                fontWeight={600}
                fill="white"
                position="center"
                dataKey={"deal_amount"}
                formatter={(value: number) => toRialFormat(value)}
              />
              <LabelList
                fontSize={"14px"}
                fontWeight={600}
                fill="black"
                position="top"
                dataKey={"total_deal_amount"}
                formatter={(value: number) => toRialFormat(value)}
              />
            </>
          ) : (
            ""
          )}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MixBarChart;
