import {
  Drawer,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router";
import { useGetSaleDetailV2 } from "@/services/api/sales";

export const CommissionModal = ({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { id } = useParams();
  const { data } = useGetSaleDetailV2(Number(id), { enabled: Boolean(id) });

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: "1.75rem",
          borderTopRightRadius: "1.75rem"
        }
      }}
    >
      <Stack
        sx={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          // width: 460,
          backgroundColor: (th) => th.palette.common.white,
          outline: "none",
          borderRadius: "8px",
          overflow: "hidden"
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="1.25rem 1rem"
        >
          <Typography>ریز محاسبه درصد کمیسیون دستیار</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ width: "1.25rem", height: "1.25rem" }} />
          </IconButton>
        </Stack>
        <Table>
          <TableHead sx={{ backgroundColor: "#EBEBEB" }}>
            <TableRow>
              <TableCell>عنوان</TableCell>
              <TableCell sx={{ textAlign: "center" }}>مقدار</TableCell>
              <TableCell sx={{ textAlign: "end" }}>درصد تاثیر</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.deals?.sale_concierge_commission_detail?.map(
              (item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.value}
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>{item.impact}</TableCell>
                </TableRow>
              )
            )}
            <TableRow sx={{ backgroundColor: "#F3F2F8" }}>
              <TableCell>مجموع</TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: "end" }}>
                {data?.deals?.sale_concierge_commission_detail?.reduce(
                  (acc, item) => (acc += item.impact),
                  0
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Drawer>
  );
};
