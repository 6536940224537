import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    py: '1rem',
  },
  backIcon: {
    color: (th) => th.palette.iconDefault.main,
  },
});

export default styles;
