import { Stack, Typography } from '@mui/material';

import { FC } from 'react';
import styles from './styles';
import { useCitySelectInternal } from '../../configs';
import Item from './Item';

const ActiveFilters: FC = () => {
  const { values } = useCitySelectInternal();

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.scrollableZone}>
        {values.map((value, valueIndex) => {
          return (
            <Stack key={`${valueIndex}-${value}`} sx={styles.itemContainer}>
              <Item value={value} />
            </Stack>
          );
        })}
        {!values.length && (
          <Typography
            fontSize="0.875rem"
            color={(th) => th.palette.iconDefault.main}
          >
            لطفا شهر خود را انتخاب کنید.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ActiveFilters;
