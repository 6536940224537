import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    px: '1rem',
    pb: '5rem',
  },
  loadingContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    py: '1rem',
  },
});

export default styles;
