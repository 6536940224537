import { Stack } from "@mui/material";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";

import { FC } from "react";

export const DataLineChart: FC<{
  data?: { x: string; y: number }[];
}> = ({ data }) => {
  return (
    <Stack height="250px" width="min(100vw, 512px)">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ right: 10, left: 10 }}>
          <CartesianGrid stroke="#C9CCCF" strokeDasharray="5 5" />
          <XAxis dataKey="x" fontSize={"10px"} fontWeight={400} interval={0} />
          <YAxis
            dataKey="y"
            fontSize={"12px"}
            fontWeight={400}
            tickMargin={10}
          />
          <Line
            dataKey="y"
            stroke="#694FB4"
            dot={{ stroke: "#694FB4", fill: "#694FB4", r: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};
