import { Button, Stack } from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import { useCitySelectInternal } from '../../configs';

interface PropTypes {
  onSubmitClick: () => void;
  onCancelClick: () => void;
}

const Footer: FC<PropTypes> = (props) => {
  const { onCancelClick, onSubmitClick } = props;
  const { isMultiple } = useCitySelectInternal();

  if (!isMultiple) {
    return null;
  }

  return (
    <Stack sx={styles.container}>
      <Button
        onClick={onCancelClick}
        sx={styles.cancelButton}
        color="inherit"
        variant="outlined"
      >
        انصراف
      </Button>
      <Button onClick={onSubmitClick} sx={styles.submitButton}>
        تائید
      </Button>
    </Stack>
  );
};

export default Footer;
