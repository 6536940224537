import { CircularProgress, Stack } from '@mui/material';

import { produce } from 'immer';
import { FC, useCallback, useMemo, useState } from 'react';
import styles from './styles';
import { levels, useCitySelectInternal } from '../../configs';
import { useGetClutchPlace } from '@/services/api/clutch';
import BackItem from './BackItem';
import ItemWithSubList from './ItemWithSubList';
import SelectableItem from './SelectableItem';

const DefaultMethod: FC = () => {
  const [currentLevel, setCurrentLevel] = useState(levels[0].level);
  const [currentParent, setCurrentParent] = useState<ClutchPlace[]>([]);

  const { onValueChange, values, isMultiple } = useCitySelectInternal();

  const {
    data: placeListQuery,
    isLoading: isPlaceListLoading,
    isSuccess: isPlaceListSuccess,
  } = useGetClutchPlace({});

  const placeList = useMemo(() => {
    const result =
      placeListQuery?.filter((place) => {
        return place.level === currentLevel;
      }) || [];
    if (currentParent.length) {
      return result.filter(
        (place) => place.parent === currentParent.at(-1)?.id,
      );
    } else {
      return result;
    }
  }, [currentLevel, currentParent, placeListQuery]);

  const backClickHandler = useCallback(() => {
    if (currentParent.length) {
      setCurrentLevel(currentParent[0].level);
      setCurrentParent(
        produce((draft) => {
          draft.splice(draft.length - 1, 1);
        }),
      );
    }
  }, [currentParent]);

  const lastParent = useMemo(() => {
    if (currentParent.length) {
      return currentParent.at(-1)!;
    }
    return null;
  }, [currentParent]);

  const lastParentLevelConfig = useMemo(() => {
    if (lastParent) {
      return levels.find((level) => level.level === lastParent.level) || null;
    } else {
      return null;
    }
  }, [lastParent]);

  return (
    <Stack sx={styles.container}>
      {!!lastParent && !!lastParentLevelConfig && (
        <BackItem
          hasBorderBottom
          label={(function getBackLabel() {
            const backLabel = lastParentLevelConfig.backLabel;
            if (typeof backLabel === 'string') {
              return backLabel;
            } else if (typeof backLabel === 'function') {
              return backLabel({
                fa: lastParent.name_fa,
                en: lastParent.name_en,
              });
            } else {
              return undefined;
            }
          })()}
          onClick={backClickHandler}
        />
      )}

      {!!lastParent && isMultiple && (
        <SelectableItem
          key={lastParent.id}
          title={
            levels
              .find((level) => level.level === lastParent.level)
              ?.selectAllLabel?.({
                en: lastParent.name_en,
                fa: lastParent.name_fa,
              }) || lastParent.name_fa
          }
          onClick={() => onValueChange(lastParent)}
          checked={
            !!values.find(
              (value) =>
                value.id === lastParent.id ||
                (isMultiple && lastParent.parent_ids.includes(value.id)),
            )
          }
          hasBorderBottom
        />
      )}

      {isPlaceListLoading && (
        <Stack sx={styles.loadingContainer}>
          <CircularProgress size={30} />
        </Stack>
      )}

      {isPlaceListSuccess &&
        Array.isArray(placeList) &&
        placeList.map((place, placeIndex) => {
          const checked = !!values.find(
            (value) =>
              value.id === place.id ||
              (isMultiple && place.parent_ids.includes(value.id)),
          );
          if (place.has_children) {
            return (
              <ItemWithSubList
                key={place.id}
                title={place.name_fa}
                onClick={() => {
                  const currentLevelIndex = levels.findIndex(
                    (level) => level.level === place.level,
                  );
                  if (
                    currentLevelIndex >= 0 &&
                    typeof levels[currentLevelIndex + 1] !== 'undefined'
                  ) {
                    setCurrentParent(
                      produce((draft) => {
                        draft.push(place);
                      }),
                    );
                    setCurrentLevel(levels[currentLevelIndex + 1].level);
                  }
                }}
                hasBorderBottom={placeIndex !== placeList.length - 1}
              />
            );
          } else {
            return (
              <SelectableItem
                key={place.id}
                title={place.name_fa}
                onClick={() => onValueChange(place)}
                checked={checked}
                hasBorderBottom={placeIndex !== placeList.length - 1}
              />
            );
          }
        })}
    </Stack>
  );
};

export default DefaultMethod;
