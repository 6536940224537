import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetAllVisiblePlans,
  usePostConciergeSaleSubmitOrder
} from "@/services/api/concierge-sale/submit-order";
import { useGetCarStatusColors } from "@/services/api/sales";
import { Car } from "@/services/api/searchCar";
import { toEngDigits } from "@/utils/toEnglishDigit";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useLogic = () => {
  const [isCitySelectOpen, setIsCitySelectOpen] = useState(false);
  const { data: colors } = useGetCarStatusColors();

  const { mutateAsync: submitOrder, isLoading: isSubmitOrderLoading } =
    usePostConciergeSaleSubmitOrder();

  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("ثبت درخواست فروش");
    }
  }, [setHeaderTitle]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      city: null as null | { id: number; name: string },
      car: null as Car | null,
      year: null as number | null,
      usage: "",
      color: null as number | null,
      plan: null as null | string,
      sendLinkToUser: false,
      imReagent: false,
      assignToMe: false,
      address: "",
      description: ""
    },
    onSubmit: (values) => {
      submitOrder({
        address: values.address,
        car_brand_model: (values.car as Car).name_en,
        city: values?.city?.id as number,
        car_color: values.color as number,
        description: values.description,
        is_assign_to_me: values.assignToMe,
        is_reagent: values.imReagent,
        is_send_payment_link_to_customer: values.sendLinkToUser,
        customer_phone_number: values.phoneNumber,
        plan_uuid: values.plan as string,
        usage: parseInt(values.usage),
        customer_name: values.fullName,
        car_brand_model_year: values.year as number
      }).then(() => {
        navigate(-1);
        toast.success("سفارش با موفقیت ثبت شد.");
      });
    }
  });

  const { data: _plansList } = useGetAllVisiblePlans(
    {
      service_name: "CONCIERGE_SALE",
      brand_model: formik.values.car?.name_en || "",
      car_brand_model_year: formik.values.year as number,
      city: formik.values.city?.id ?? 0,
      usage: formik.values.usage
    },
    {
      enabled:
        !!formik.values.car &&
        !!formik.values.year &&
        !!formik.values.city &&
        !!formik.values.usage
    }
  );

  const plansList =
    !!formik.values.car &&
    !!formik.values.year &&
    !!formik.values.city &&
    !!formik.values.usage
      ? _plansList
      : undefined;

  const manufactureYearList = useMemo(() => {
    const currentDate = new Date();
    return new Array(currentDate.getFullYear() - 2001 + 1)
      .fill(null)
      .map((_, yearIndex) => {
        currentDate.setFullYear(2001 + yearIndex);
        const jalaliYear = currentDate
          .toLocaleDateString("fa-IR-u-nu-latn", { timeZone: "Iran" })
          .split("/")[0];
        return {
          id: currentDate.getFullYear() - 1,
          label: `${currentDate.getFullYear() - 1} - ${jalaliYear}`
        };
      })
      .reverse();
  }, []);

  const isValid = (() => {
    return [
      !!formik.values.address,
      !!formik.values.car?.name_en,
      !!formik.values.city,
      typeof formik.values.color === "string",
      /^09\d{9}$/.test(formik.values.phoneNumber),
      !!formik.values.plan,
      /\d+/.test(formik.values.usage),
      !!formik.values.fullName,
      typeof formik.values.year === "number"
    ].every((i) => !!i);
  })();

  const numericChangeHandler = (name: string, _newValue: string) => {
    const newValue = toEngDigits(_newValue).replace(/[^0-9]/g, "");

    formik.setFieldValue(name, newValue);
  };

  return {
    colors,
    isSubmitOrderLoading,
    formik,
    plansList,
    manufactureYearList,
    isValid,
    numericChangeHandler,
    isCitySelectOpen,
    setIsCitySelectOpen
  };
};

export default useLogic;
