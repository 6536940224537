import { createContext, useContext } from 'react';

type ClutChPlaceLevels = 'city' | 'province' | 'neighborhood';


type Level = {
  level: ClutChPlaceLevels;
  title_en: string;
  title_fa: string;
  nextLevelKey?: string;
  prevLevelKey?: string;
  backLabel?: string | ((name: { fa: string; en: string }) => string);
  getActiveFilterLabel?: (name: { fa: string; en: string }) => string;
  selectAllLabel?: (name: { fa: string; en: string }) => string;
  getSearchLabel?: (name: { fa: string; en: string }) => string;
};

export const levels: Level[] = [
  {
    level: 'province',
    title_en: 'province',
    title_fa: 'استان',
    nextLevelKey: 'city',
    backLabel: 'مشاهده‌ی همه‌ی استان‌ها',
    getActiveFilterLabel: (name) => `همه‌ی شهرهای ${name.fa}`,
    selectAllLabel: (name) => `همه‌ی شهرهای ${name.fa}`,
    getSearchLabel: (name) => `همه‌ی شهر‌های استان ${name.fa}`,
  },
  {
    level: 'city',
    title_en: 'city',
    title_fa: 'شهر',
    prevLevelKey: 'province',
    getActiveFilterLabel: (name) => name.fa,
  },
];

export const levelsObj = levels.reduce<
  Partial<Record<ClutChPlaceLevels, Level>>
>((result, level) => {
  if (typeof level.level !== 'undefined') {
    result[level.level] = level;
  }
  return result;
}, {});

export const CitySelectInternalContext = createContext<{
  isMultiple: boolean;
  values: ClutchPlace[];
  onValueChange: (newValue: ClutchPlace) => void;
}>({
  isMultiple: false,
  values: [],
  onValueChange: () => null,
});

export const useCitySelectInternal = () => {
  return useContext(CitySelectInternalContext);
};

export const shapeValue = (
  value: number[] | number | string[] | string | null,
  placeList: ClutchPlace[],
  withSlug: boolean,
) => {
  //#region generate raw ClutchPlace array from value.
  if (typeof value === 'number' || typeof value === 'string') {
    const findedPlace = placeList.find(
      (place) =>
        (!withSlug && place.id === value) || (withSlug && place.slug === value),
    );
    if (findedPlace) {
      return [findedPlace];
    }
  } else if (Array.isArray(value)) {
    const findedPlaces: ClutchPlace[] = [];
    value.forEach((valueItem) => {
      const findedPlace = placeList.find(
        (place) =>
          (!withSlug && place.id === valueItem) ||
          (withSlug && place.slug === valueItem),
      );
      if (findedPlace) {
        findedPlaces.push(findedPlace);
      }
    });
    return findedPlaces;
  }
  return [];
  //#endregion
};

export const extractPlaceLeafs = (
  placeList: ClutchPlace[],
  values: ClutchPlace[],
) => {
  const activeParents: number[] = [];
  const placeLeafs: ClutchPlace[] = [];
  placeList
    .filter((place) => {
      const isInValues = values.some((value) => value.id === place.id);
      if (isInValues && place.has_children) {
        activeParents.push(place.id);
      } else if (isInValues) {
        placeLeafs.push(place);
      }
      return !place.has_children;
    })
    .forEach((place) => {
      if (
        activeParents.some((activeParent) =>
          place.parent_ids.includes(activeParent),
        )
      ) {
        placeLeafs.push(place);
      }
    });

  return Object.values(
    placeLeafs.reduce<Record<number, ClutchPlace>>((result, item) => {
      if (!(item.id in result)) {
        result[item.id] = item;
      }
      return result;
    }, {}),
  );
};
