import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import styles from './styles';
import { levels, useCitySelectInternal } from '@/components/CitySelect/configs';

interface PropTypes {
  value: ClutchPlace;
}

const Item: FC<PropTypes> = (props) => {
  const { value } = props;

  const { onValueChange } = useCitySelectInternal();

  const label = (function createLabel() {
    return (
      levels
        .find((level) => level.level === value.level)
        ?.getActiveFilterLabel?.({ en: value.name_en, fa: value.name_fa }) ||
      value.name_fa
    );
  })();

  const onClickHandler = useCallback(() => {
    onValueChange(value);
  }, [onValueChange, value]);

  return (
    <>
      <Button
        onClick={onClickHandler}
        variant="outlined"
        size="small"
        sx={styles.button}
        color="secondary"
        startIcon={<CloseRoundedIcon />}
      >
        {label}
      </Button>
    </>
  );
};

export default Item;
