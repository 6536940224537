import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  Button,
  CircularProgress,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import { useGetSaleDetailV2, useUpdateInfoData } from "@/services/api/sales";
import { toEngDigits } from "@/utils/toEnglishDigit";
interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const EditCarUsage: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const { data, refetch } = useGetSaleDetailV2(Number(id), {
    enabled: Boolean(id)
  });
  const { mutateAsync, isLoading } = useUpdateInfoData(String(id));

  const [usage, setUsage] = useState<number>(0);

  useEffect(() => {
    if (data?.usage) {
      setUsage(data?.usage);
    }
  }, [data?.usage]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUsage(parseInt(toEngDigits(event.target.value)));
  };

  const submitHandler = () => {
    mutateAsync({
      usage: Number(usage)
    }).then(() => {
      refetch();
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.pirceModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ mb: "20px", width: "100%" }}
            label="کارکرد"
            value={usage ? usage : ""}
            type="tel"
            size="small"
            onChange={changeHandler}
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
          />
          <Button
            disabled={Boolean(!usage) || isLoading}
            onClick={submitHandler}
          >
            {!isLoading && "ثبت تغییرات"}
            {isLoading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default EditCarUsage;
