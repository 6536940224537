import { FC } from "react";
import {
  Box,
  Typography,
  SwipeableDrawer,
  Stack,
  Divider
} from "@mui/material";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PhoneIcon from "@mui/icons-material/Phone";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  fullName: string;
  phoneNumber: string;
}

const ContactModal: FC<Props> = ({
  open,
  showModal,
  fullName,
  phoneNumber
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{
        elevation: 0,
        style: { backgroundColor: "transparent" }
      }}
    >
      <Stack sx={styles.contactModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={15}>
            {fullName}
          </Typography>
        </Box>
        <Stack
          flexDirection="column"
          width="100%"
          spacing={2}
          divider={<Divider />}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Stack
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                شماره همراه
              </Typography>
              <Typography sx={{ fontSize: 14, ml: 1 }}>
                {phoneNumber}
              </Typography>
            </Stack>
            <Box component="div" sx={{ display: "flex" }}>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "12px"
                }}
                href={`sms:${phoneNumber}`}
              >
                <ChatBubbleOutlineIcon sx={{ color: "#6E6E6E" }} />
              </a>
              <a
                style={{ display: "flex", alignItems: "center" }}
                href={`tel:${phoneNumber}`}
              >
                <PhoneIcon sx={{ color: "#6E6E6E" }} />
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Stack
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                تماس با VOIP
              </Typography>
            </Stack>
            <Box component="div" sx={{ display: "flex" }}>
              <a
                style={{ display: "flex", alignItems: "center" }}
                href={`zoiper://9${phoneNumber}`}
              >
                <PhoneIcon sx={{ color: "#6E6E6E" }} />
              </a>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ContactModal;
