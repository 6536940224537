import createStyle from "@/utils/createStyle";

const styles = createStyle({
  button: {
    whiteSpace: 'nowrap',
    borderRadius: '99999px',
  },
});

export default styles;
