import { GlobalErrorModel } from "@/utils/error-handling";
import { AxiosError } from "axios";
import {
  MutationFunction,
  MutationKey,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery
} from "react-query";
import { httpAPIGW } from "../http-clients";
import {
  api2Endpoints,
  dealersEndPoints,
  restEndpoints
} from "../http-clients/configs";
import { endPointUrlNormalizer } from "../http-clients/utils";

export interface SalesExpert {
  results: [
    {
      id: number;
      applicant_full_name: string;
      car_brand_model: string;
      karnameh_pricing_lower_bound: number;
      car_brand_model_pe: string;
      karnameh_pricing_upper_bound: number;
      address: string;
      car_brand_model_year: string;
      status?: string;
      expiration_time?: string | null;
      is_new_financial_model: boolean;
    }
  ];
  count: number;
  next: null | string;
  previous: null | string;
}

export interface SalesNoteList {
  agent_fullname: string;
  category: string;
  content: string;
  uuid: string;
  created_at: string;
  last_modified?: string;
}

export interface SendNewNote {
  order_id: string | undefined;
  category: string;
  content: string;
}

export interface UpdateNote {
  category: string;
  content: string;
}

export interface NoteById {
  agent_fullname: string;
  category: string;
  content: string;
  uuid: string;
}

export interface CarStatus {
  car_status?: string;
  is_leasing_available?: boolean;
}

const getSaleExpertRequest = async (status: string, page: number) => {
  return httpAPIGW<SalesExpert>({
    method: "GET",
    url: dealersEndPoints.SALES_REQUESTS,
    params: { status, page, page_size: 10 }
  });
};

const getSaleExpertRequestKey = "getSaleExpertRequest";

export const useGetSaleExpert = (status: string, page = 1) => {
  return useQuery({
    queryKey: [getSaleExpertRequestKey, status, page],
    staleTime: 0,
    queryFn: () => {
      return getSaleExpertRequest(status, page)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return {} as SalesExpert;
        });
    }
  });
};

const getSalesNoteList = async (id: string) => {
  return httpAPIGW<SalesNoteList[]>({
    method: "GET",
    url: endPointUrlNormalizer(dealersEndPoints.SALE_NOTE, { id })
  });
};

const getSalesNoteListKey = "getSalesNoteList";

export const useGetSaleNoteList = (id: string) => {
  return useQuery({
    queryKey: [getSalesNoteListKey],
    staleTime: 0,
    queryFn: () => {
      return getSalesNoteList(id)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [] as SalesNoteList[];
        });
    }
  });
};

const postConciergeSaleNote = (data: SendNewNote) => {
  return httpAPIGW({
    method: "POST",
    url: dealersEndPoints.SALE_NOTE_REGISTER,
    data
  });
};

const postConciergeSaleNoteKey = "postConciergeSaleNote";

export const usePostConciergeSaleNote = () => {
  return useMutation({
    mutationKey: [postConciergeSaleNoteKey],
    mutationFn: (data: SendNewNote) => {
      return postConciergeSaleNote(data);
    }
  });
};

const updateConciergeSaleNote = (uuid: string, data: UpdateNote) => {
  return httpAPIGW({
    method: "PUT",
    url: endPointUrlNormalizer(dealersEndPoints.SALE_NOTE_UPDATE, { id: uuid }),
    data
  });
};

const updateConciergeSaleNoteKey = "updateConciergeSaleNote";

export const useUpdateConciergeSaleNote = (uuid: string) => {
  return useMutation({
    mutationKey: [updateConciergeSaleNoteKey],
    mutationFn: (data: UpdateNote) => {
      return updateConciergeSaleNote(uuid, data);
    }
  });
};

/*--- Car Status Colors   ---*/

export type colorsResponse = { [key: string | number]: string };

const getCarStatusColors = () => {
  return httpAPIGW<colorsResponse>({
    method: "GET",
    url: dealersEndPoints.COLORS
  }).then((res) => res.data);
};

const getCarStatusColorsKey = "getCarStatusColors";

export const useGetCarStatusColors = (
  options?: UseQueryOptions<Awaited<ReturnType<typeof getCarStatusColors>>>
) => {
  const queryFn = () => {
    return getCarStatusColors();
  };

  return useQuery({
    queryKey: [getCarStatusColorsKey],
    queryFn,
    staleTime: 0,
    ...options
  });
};

interface PriceForm {
  price: number | null;
  advertisement_price: number | null;
  karnameh_commission: number | null;
  karnameh_commission_percentage?: number | null;
}

export const getCalculatePrice = (
  price: number | null,
  karnameh_commission: number | null
) => {
  return httpAPIGW<PriceForm>({
    method: "GET",
    url: dealersEndPoints.CALCULATE_PRICE,
    params: { price, karnameh_commission }
  });
};

const getCalculatePriceKey = "getCalculatePrice";

export const useGetCalculatePrice = (
  price: number | null,
  karnameh_commission: number | null,
  id: number
) => {
  return useQuery({
    queryKey: [getCalculatePriceKey, id],
    staleTime: 0,
    queryFn: () => {
      return getCalculatePrice(price, karnameh_commission).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Post Negotiated Price API ---*/

interface NegotiatedPrice {
  price: number | null;
  advertisement_price: number | null;
  karnameh_commission: number | null;
}

const postNegotiatedPrice = (id: number, data: NegotiatedPrice) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.SUBMIT_PRICE, { id: `${id}` }),
    data
  });
};

const postNegotiatedPriceKey = "postNegotiatedPrice";

export const usePostNegotiatedPrice = (id: number) => {
  return useMutation({
    mutationKey: [postNegotiatedPriceKey],
    mutationFn: (data: NegotiatedPrice) => {
      return postNegotiatedPrice(id, data);
    }
  });
};

/*--- Get Cancel Reason API ---*/

export const getCancelReason = () => {
  return httpAPIGW<{ [key: string]: string }>({
    method: "GET",
    url: dealersEndPoints.CANCEL_REASONS
  });
};

const getCancelReasonKey = "getCancelReason";

export const useGetCancelReason = () => {
  return useQuery({
    queryKey: [getCancelReasonKey],
    staleTime: 0,
    queryFn: () => {
      return getCancelReason().then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Post Cancel Reason API ---*/

interface CancelReasons {
  content: string;
  cancel_reason: string;
}

const postCancelReason = (id: string, data: CancelReasons) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.CANCEL, { id }),
    data
  });
};

const postCancelReasonKey = "postCancelReason";

export const usePostCancelReason = (id: string) => {
  return useMutation({
    mutationKey: [postCancelReasonKey],
    mutationFn: (data: CancelReasons) => {
      return postCancelReason(id, data);
    }
  });
};

/*--- update address API ---*/

interface UpdateRequest {
  address?: string;
  usage?: number;
  car_brand_model?: string;
  status?: string;
  car_brand_model_year?: string;
  car_color?: string;
}

const updateInfoData = (id: string, data: UpdateRequest) => {
  return httpAPIGW({
    method: "PATCH",
    url: endPointUrlNormalizer(dealersEndPoints.SALES_REQUEST_DETAIL, { id }),
    data
  });
};

const updateAddressKey = "updateAddress";

export const useUpdateInfoData = (id: string) => {
  return useMutation({
    mutationKey: [updateAddressKey],
    mutationFn: (data: UpdateRequest) => {
      return updateInfoData(id, data);
    }
  });
};

/*--- Post Deal Concierge Sale ---*/

export interface SubmitDealResoponse {
  deal_date: number | string;
  visitor_name: string;
  visitor_phone_number: string;
  price: number;
  karnameh_commission: number;
  sale_concierge_commission: number;
  buy_concierge_commission: number;
  pos_receipt_number: string;
  deal_description: string | null;
  buy_id: number;
  warranty_number: string | null;
  sale_concierge_commission_percent: number | null;
  buy_concierge_commission_percent: number | null;
  is_warrantied: boolean | null;
  affidavit_images: string[] | [];
  affidavit_documents: Record<
    "affidavit" | "payment_receipt" | "other",
    {
      name: string | null;
      url: string | null;
    } | null
  >;
}

export interface UpdateSubmitDealParams
  extends Omit<SubmitDealResoponse, "affidavit_documents"> {
  affidavit_documents: Partial<
    Record<"affidavit" | "payment_receipt" | "other", string | null>
  >;
}

const postSubmitDeal = (id: string, data: UpdateSubmitDealParams) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.SUBMIT_DEAL, { id }),
    data
  });
};

const postSubmitDealKey = "postSubmitDeal";

export const usePostSubmitDeal = (id: string) => {
  return useMutation({
    mutationKey: [postSubmitDealKey],
    mutationFn: (data: UpdateSubmitDealParams) => {
      return postSubmitDeal(id, data);
    },
    onSuccess: () => undefined
  });
};

/*--- Update Deal Concierge Sale ---*/

const updateSubmitDeal = (id: number, data: UpdateSubmitDealParams) => {
  return httpAPIGW({
    method: "PUT",
    url: endPointUrlNormalizer(dealersEndPoints.SUBMIT_DEAL, { id: `${id}` }),
    data
  });
};

const updateSubmitDealKey = "updateSubmitDeal";

export const useUpdateSubmitDeal = (id: number) => {
  return useMutation({
    mutationKey: [updateSubmitDealKey],
    mutationFn: (data: UpdateSubmitDealParams) => {
      return updateSubmitDeal(id, data);
    },
    onSuccess: () => undefined
  });
};

export interface KarnamehAds {
  title: string;
  brand_model: string;
  persian_brand_model?: string;
  usage: number | null;
  year: number;
  color: string;
  city: number | null;
  third_party_insurance_deadline: number;
  price: number;
  gearbox: string;
  body_status: string;
  description: string;
  images: string[] | [];
  edit_reason?: string | null;
}

const getSearchAllRequest = async (search: string) => {
  return httpAPIGW<SalesExpert>({
    method: "GET",
    url: dealersEndPoints.SALES_REQUESTS,
    params: { search }
  });
};

const getSearchAllRequestKey = "getSearchAllRequest";

export const useGetSearchAllRequest = (search: string) => {
  return useQuery({
    queryKey: [getSearchAllRequestKey, search],
    staleTime: 0,
    queryFn: () => {
      return getSearchAllRequest(search).then((res) => {
        return res.data;
      });
    }
  });
};

/*--- Attach Divar Ads ---*/

const postAttachDivarAds = (
  concierge_sale_id: number,
  inspection_id: number,
  data: null
) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.ATTACH_REPORT, {
      concierge_sale_id: `${concierge_sale_id}`,
      inspection_id: `${inspection_id}`
    }),
    data
  });
};

const postAttachDivarAdsKey = "postAttachDivarAds";

export const usePostAttachDivarAds = (
  concierge_sale_id: number,
  inspection_id: number
) => {
  return useMutation({
    mutationKey: [postAttachDivarAdsKey],
    mutationFn: (data: null) => {
      return postAttachDivarAds(concierge_sale_id, inspection_id, data);
    }
  });
};

/*--- Calculate Commission ---*/

interface CommissionPostData {
  karnameh_commission: number;
  sale_concierge_commission: number;
  buy_concierge_commission: number;
}

interface CommissionGetData {
  buy_commission: number;
  sale_commission: number;
}

const calculateCommission = (data: CommissionPostData) => {
  return httpAPIGW<CommissionGetData>({
    method: "POST",
    url: dealersEndPoints.CALCULATE_COMMISSION,
    data
  });
};

export const useCalculateCommission = () => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof calculateCommission>>,
    CommissionPostData
  > = (data) => {
    return calculateCommission(data);
  };

  const mutationKey: MutationKey = [
    "/concierge-sale/submit-deal/calculate-commission"
  ];

  return useMutation<
    Awaited<ReturnType<typeof calculateCommission>>,
    AxiosError<GlobalErrorModel>,
    CommissionPostData
  >({
    mutationKey,
    mutationFn,
    onSuccess: () => undefined
  });
};

//#region Infinite Scroll Pagination
interface PaginationData {
  results: [
    {
      id: number;
      applicant_full_name: string;
      car_brand_model: string;
      karnameh_pricing_lower_bound: number;
      karnameh_pricing_upper_bound: number;
      address: string;
      car_brand_model_pe: string;
      car_brand_model_year: string;
      phone_number: string;
      status: string;
      city: string;
      expiration_time: string | null;
      is_new_financial_model: boolean;
    }
  ];
  count: number;
  next: string | null;
  previous: string | null;
}

const getPaginationData = async (status: string, page: number) => {
  return httpAPIGW<PaginationData>({
    method: "GET",
    url: dealersEndPoints.SALES_REQUESTS,
    params: {
      status,
      page,
      page_size: 10
    }
  });
};

const getPaginationDataKey = "getPaginationData";

export const useGetPaginationData = (status: string, pageParam: number) => {
  return useInfiniteQuery({
    queryKey: [getPaginationDataKey, status, pageParam],
    queryFn: ({ pageParam = 1 }) => {
      return getPaginationData(status, pageParam).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.next ? allPages.length + 1 : undefined;
    }
  });
};

//#endregion

//#region Get Sale Expert Detal Version 2

export interface CarPostData extends KarnamehAds {
  state: string;
  edit_reason: string | null;
}

export type CommissionDetail = { title: string; value: string; impact: number };

interface SaleExpertDetailV2 {
  id: number;
  applicant_full_name: string;
  phone_number: string;
  status: string;
  car_brand_model: string;
  car_brand_model_fa: string;
  car_brand_model_year: string;
  expiration_time: string | null;
  usage: number;
  car_status: {
    extra_info: {
      count: number | null;
    };
    selected_part: string | null;
  };
  dealership_manage_token: string | null;
  karnameh_pricing_lower_bound: number;
  karnameh_pricing_upper_bound: number;
  address: string;
  django_panel_link: string;
  car_color: string;
  roof_painted_or_replacement: boolean;
  front_chassis_painted_or_damaged: boolean;
  rear_chassis_painted_or_damaged: boolean;
  djalali_year: number;
  is_car_health_filled: boolean;
  car_options: string | null;
  car_status_description: string | null;
  is_new_financial_model: boolean;
  price_agreements:
    | []
    | {
        submission_date: string;
        price: number;
        advertisement_price: number;
        karnameh_commission: number;
      }[];
  pricing_team_info: {
    karnameh_pricing_lower_bound: number | null;
    karnameh_pricing_upper_bound: number | null;
    price_expert_name: string | null;
  };

  deals?: {
    deal_date: string;
    visitor_name: string;
    visitor_phone_number: string;
    buy_id: number;
    price: number;
    karnameh_commission: number;
    sale_concierge_commission_percent: number | null;
    sale_concierge_commission: number;
    buy_concierge_commission_percent: number | null;
    buy_concierge_commission: number;
    pos_receipt_number: string;
    deal_description: string;
    warranty_number: string | null;
    is_warrantied: boolean | null;
    id: number;
    affidavit_images: string[] | [];
    affidavit_documents: {
      affidavit: {
        name: string;
        url: string;
      };
      payment_receipt: {
        name: string;
        url: string;
      };
      other: {
        name: string;
        url: string;
      };
    };
    sale_concierge_commission_detail?: CommissionDetail[];
  } | null;
  car_post?: CarPostData | null;
  car_post_token?: string;
  karnameh_post_link?: string;
  cancel_reason: string;
  city: number | null;
  city_name: string | null;
  operator_note: string | null;
  health_state: string | null;
  lead_health_notif: string | null;
  is_leasing_available?: boolean;
  token: string | null;
  is_canceled: boolean;
  inspections:
    | [
        {
          id: number;
          name: string;
          phone_number: string;
          state: string;
          created_at: string;
          report_link: string;
        }
      ]
    | [];
}

const getSaleExpertDetailV2 = async (id: number) => {
  return httpAPIGW<SaleExpertDetailV2>({
    method: "GET",
    url: endPointUrlNormalizer(dealersEndPoints.SALES_REQUEST_DETAIL, {
      id: `${id}`
    })
  });
};

const getSaleExpertDetailV2Key = "getSaleExpertDetailV2";

export const useGetSaleDetailV2 = (
  id: number,
  options?: UseQueryOptions<unknown, unknown, SaleExpertDetailV2>
) => {
  return useQuery({
    queryKey: [getSaleExpertDetailV2Key, id],
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: () => {
      return getSaleExpertDetailV2(id)
        .then((res) => {
          const data = res.data;

          data.price_agreements = data.price_agreements.sort((a, b) => {
            if (
              new Date(a.submission_date).getTime() <
              new Date(b.submission_date).getTime()
            ) {
              return 1;
            }
            return -1;
          });
          return data;
        })
        .catch(() => {
          return {} as SaleExpertDetailV2;
        });
    },
    ...options
  });
};

//#endregion

//#region gets sales expert timeslots
export interface GetSalesExpertTimeItem {
  available_center: boolean;
  available_in_person: boolean;
  end: string; // "{number}{number}:{number}{number}"
  final_price: number;
  is_immediate: boolean;
  pk: number;
  price_diff: number;
  price_diff_reason: string;
  start: string; // "{number}{number}:{number}{number}"
  start_timestamp: number;
  title: string;
}

interface GetSalesExpertTimeResponse {
  data: GetSalesExpertTimeItem[];
}

interface GetSalesExpertTimeParams {
  city: number | null;
  brand_model: string;
}

const getSalesExpertTime = async (params: GetSalesExpertTimeParams) => {
  return httpAPIGW<GetSalesExpertTimeResponse>({
    method: "GET",
    url: restEndpoints.EXPERT_TIME,
    params: {
      city_id: params.city === 1763 ? 1 : params.city,
      brand_model: params.brand_model
    }
  }).then((res) => {
    if (res.data.data && Array.isArray(res.data.data)) {
      return res.data.data;
    } else {
      return [];
    }
  });
};

const getSaleExpertTimeSlotWithOrderId = async (id: number) => {
  try {
    const saleExpertDetails = await getSaleExpertDetailV2(id);

    const saleExpertTimeSlots = await getSalesExpertTime({
      city: saleExpertDetails.data.city,
      brand_model: saleExpertDetails.data.car_brand_model
    });

    return saleExpertTimeSlots;
  } catch (err) {
    return Promise.reject();
  }
};

const getSaleExpertTimeSlotWithOrderIdKey = "getSaleExpertTimeSlotWithOrderId";

export const useGetSaleExpertTimeSlotWithOrderId = (
  id: number,
  options?: UseQueryOptions<
    unknown,
    unknown,
    Awaited<ReturnType<typeof getSaleExpertTimeSlotWithOrderId>>
  >
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSaleExpertTimeSlotWithOrderId>>
  > = () => {
    return getSaleExpertTimeSlotWithOrderId(id);
  };

  const queryKey: QueryKey = [getSaleExpertTimeSlotWithOrderIdKey, id];

  return useQuery({
    queryFn,
    queryKey,
    ...options
  });
};

//#endregion

interface PostCreateInspectionBody {
  customer_name?: string;
  phone_number?: string;
  city?: number;
  center?: string;
  brand_model?: string;
  visit_place: string; //
  description?: string;
  time_slot_id: number; //
  concierge_sale?: string;
  visit_time: string; // 10:30
  selected_package?: string;
  payment_type?: string;
  platform?: string;
  city_id?: number;
  service_type?: string;
  inspection_center?: string;
  shift_id?: number;
}

// interface CreateInspectionResponse {

// }

const postCreateInspection = (
  data: PostCreateInspectionBody,
  id: string | undefined
) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(api2Endpoints.INSPECTION_DETAIL, {
      id: id ?? ""
    }),
    data
  });
};

export const usePostCreateInspection = (
  id: string | undefined,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateInspection>>,
    unknown,
    PostCreateInspectionBody
  >
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateInspection>>,
    PostCreateInspectionBody
  > = (params) => {
    return postCreateInspection(params, id);
  };

  const mutationKey = ["postCreateInspection"];

  return useMutation({
    mutationFn,
    mutationKey,
    ...options
  });
};

//#region pricing history concierge sale

interface GetPricingHistoryList {
  concierge_sale_id: number;
  price_upper_bound: number;
  price_lower_bound: number;
  price_expert_name: string;
  pricing_description: string;
  car_detail: {
    car_brand_model?: string;
    car_brand_model_year?: number;
    djalali_year?: number;
    usage?: number;
    car_color?: string;
    car_status?: { selected_part: string };
    car_options?: string;
    persian_car_status?: string;
    description?: string;
  };
  created_at: string;
}

const getPricingHistoryList = async (id: number) => {
  return httpAPIGW<GetPricingHistoryList[]>({
    method: "GET",
    url: endPointUrlNormalizer(dealersEndPoints.PRICE_HISTORY, { id: `${id}` })
  }).then((res) => res.data);
};

const getPricingHistoryListKey = "getPricingHistoryList";

export const useGetPricingHistoryList = (
  id: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getPricingHistoryList>>>
) => {
  return useQuery({
    queryKey: [getPricingHistoryListKey, id],
    staleTime: 0,
    queryFn: () => {
      return getPricingHistoryList(id);
    },
    ...options
  });
};

//#endregion

//#region share concierge sale

export interface ConciergeSaleShare {
  phone_number?: string;
  lead_info_and_price?: boolean;
  car_post_links?: boolean;
  last_inspection?: boolean;
  leasing_landing?: boolean;
  last_inspection_report_link?: string | null;
}

const postConciergeSaleShare = async (id: number, data: ConciergeSaleShare) => {
  return httpAPIGW<ConciergeSaleShare>({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.SALE_SHARE, { id: `${id}` }),
    data
  });
};

const postConciergeSaleShareKey = "postConciergeSaleShare";

export const usePostConciergeSaleShare = (id: number) => {
  return useMutation({
    mutationKey: [postConciergeSaleShareKey],
    mutationFn: (data: ConciergeSaleShare) => {
      return postConciergeSaleShare(id, data);
    }
  });
};

//#endregion

//#region

interface RegisterCarPostParams {
  brand_model: string;
  usage: number;
  year: number;
  color: string;
  city: number | null;
  conciergeSaleId: number;
}

interface RegisterCarPostResponse {
  token: string;
}

export const registerCarPost = (params: RegisterCarPostParams) => {
  return httpAPIGW<RegisterCarPostResponse>({
    method: "POST",
    url: endPointUrlNormalizer(api2Endpoints.REGISTER_CAR_POST, {
      conciergeSaleId: `${params.conciergeSaleId}`
    }),
    data: params
  }).then((res) => res.data);
};

export const useRegisterCarPost = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof registerCarPost>>,
    unknown,
    RegisterCarPostParams
  >
) => {
  return useMutation({
    mutationFn: (params: RegisterCarPostParams) => {
      return registerCarPost(params);
    },
    ...options
  });
};

//#endregion
