import { Stack } from "@mui/material";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar
} from "recharts";

import { FC } from "react";
import { GetSalesDailyReportResponse } from "@/services/api/concierge-sale/sales-expert-report";

const DataLineChart: FC<{ data?: GetSalesDailyReportResponse[] }> = ({
  data
}) => {
  return (
    <Stack height={400} p={4} minHeight={400}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={700} height={500} data={data}>
          <XAxis
            dataKey="hour"
            tickFormatter={(value) => `${value}:00`}
            fontSize={"10px"}
            fontWeight={400}
            interval={0}
          />
          <Tooltip
            labelFormatter={(value) => {
              return ` ${value}:00`;
            }}
            formatter={(value, name, props) => [`امتیاز: ${value} `]}
          />

          <YAxis
            width={1}
            tickFormatter={() => ""}
            axisLine={true}
            tickLine={false}
          />
          <Bar dataKey="score" barSize={1} fill="#EBEBEB" />
          <Line
            dataKey="score"
            stroke="#694FB4"
            dot={{ stroke: "#694FB4", strokeWidth: 1, fill: "#694FB4", r: 5 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default DataLineChart;
