import { Checkbox, Radio, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import { useCitySelectInternal } from '@/components/CitySelect/configs';

interface PropTypes {
  title: string;
  onClick: () => void;

  checked: boolean;

  hasBorderBottom?: boolean;
}

const SelectableItem: FC<PropTypes> = (props) => {
  const { title, onClick, hasBorderBottom = false, checked } = props;

  const { isMultiple } = useCitySelectInternal();

  const theme = useTheme();
  return (
    <Stack
      component="div"
      onClick={onClick}
      sx={styles.container}
      style={{
        borderBottom: hasBorderBottom
          ? `1px solid ${theme.palette.surface.paper}`
          : 'unset',
      }}
    >
      <Typography>{title}</Typography>
      {!isMultiple && <Radio checked={checked} />}
      {isMultiple && <Checkbox checked={checked} />}
      {/* <ChevronLeftRoundedIcon /> */}
    </Stack>
  );
};

export default SelectableItem;
