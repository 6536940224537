//#region create invoice

import { httpAPIGW } from "@/services/http-clients";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { useMutation } from "react-query";

const dealersEndPoints = {
  DIVAR_POST: `dealers/api/v1/concierge-sale/expert-app/divar-post`
};

export interface DivarPost {
  action: "get_otp" | "confirm_otp" | "check_token_expiration";
  otp?: string;
}

export interface DivarPostResponse {
  message: string;
}

const postDivarPost = async (data: DivarPost) => {
  return httpAPIGW<DivarPostResponse>({
    method: "POST",
    url: endPointUrlNormalizer(dealersEndPoints.DIVAR_POST, {}),
    data
  });
};

const postDivarPostKey = "postDivarPost";

export const usePostDivarPost = () => {
  return useMutation({
    mutationKey: [postDivarPostKey],
    mutationFn: (data: DivarPost) => {
      return postDivarPost(data);
    }
  });
};

//#endregion
