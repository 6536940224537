import BottomSheetHeader from "@/components/BottomSheetHeader";
import BottomSheetModal from "@/components/BottomSheetModal";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetCommentsList,
  useGetRatingReport
} from "@/services/api/concierge-sale/dealers";
import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import StarIcon from "@mui/icons-material/Star";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CommentCard from "./components/CommentCard";
import CustomerReportDetailCard from "./components/CustomerReportDetailCard";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#EEEEEE"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FBC02D"
  }
}));

const CustomerReport = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { setHeaderTitle } = useRootLayout();
  const [openBottomSheet, setOpenBottomSheet] = useState({
    sixMonth: false,
    nps: false
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("امتیاز و نظرات کاربران");
    }
  }, [setHeaderTitle]);

  const { data: ratingReport } = useGetRatingReport();

  const {
    data: commentsList,
    fetchNextPage,

    isLoading,
    isRefetching,
    status
  } = useGetCommentsList();

  const isDataLoading = (isLoading || isRefetching) && status !== "idle";

  const scoresList = Object.entries(
    ratingReport?.six_month_score?.score_counts || {}
  ).map(([key, value]) => {
    return { key: key, value: value };
  });

  return (
    <>
      <Stack
        sx={{ background: (th) => th.palette.background.paper }}
        height={"100%"}
        width={"100%"}
        padding={2}
        gap={4}
        overflow={"auto"}
        ref={containerRef}
        onScroll={() => {
          if (
            isDataLoading ||
            (containerRef?.current?.scrollTop ?? 0) +
              (containerRef?.current?.getBoundingClientRect().height ?? 0) <
              (containerRef?.current?.scrollHeight ?? 0)
          )
            return;
          fetchNextPage();
        }}
      >
        <Grid container columns={4} spacing={2}>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="روز در کارنامه"
              value={ratingReport?.days_in_karnameh ?? 0}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="لید دریافتی"
              value={ratingReport?.received_concierge_sale}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="آگهی منتشر شده"
              value={ratingReport?.published_concierge_sale}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="فروش موفق"
              value={ratingReport?.success_concierge_sale}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="امتیاز شش ماهه"
              value={ratingReport?.six_month_score.average_score}
              icon={<KeyboardArrowLeftOutlinedIcon color="action" />}
              iconOnClick={() =>
                setOpenBottomSheet((prev) => ({ ...prev, sixMonth: true }))
              }
            />
          </Grid>
          <Grid item xs={2}>
            <CustomerReportDetailCard
              title="NPS شش ماهه"
              value={ratingReport?.days_in_karnameh}
              icon={<InfoOutlinedIcon color="action" fontSize="small" />}
              iconOnClick={() =>
                setOpenBottomSheet((prev) => ({ ...prev, nps: true }))
              }
            />
          </Grid>
        </Grid>
        {commentsList?.pages?.length && (
          <Stack gap={2}>
            <Typography fontSize={14} fontWeight={600} color={"action.active"}>
              کاربران درباره شما و کارنامه چه می‌گویند؟
            </Typography>
            <Stack gap={1}>
              {commentsList?.pages.map((page) => (
                <>
                  {page?.results?.map((item, i) => {
                    return (
                      <CommentCard
                        key={i}
                        content={item.comment}
                        score={item.sale_expert_score}
                      />
                    );
                  })}
                </>
              ))}
              {isDataLoading && (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
      <BottomSheetModal
        open={openBottomSheet.nps}
        onClose={() => {
          setOpenBottomSheet((prev) => ({ ...prev, nps: false }));
        }}
      >
        <BottomSheetHeader title={"NPS چیست؟"} />
        <Stack px={4} py={2}>
          <Typography fontSize={"14px"}>
            معیاری برای سنجش وفاداری و رضایت مشتری است که از مشتریان می‌پرسد تا
            چه اندازه مایلند یک محصول یا خدمت را به دیگران توصیه کنند.
          </Typography>
        </Stack>
      </BottomSheetModal>
      <BottomSheetModal
        open={openBottomSheet.sixMonth}
        onClose={() => {
          setOpenBottomSheet((prev) => ({ ...prev, sixMonth: false }));
        }}
      >
        <BottomSheetHeader title={"امتیاز ۶ ماهه"} />
        <Stack px={4} py={2} gap={2}>
          {scoresList?.map((item, i) => {
            return (
              <Stack
                key={i + item.key + item.value}
                flexDirection={"row"}
                alignItems={"center"}
                gap={1}
              >
                <StarIcon fontSize="small" color="action" />
                <Typography fontSize={14} fontWeight={400}>
                  {Number(item?.value ?? 0)}
                </Typography>
                <Stack width={"100%"}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={Number(item?.value ?? 0) * 10}
                  />
                </Stack>
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color={"action.active"}
                  sx={{ textWrap: "nowrap" }}
                >
                  {item.key} نفر
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </BottomSheetModal>
    </>
  );
};

export default CustomerReport;
