import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  GetSupervisorAssistantsScoreResponse,
  useGetSupervisorAssistantsScore
} from "@/services/api/concierge-sale/sales-expert-report";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";
import BottomSheetModal from "@/components/BottomSheetModal";
import BottomSheetHeader from "@/components/BottomSheetHeader";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 4,
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "20px",

  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.surface.default
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA"
  }
}));

const AssistantsTabel = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState<
    GetSupervisorAssistantsScoreResponse & { isOpen: boolean }
  >({
    isOpen: false
  });

  const { data: supervisorAssistantsData } = useGetSupervisorAssistantsScore();
  const toRialFormat = (value: number) => `${value.toLocaleString("fa-IR")}`;
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none"
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "100%", padding: 1.5 }}>
                نام دستیار
              </StyledTableCell>
              <StyledTableCell sx={{ textWrap: "nowrap", padding: 1.5 }}>
                مبلغ فروش
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {supervisorAssistantsData?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ paddingX: 2 }}
                >
                  {row?.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {toRialFormat(Number(row?.total_commission_amount))}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ cursor: "pointer" }}>
                  <Button
                    variant="text"
                    onClick={() => setModal({ isOpen: true, ...row })}
                  >
                    <ChevronLeftIcon fontSize="small" color="action" />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BottomSheetModal
        open={modal.isOpen}
        onClose={() => setModal({ isOpen: false })}
      >
        <BottomSheetHeader title={modal.name ?? ""}></BottomSheetHeader>

        <Stack gap={3}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none"
            }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    sx={{ paddingY: 1.5, textWrap: "nowrap", fontSize: "12px" }}
                  >
                    عنوان
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ paddingY: 1.5, textWrap: "nowrap", fontSize: "12px" }}
                  >
                    لید ۵ روزه
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ paddingY: 1.5, textWrap: "nowrap", fontSize: "12px" }}
                  >
                    تعداد فروش
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ paddingY: 1.5, textWrap: "nowrap", fontSize: "12px" }}
                  >
                    مبلغ فروش
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    component="th"
                    sx={{ textWrap: "nowrap", paddingY: 1.5 }}
                  >
                    شرکتی
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.karnameh_last_5_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.karnameh_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {toRialFormat(Number(modal?.karnameh_commission_amount))}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    component="th"
                    sx={{ textWrap: "nowrap", paddingY: 1.5 }}
                  >
                    شخصی
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.personal_last_5_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.personal_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {toRialFormat(Number(modal?.personal_commission_amount))}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    component="th"
                    sx={{ textWrap: "nowrap", paddingY: 1.5 }}
                  >
                    کل
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.total_last_5_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {modal?.total_deal_count}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {toRialFormat(Number(modal?.total_commission_amount))}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            sx={{ marginX: 3, marginBottom: 3 }}
            onClick={() =>
              navigate(
                `/concierge-sale/assistant-performance-report?id=${modal.id}`
              )
            }
            variant="outlined"
          >
            مشاهده عملکرد دستیار
          </Button>
        </Stack>
      </BottomSheetModal>
    </>
  );
};

export default AssistantsTabel;
