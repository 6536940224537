import { FC, useEffect, useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Typography,
  Stack,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useParams } from "react-router";
import { useGetSaleDetailV2, useUpdateInfoData } from "@/services/api/sales";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const EditAddress: FC<Props> = ({ open, showModal, title }) => {
  const { id } = useParams();
  const { data, refetch } = useGetSaleDetailV2(Number(id), {
    enabled: Boolean(id)
  });
  const { mutateAsync, isLoading } = useUpdateInfoData(String(id));

  const [address, setAddress] = useState("");

  useEffect(() => {
    if (data) {
      setAddress(data?.address);
    }
  }, [data?.address]);

  const changeHandler = (event: { target: { value: string } }) => {
    setAddress(event.target.value);
  };

  const submitHandler = () => {
    mutateAsync({
      address: String(address)
    }).then(() => {
      refetch();
      showModal(false);
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.pirceModal}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          {" "}
          <TextField
            sx={{ width: "100%", lineHeight: "1.7", mb: 3 }}
            id="outlined-multiline-static"
            label="آدرس"
            multiline
            value={address}
            rows={3}
            onChange={changeHandler}
            variant="outlined"
          />
          <Button
            disabled={Boolean(!address) || isLoading}
            onClick={submitHandler}
          >
            {!isLoading && "ثبت تغییرات"}
            {isLoading && (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            )}
          </Button>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default EditAddress;
