import {
  Box,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { buildDynamicYupSchema } from "@/utils/form-utils/build-dynamic-yup-schema";
import { Field, FieldProps, Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import ImageUploader from "@/components/ImageUploader";
import { ArrowBackIos } from "@mui/icons-material";
import {
  useExecuteStateAction,
  useUploadLeasingFile
} from "@/services/api/leasing/requests";
import useAppSelector from "@/hooks/useAppSelector";
import useAppDispatch from "@/hooks/useAppDispatch";
import { leasingActions } from "@/store/slices/leasing";
import { DateCmp } from "@/components/DateCmp/DateCmp";
import { toast } from "react-toastify";

type Props = {
  orderId: number;
};

const DynamicInputsDrawer = ({ orderId }: Props) => {
  const { isOpen, action } = useAppSelector(
    (state) => state.leasing.dynamicInputDrawer
  );
  const dispatch = useAppDispatch();

  const { mutateAsync: uploadFile, isLoading: uploadingImage } =
    useUploadLeasingFile(undefined, {
      onSuccess: () => {
        return;
      }
    });

  const { dynamicInputDrawerStateHandler } = leasingActions;

  const onCloseDrawer = useCallback(() => {
    dispatch(
      dynamicInputDrawerStateHandler({ isOpen: false, action: undefined })
    );
  }, [dispatch, dynamicInputDrawerStateHandler]);

  const inputs = useMemo(() => {
    if (!action || !action.params || !action.params.inputs) return [];
    return action.params.inputs;
  }, [action]);

  const validationSchema = buildDynamicYupSchema(inputs);
  const { mutateAsync: execute, isLoading } = useExecuteStateAction();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onCloseDrawer}
      onOpen={() => null}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "90%",
          overflow: "auto",
          backgroundColor: (th) => th.palette.surface.default,
          p: 2
        }}
      >
        <Stack
          p={2}
          bgcolor="white"
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography fontSize="1.25rem">{action?.name_fa}</Typography>
          <ArrowBackIos
            onClick={onCloseDrawer}
            sx={{
              cursor: "pointer"
            }}
          />
        </Stack>
        <Typography mb={2} variant="subtitle2">
          {action?.description}
        </Typography>
        <Formik
          enableReinitialize
          initialValues={inputs.reduce(
            (acc, input) => {
              if (input.type === "file" || input.type === "image") {
                acc[input.name] = input.default_value
                  ? {
                    key: input.default_value,
                    url: input.default_value
                  }
                  : "";
              } else {
                acc[input.name] = input.default_value ?? "";
              }
              return acc;
            },
            {} as Record<string, any>
          )}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (!action) return;
            const inputBody: Record<string, any> = {};
            Object.keys(values).forEach((key) => {
              if (typeof values[key] === "object") {
                inputBody[key] = values[key].key;
              } else {
                inputBody[key] = values[key];
              }
            });
            execute({
              orderId: orderId,
              actionId: action.id,
              body: {
                inputs: inputBody
              }
            }, {
              onSuccess: (res) => {
                const isError = res.output.error
                toast.success(res.output.message, {
                  type: isError ? 'error' : 'success'
                })
                if (!isError) {
                  onCloseDrawer()
                }
              }
            })
          }}
        >

          {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values
            }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                {inputs.map((input) => {
                  if (input.type === "date") {
                    const currentDate = new Date().toLocaleDateString("fa-IR", {
                      calendar: "persian"
                    });
                    const extractedCurrentYear = Number(
                      currentDate.split("/")[0]
                    );
                    const currentYear = isNaN(extractedCurrentYear)
                      ? 1403
                      : extractedCurrentYear;
                    const years = Array.from(
                      { length: currentYear - 1310 + 2 },
                      (_, i) => (currentYear - i + 1).toString()
                    );
                    return (
                      <DateCmp
                        key={input.name}
                        title={input.title}
                        years={years}
                        date={
                          values[input.name]
                            ? values[input.name].split("T")[0]
                            : null
                        }
                        onDateChange={(date) => setFieldValue(input.name, date)}
                      />
                    );
                  }
                  if (input.type === "text" || input.type === "number") {
                    return (
                      <Field name={input.name} key={input.name}>
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label={input.title}
                            type={input.type}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[input.name]}
                            defaultValue={
                              input.default_value
                            }
                            error={Boolean(
                              touched[input.name] && errors[input.name]
                            )}
                            placeholder={input.placeholder}
                          />
                        )}
                      </Field>
                    );
                  }
                  if (input.type === "file" || input.type === "image") {
                    return (
                      <Box key={input.name}>
                        <ImageUploader
                          onChange={(imgData) => {
                            if (!imgData) {
                              setFieldValue(input.name, null);
                              return;
                            }
                            uploadFile({
                              file: imgData.file,
                              scope: "leasing-documents"
                            }).then((res) => {
                              setFieldValue(
                                input.name,
                                {
                                  key: res.data.key,
                                  url: res.data.file_url
                                },
                                false
                              );
                            });
                          }}
                          value={
                            values[input.name] ? values[input.name].url : null
                          }
                          key={input.name}
                          title={input.title}
                          variant={["upload", "camera"]}
                        />
                        {errors[input.name] && (
                          <Typography color="error" variant="caption">
                            {(errors[input.name] as Record<string, string>).key}
                          </Typography>
                        )}
                      </Box>
                    );
                  }
                })}
                <LoadingButton
                  sx={{
                    mt:2
                  }}
                  loading={isLoading || uploadingImage}
                  type="submit"
                  variant="contained"
                  disabled={isLoading || uploadingImage}
                >
                  {action?.params.button_text}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Stack>
    </SwipeableDrawer>
  );
};
export default DynamicInputsDrawer;
