import { useCallback, useEffect, useRef, useState } from 'react';

const useLogic = (open: boolean, onClose?: () => void) => {
  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = setTimeout(
      () => {
        setShow(open);
      },
      open ? 50 : 300,
    );
  }, [open]);

  const onCloseHandler = useCallback(() => {
    if (typeof onClose !== 'undefined') {
      onClose();
    }
  }, [onClose]);

  return {
    show,
    onCloseHandler,
  };
};

export default useLogic;
