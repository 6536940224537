import { monthList } from "@/components/Finance/configs";
import { GetSalesExpertReportResponse } from "@/services/api/concierge-sale/sales-expert-report";
import { gregorianToJalali } from "@/utils/date";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Rectangle,
  ResponsiveContainer,
  YAxis,
  Legend,
  LegendProps,
  LabelList
} from "recharts";

const LEGEND_CONSTANT: { [key: string]: string } = {
  deal_count: "تعداد لید فروخته شده"
};

const renderLegend = (props: LegendProps) => {
  const { payload } = props;
  return (
    <Stack alignItems={"start"}>
      {payload?.map((entry, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            color: "#000", // Set text color explicitly
            fontSize: "14px" // Optional: Adjust font size
          }}
        >
          {/* Icon */}
          <svg width="12" height="12" style={{ marginRight: 5 }}>
            <rect width="12" height="12" fill={entry.color} />
          </svg>
          {/* Text */}
          <Typography
            fontSize={"10px"}
            fontWeight={400}
            sx={{
              textWrap: "nowrap"
            }}
          >
            {LEGEND_CONSTANT[entry.value]}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export const monthFormatter = (val: string) => {
  const [year, month, day] = val.split("-").map(Number);
  const jalaliDate = gregorianToJalali(year, month, day);
  const [, jalalimonth] = jalaliDate;
  return monthList[jalalimonth - 1];
};

const SingleBarChart: React.FC<{ data: GetSalesExpertReportResponse[] }> = ({
  data
}) => {
  return (
    <ResponsiveContainer width={"100%"} height={350}>
      <BarChart data={data} margin={{ top: 20, right: 10, left: 10 }}>
        <XAxis
          dataKey="month"
          fontSize={"12px"}
          fontWeight={"400"}
          tickFormatter={(mnth) => monthFormatter(mnth)}
        />
        <YAxis
          width={1}
          tickFormatter={() => ""} // Remove value display
          axisLine={true} // Keep Y-axis line
          tickLine={false} // Hide the tick lines
        />
        <Legend content={renderLegend as any} /> {/* Use custom renderer */}
        <Bar
          dataKey="deal_count"
          fill="#0078FB"
          activeBar={<Rectangle fill="#694FB4" stroke="blue" />}
          radius={[8, 8, 0, 0]}
        >
          <LabelList
            fontSize={"14px"}
            fontWeight={600}
            fill="black"
            position="top"
            dataKey={"deal_count"}
            formatter={(value: number) => value}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SingleBarChart;
