import { createContext, useContext } from 'react';

export const BottomSheetContext = createContext<{
  open: boolean;
  close: () => void;
}>({
  open: false,
  close: () => null,
});

export const useBottomSheet = () => useContext(BottomSheetContext);
