import { httpAPIGW } from "@/services/http-clients";
import { dealersEndPoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { AxiosError } from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";

// #region sale order statistic for divar and bama post
export type GetPostsStatisticsParams = {
  concierge_sale: number;
};

export type PostStatistics = {
  id: number;
  concierge_sale: number;
  third_party: "D" | "B"; //D => Divar, B => Bama
  link: string;
  dates: string[];
  values: number[];
  total_views: number;
};

export type GetPostsStatisticsResponse = PostStatistics[];

const getPostsStatistics = (params: GetPostsStatisticsParams) => {
  return httpAPIGW<GetPostsStatisticsResponse>({
    method: "GET",
    url: dealersEndPoints.THIRD_PARTY,
    params
  }).then((res) => res.data);
};

export const getPostsStatisticKey = (params: GetPostsStatisticsParams) => [
  `${dealersEndPoints.THIRD_PARTY}-${params.concierge_sale}`
];

export const useGetPostsStatistics = (
  params: GetPostsStatisticsParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getPostsStatistics>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getPostsStatistics>>,
    ReturnType<typeof getPostsStatisticKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getPostsStatisticKey(params),
    queryFn: () => getPostsStatistics(params)
  });
};

//#endregion

// #region register and update divar and bama post url for a dealer order
export type RegisterPostsUrlParams = {
  concierge_sale: number;
  link: string;
  third_party: PostStatistics["third_party"];
};

const registerPostsUrl = (data: RegisterPostsUrlParams) => {
  return httpAPIGW({ method: "POST", url: dealersEndPoints.THIRD_PARTY, data });
};

export const useRegisterPostsUrl = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof registerPostsUrl>>,
    AxiosError<unknown>,
    RegisterPostsUrlParams
  >
) => {
  return useMutation({ ...options, mutationFn: registerPostsUrl });
};

export type UpdatePostsUrlParams = RegisterPostsUrlParams & { id: number };

const updatePostsUrl = ({ id, ...data }: UpdatePostsUrlParams) => {
  return httpAPIGW({
    method: "PUT",
    url: endPointUrlNormalizer(dealersEndPoints.THIRD_PARTY_DETAIL, {
      id: `${id}`
    }),
    data
  });
};

export const useUpdatePostsUrl = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePostsUrl>>,
    AxiosError<unknown>,
    UpdatePostsUrlParams
  >
) => {
  return useMutation({ ...options, mutationFn: updatePostsUrl });
};
//#endregion

// #region get rating score report
type GetRatingReportResponse = {
  days_in_karnameh: number;
  received_concierge_sale: number;
  published_concierge_sale: number;
  success_concierge_sale: number;
  six_month_score: {
    average_score: number;
    score_counts: Record<string, unknown>;
  };
};

const getRatingReport = () => {
  return httpAPIGW<GetRatingReportResponse>({
    method: "GET",
    url: dealersEndPoints.RATING_REPORT
  }).then((res) => res.data);
};

export const getRatingReportKey = () => [`${dealersEndPoints.RATING_REPORT}`];

export const useGetRatingReport = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getRatingReport>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getRatingReport>>,
    ReturnType<typeof getRatingReportKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getRatingReportKey(),
    queryFn: () => getRatingReport()
  });
};

//#endregion

// #region get comments list

type GetCommentListResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    sale_expert_score: number;
    comment: string;
  }[];
};

const getCommentsList = ({ pageParam }: { pageParam: number | null }) => {
  return httpAPIGW<GetCommentListResponse>({
    method: "GET",
    url: dealersEndPoints.COMMENTS_REPORT + `?page=${pageParam}`
  }).then((res) => res.data);
};

export const getCommentsListKey = () => [`${dealersEndPoints.COMMENTS_REPORT}`];

export const useGetCommentsList = () => {
  return useInfiniteQuery({
    queryKey: getCommentsListKey(),
    queryFn: ({ pageParam = 1 }) => {
      return getCommentsList({ pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.next ? allPages.length + 1 : undefined;
    }
  });
};

//#endregion
