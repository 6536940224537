import { FC, useMemo } from "react";
import { levelsObj, useCitySelectInternal } from "../../configs";
import { useGetClutchPlace } from "@/services/api/clutch";
import { searchInArray } from "@/utils/search";
import SelectableItem from "../DefaultMethod/SelectableItem";


interface PropTypes {
  searchingValue: string;
}

const SearchResults: FC<PropTypes> = (props) => {
  const { searchingValue } = props;
  const { values, isMultiple, onValueChange } = useCitySelectInternal();

  const { data: placeList, isSuccess: isPlaceListSuccess } = useGetClutchPlace(
    {},
  );

  const searchedPlaces = useMemo(() => {
    if (searchingValue.length < 2) {
      return [];
    }
    if (isPlaceListSuccess) {
      return searchInArray(placeList, searchingValue, (searchItem) => {
        return [searchItem.name_en, searchItem.name_fa];
      });
    } else {
      return [];
    }
  }, [isPlaceListSuccess, placeList, searchingValue]);

  return (
    <>
      {searchedPlaces.map((place, placeIndex) => {
        const checked = !!values.find(
          (value) =>
            value.id === place.id ||
            (isMultiple && place.parent_ids.includes(value.id)),
        );
        if (!isMultiple && place.has_children) {
          return null;
        }
        return (
          <SelectableItem
            key={place.id}
            checked={checked}
            onClick={() => onValueChange(place)}
            hasBorderBottom={placeIndex !== searchedPlaces.length - 1}
            title={
              levelsObj[place.level as keyof typeof levelsObj]?.getSearchLabel?.({
                fa: place.name_fa,
                en: place.name_en,
              }) || place.name_fa
            }
          />
        );
      })}
    </>
  );
};

export default SearchResults;
