import { GetSupervisorTargetChartResponse } from "@/services/api/concierge-sale/sales-expert-report";
import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Legend,
  LegendProps,
  CartesianGrid,
  ReferenceLine
} from "recharts";

const LEGEND_CONSTANT: { [key: string]: string } = {
  karnameh_total_commission: "کارنامه",
  personal_total_commission: "شخصی",
  target: "تارگت"
};

const toRialFormat = (value: number) => `${value.toLocaleString("fa-IR")}`;

const HorizontalMixBarChart: React.FC<{
  data?: GetSupervisorTargetChartResponse;
}> = ({ data }) => {
  const renderLegend = (arg: LegendProps) => {
    const { payload } = arg;

    return (
      <Stack alignItems={"start"}>
        {payload?.map((entry, index) => (
          <Stack
            key={`${entry.id}-${index}`}
            p={1}
            flexDirection={"row"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              backgroundColor: index % 2 === 0 ? "transparent" : "#FAFAFA"
            }}
          >
            <Box
              key={`item-${index}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                color: "#000", // Set text color explicitly
                fontSize: "14px" // Optional: Adjust font size
              }}
            >
              {/* Icon */}
              <svg width="12" height="12" style={{ marginRight: 5 }}>
                <rect width="12" height="12" fill={entry.color} />
              </svg>
              {/* Text */}
              <Typography
                fontSize={"10px"}
                fontWeight={400}
                sx={{
                  textWrap: "nowrap"
                }}
              >
                {LEGEND_CONSTANT[entry.value]}
              </Typography>
            </Box>
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              sx={{
                textWrap: "nowrap"
              }}
            >
              {toRialFormat(
                data?.[entry.value as keyof GetSupervisorTargetChartResponse] ??
                  0
              )}
            </Typography>
          </Stack>
        ))}
        <Stack
          p={1}
          flexDirection={"row"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              color: "#000", // Set text color explicitly
              fontSize: "14px" // Optional: Adjust font size
            }}
          >
            {/* Icon */}
            <svg width="12" height="12" style={{ marginRight: 5 }}>
              <rect width="12" height="12" fill={"#3E3E3E"} />
            </svg>
            {/* Text */}
            <Typography
              fontSize={"10px"}
              fontWeight={400}
              sx={{
                textWrap: "nowrap"
              }}
            >
              {LEGEND_CONSTANT["target"]}
            </Typography>
          </Box>
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            sx={{
              textWrap: "nowrap"
            }}
          >
            {toRialFormat(data?.["target"] ?? 0)}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const getRoundedMax = useMemo(() => {
    if (!data) return 0;

    const values = Object.values(data);
    const max = Math.max(...values);
    const paddedMax = max * 1.5;

    const magnitude = 10 ** Math.floor(Math.log10(paddedMax));
    const threshold1_5x = magnitude * 1.5;
    const threshold2x = magnitude * 1.5;

    return paddedMax <= threshold1_5x ? threshold1_5x : threshold2x;
  }, [data]);

  // let arr = Object.values(data ?? {});
  // let max = Math.max(...arr);

  // console.log({ max, getRoundedMax });

  return (
    <ResponsiveContainer width={"100%"} height={250}>
      <BarChart
        layout="vertical" // Change layout to vertical for horizontal bars
        data={[data]}
      >
        <CartesianGrid
          strokeDasharray="1 0"
          vertical={true}
          horizontal={false}
        />
        <XAxis
          fontSize={"10px"}
          fontWeight={400}
          // interval={1}
          type="number" // Set the type to number for horizontal bars
          tickFormatter={(value) => toRialFormat(value)} // Optional: Format axis values
          domain={[
            0,
            (dataMax: number) => {
              return getRoundedMax;
            }
          ]}
        />
        <YAxis
          width={1}
          type="category" // Set the type to category for horizontal bars
          axisLine={true}
          tickLine={false}
          tickFormatter={() => ""}
        />
        <Legend content={renderLegend as any} />
        <ReferenceLine
          x={data?.target} // Position of the vertical line
          stroke="black"
          strokeWidth={2}
          strokeDasharray="3 3"
          z={10}
        />
        <Bar
          // fillOpacity="0.9"
          dataKey={"personal_total_commission"}
          fill="#E9C345"
          stackId="a"
          barSize={60}
        />
        <Bar
          dataKey="karnameh_total_commission"
          fill="#C73DD3"
          stackId="a"
          // fillOpacity="0.9"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalMixBarChart;
