import { OTP } from "@/components/Login/OtpInput";
import { useRootLayout } from "@/hooks/useRootLayout";
import { usePostDivarPost } from "@/services/api/concierge-sale/divar-post";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";

const DivarLogin = () => {
  const navigate = useNavigate();
  const { setHeaderTitle } = useRootLayout();

  const [otpValue, setOtpValue] = useState("");
  const [timerValue, setTimerValue] = useState<string | undefined>(undefined);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + 120000),
    onExpire: () => setTimerValue(undefined),
    autoStart: true
  });

  const { mutateAsync } = usePostDivarPost();

  useEffect(() => {
    mutateAsync({
      action: "get_otp"
    }).then((res) => {
      toast.success(res?.data?.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("ورود به دیوار");
    }
  }, [setHeaderTitle]);

  useEffect(() => {
    setTimerValue(
      `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    );
  }, [seconds, minutes]);

  return (
    <Stack p={4} gap={4}>
      <Typography>کد تأیید پیامک شده است.</Typography>
      <Stack
        direction="row"
        mt="24px"
        gap="10px"
        dir="ltr"
        justifyContent="center"
      >
        <OTP
          separator={<span> </span>}
          value={otpValue}
          onChange={setOtpValue}
          length={6}
        />
      </Stack>
      <Stack
        direction="row"
        onClick={() =>
          isRunning
            ? undefined
            : mutateAsync({
                action: "get_otp"
              }).then((res) => {
                toast.success(res?.data?.message);
                setOtpValue("");
                restart(new Date(Date.now() + 120000), true);
              })
        }
        justifyContent="center"
        mt="20px"
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "24px",
            color: "grey.800"
          }}
          color="black.deactive"
        >
          ارسال مجدد کد
        </Typography>
        {isRunning && (
          <Typography
            sx={{
              width: "40px",
              textAlign: "center",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "24px",
              color: "grey.800"
            }}
          >
            {timerValue}
          </Typography>
        )}
      </Stack>
      <Button
        onClick={() => {
          mutateAsync({
            action: "confirm_otp",
            otp: otpValue
          }).then(() => {
            navigate("/concierge-sale");
          });
        }}
      >
        اتصال حساب
      </Button>
    </Stack>
  );
};

export default DivarLogin;
