import { VehicleType } from "@/services/api/searchCar";
import { ErrorType, httpAPIGW } from "@/services/http-clients";
import { restEndpoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";

import { AxiosError } from "axios";
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "react-query";

export interface City {
  pk: number;
  name: string;
  en_name: string;
}
export type EvaluationV1ServiceTimeListParams = {
  city_id?: number | null;
  brand_model?: string;
  package_id?: number;
  vehicle_type?: VehicleType;
};

export type EvaluationV1ServiceTimeList200DataItem = {
  end: string;
  final_price?: number;
  is_immediate: boolean;
  pk: number;
  price_diff?: number;
  price_diff_reason?: number;
  start: string;
  start_timestamp: number;
  title: string;
  available_center: boolean;
  available_in_person: boolean;
};

export type EvaluationV1ServiceTimeList200 = {
  data?: EvaluationV1ServiceTimeList200DataItem[];
};

export interface EvaluationV1TimeslotAvailabilityParams {
  city_id: number;
  shift_id: number;
  selected_package_id: number;
  vehicle_type?: VehicleType;
}

export interface EvaluationV1TimeslotAvailability200 {
  data: {
    in_person: {
      available: boolean;
      reason?: string;
    };
    in_center: {
      available: boolean;
      reason?: string;
    };
    in_center_city: {
      available: boolean;
      reason?: string;
    };
  };
}

export interface CheckDiscountCode {
  code: string;
  city_id: number;
  brand_model: string;
  selected_package_id: number;
  shift_id: number;
  customer_phone_number: string;
}

export type CheckDiscountCodeBody = CheckDiscountCode;

export type DiscountV1CheckCodeCreate200Data = {
  price: number;
};

export type DiscountV1CheckCodeCreate200 = {
  data?: DiscountV1CheckCodeCreate200Data;
};

export type CreateUpdateInspectionInputBody = CreateUpdateInspectionInput;

export interface CreateUpdateInspectionInput {
  inspection_type: string;
  customer_name: string;
  phone_number: string;
  city: number;
  time_slot_id: number;
  brand_model: string;
  package?: string;
  visit_place?: string;
  description?: string;
  visit_time?: string;
  center?: number | string;
  concierge_sale?: string;
  discount_code: string | null;
}

export type EvaluationV1InspectionUpdate200Data = {
  inspection_reserve_id: number;
};

export type EvaluationV1InspectionUpdate200 = {
  data?: number;
};

export type EvaluationV1InspectionCreate200Data = {
  id: number;
  is_reserve: boolean;
};

export type EvaluationV1InspectionCreate200 =
  EvaluationV1InspectionCreate200Data;

export type EvaluationV1PackagesList200DataItem = {
  address: string;
  body_color_report: boolean;
  description: string;
  discount: number;
  duration: string;
  guarantee: string;
  in_person: boolean;
  latitude: number;
  longitude: number;
  pk: string;
  price: number;
  price_estimation_report: boolean;
  technical_report: boolean;
  title: string;
  slug?: string;
};

export type EvaluationV1PackagesList200 = {
  data?: EvaluationV1PackagesList200DataItem[];
};

export type EvaluationV1PackagesListParams = {
  city_id?: number | null;
  brand_model?: string;
  is_bo?: 1;
  is_available_on_app_or_bo?: 1;
  vehicle_type?: VehicleType;
};

export const evaluationV1PackagesList = (
  params?: EvaluationV1PackagesListParams,
  signal?: AbortSignal
) => {
  return httpAPIGW<EvaluationV1PackagesList200>({
    url: restEndpoints.PACKAGE_LIST,
    method: "get",
    params,
    signal
  });
};

export const getEvaluationV1PackagesListQueryKey = (
  params?: EvaluationV1PackagesListParams
) => [`/evaluation/v1/packages/`, ...(params ? [params] : [])];

export type EvaluationV1PackagesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1PackagesList>>
>;
export type EvaluationV1PackagesListQueryError = ErrorType<unknown>;

export const useEvaluationV1PackagesList = <
  TData = Awaited<ReturnType<typeof evaluationV1PackagesList>>,
  TError = ErrorType<unknown>
>(
  params?: EvaluationV1PackagesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof evaluationV1PackagesList>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEvaluationV1PackagesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof evaluationV1PackagesList>>
  > = ({ signal }) => evaluationV1PackagesList(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof evaluationV1PackagesList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const evaluationV1ServiceTimeList = (
  params?: EvaluationV1ServiceTimeListParams,
  signal?: AbortSignal
) => {
  return httpAPIGW<EvaluationV1ServiceTimeList200>({
    url: restEndpoints.SERVICE_TIME,
    method: "get",
    params,
    signal
  });
};

export const getEvaluationV1ServiceTimeListQueryKey = (
  params?: EvaluationV1ServiceTimeListParams
) => [`/evaluation/v1/service_time/`, ...(params ? [params] : [])];

export type EvaluationV1ServiceTimeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1ServiceTimeList>>
>;
export type EvaluationV1ServiceTimeListQueryError = ErrorType<unknown>;

export const useEvaluationV1ServiceTimeList = <
  TData = Awaited<ReturnType<typeof evaluationV1ServiceTimeList>>,
  TError = ErrorType<unknown>
>(
  params?: EvaluationV1ServiceTimeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof evaluationV1ServiceTimeList>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEvaluationV1ServiceTimeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof evaluationV1ServiceTimeList>>
  > = ({ signal }) => evaluationV1ServiceTimeList(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof evaluationV1ServiceTimeList>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const evaluationV1TimeslotAvailability = (
  params?: EvaluationV1TimeslotAvailabilityParams,
  signal?: AbortSignal
) => {
  return httpAPIGW<EvaluationV1TimeslotAvailability200>({
    url: restEndpoints.TIME_SLOT_AVAILABILITY,
    method: "get",
    params,
    signal
  });
};

export const getEvaluationV1TimeslotAvailabilityQueryKey = (
  params?: EvaluationV1TimeslotAvailabilityParams
) => [`/evaluation/v1/timeslot_availability/`, ...(params ? [params] : [])];

export type EvaluationV1TimeslotAvailabilityQueryResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1TimeslotAvailability>>
>;
export type EvaluationV1TimeslotAvailabilityQueryError = ErrorType<unknown>;

export const useEvaluationV1TimeslotAvailability = <
  TData = Awaited<ReturnType<typeof evaluationV1TimeslotAvailability>>,
  TError = ErrorType<unknown>
>(
  params?: EvaluationV1TimeslotAvailabilityParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof evaluationV1TimeslotAvailability>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEvaluationV1TimeslotAvailabilityQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof evaluationV1TimeslotAvailability>>
  > = ({ signal }) => evaluationV1TimeslotAvailability(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof evaluationV1TimeslotAvailability>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export type InspectionCenterItem = {
  address?: string;
  title?: string;
  geometry?: {
    type: string;
    coordinates: number[];
  };
  time?: string;
  image?: string;
  slug?: string;
  id: number;
};

export type InspectionCenterParams = {
  city: number;
  selected_package_id?: number;
  vehicle_type?: VehicleType;
  shift_id?: number;
};

export type InspectionCenter = InspectionCenterItem[];

export const inspectionCenter = (
  params: InspectionCenterParams,
  signal?: AbortSignal | undefined
) => {
  return httpAPIGW<InspectionCenter>({
    url: restEndpoints.INSPECTION_CENTER,
    method: "get",
    signal,
    params
  });
};

export const getInspectionCenterQueryKey = (params: InspectionCenterParams) => [
  `/evaluation/v1/inspection-center/by-city/`,
  ...(params ? [params] : [])
];

export type InspectionCenterQueryResult = NonNullable<
  Awaited<ReturnType<typeof inspectionCenter>>
>;
export type InspectionCenterQueryError = ErrorType<unknown>;

export const useInspectionCenter = <
  TData = Awaited<ReturnType<typeof inspectionCenter>>,
  TError = ErrorType<unknown>
>(
  params: InspectionCenterParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inspectionCenter>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInspectionCenterQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inspectionCenter>>
  > = ({ signal }) => inspectionCenter(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof inspectionCenter>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const evaluationV1InspectionCreate = (
  createUpdateInspectionInputBody: CreateUpdateInspectionInputBody
) => {
  return httpAPIGW<EvaluationV1InspectionCreate200>({
    url: restEndpoints.CREATE_INSPECTION,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createUpdateInspectionInputBody
  });
};

export type EvaluationV1InspectionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1InspectionCreate>>
>;
export type EvaluationV1InspectionCreateMutationBody =
  CreateUpdateInspectionInputBody;
export type EvaluationV1InspectionCreateMutationError = ErrorType<unknown>;

export const useEvaluationV1InspectionCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof evaluationV1InspectionCreate>>,
    TError,
    { data: CreateUpdateInspectionInputBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof evaluationV1InspectionCreate>>,
    { data: CreateUpdateInspectionInputBody }
  > = (props) => {
    const { data } = props ?? {};

    return evaluationV1InspectionCreate(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof evaluationV1InspectionCreate>>,
    TError,
    { data: CreateUpdateInspectionInputBody },
    TContext
  >(mutationFn, mutationOptions);
};

// Inspection QC
export interface InspectionSchemaParams {
  inspection_token: string;
}

export interface SectionRow {
  text: string;
  type: string;
  icon?: string;
  action?: {
    slug: string;
    type: string;
    value: string;
  };
  has_divider: boolean;
}

export interface InspectionSchema {
  history: string[];
  schema: SectionRow[];
}

export const inspectionSchema = (
  params: InspectionSchemaParams,
  signal?: AbortSignal | undefined
) => {
  return httpAPIGW<InspectionSchema>({
    url: endPointUrlNormalizer(restEndpoints.BACKOFFICE_INSPECTION_SCHEMA, {
      inspectionToken: params.inspection_token
    }),
    method: "get",
    signal
  });
};

export const getInspectionSchemaKey = (params: InspectionSchemaParams) => [
  `/evaluation/v1/backoffice/inspection/`,
  ...(params ? [params] : [])
];

export type InspectionSchemaResult = NonNullable<
  Awaited<ReturnType<typeof inspectionSchema>>
>;
export type InspectionSchemaError = ErrorType<unknown>;

export const useInspectionSchema = <
  TData = Awaited<ReturnType<typeof inspectionSchema>>,
  TError = ErrorType<unknown>
>(
  params: InspectionSchemaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inspectionSchema>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInspectionSchemaKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inspectionSchema>>
  > = ({ signal }) => inspectionSchema(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof inspectionSchema>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export interface InspectionSectionParams {
  inspection_token: string;
  section: string;
}

export interface InspectionSection {
  data: any;
  json_schema: any;
  ui_schema: any;
}

export const inspectionSection = (
  params: InspectionSectionParams,
  signal?: AbortSignal | undefined
) => {
  return httpAPIGW<InspectionSection>({
    url: endPointUrlNormalizer(restEndpoints.BACKOFFICE_INSPECTION_SECTION, {
      inspectionToken: params.inspection_token,
      section: params.section
    }),
    method: "get",
    signal
  });
};

export const getInspectionSectionKey = (params: InspectionSectionParams) => [
  `/evaluation/v1/backoffice/inspection/`,
  ...(params ? [params] : [])
];

export type InspectionSectionResult = NonNullable<
  Awaited<ReturnType<typeof inspectionSection>>
>;
export type InspectionSectionError = ErrorType<unknown>;

export const useInspectionSection = <
  TData = Awaited<ReturnType<typeof inspectionSection>>,
  TError = ErrorType<unknown>
>(
  params: InspectionSectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof inspectionSection>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInspectionSectionKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof inspectionSection>>
  > = ({ signal }) => inspectionSection(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof inspectionSection>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

// POST Update QC

export interface EvaluationV1QCUpdate200 {
  section: string;
  data: any;
}

export interface UpdateQCBody {
  section: string;
  data: any;
}

export const evaluationV1QCUpdate = (
  inspectionID: string,
  UpdateQCBody: UpdateQCBody
) => {
  return httpAPIGW<EvaluationV1QCUpdate200>({
    url: endPointUrlNormalizer(restEndpoints.UPDATE_INSPECTION, {
      inspectionId: inspectionID
    }),
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: UpdateQCBody
  });
};

export type EvaluationV1QCUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1QCUpdate>>
>;
export type EvaluationV1QCUpdateMutationBody = UpdateQCBody;
export type EvaluationV1QCUpdateMutationError = ErrorType<unknown>;

export const useEvaluationV1QCUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof evaluationV1QCUpdate>>,
    TError,
    { inspectionID: string; data: UpdateQCBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof evaluationV1QCUpdate>>,
    { inspectionID: string; data: UpdateQCBody }
  > = (props) => {
    const { inspectionID, data } = props ?? {};

    return evaluationV1QCUpdate(inspectionID, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof evaluationV1QCUpdate>>,
    TError,
    { inspectionID: string; data: UpdateQCBody },
    TContext
  >(mutationFn, mutationOptions);
};

export interface EvaluationV1InspectionDetailParams {
  inspection_id: number;
}

export interface EvaluationV1InspectionDetail200 {
  data: {
    id: number;
    customer_name: string;
    phone_number: string;
    visit_city: number;
    visit_place: string;
    package: number;
    vehicle_type: VehicleType;
    brand_model: string;
    service_type: string;
    time_slot_expert_name: string;
    time_slot_expert_phone_number: string;
    time_slot_mechanic_name: string;
    time_slot_mechanic_phone_number: string;
    inspection_type: string;
    city_name: string;
    shift: {
      pk: number;
      start: string;
      end: string;
      date: string;
    };
    visit_time: string;
    is_canceled: boolean;
    state: string;
    car_inspection_token: string;
    created: string;
    offer_sent_at: string;
    used_discount_code: string;
    operation_note: string;
    cancel_discount_code?: string;
    payments:
      | [
          {
            id: number;
            state: string;
            phone_number: string;
            amount: number;
            payment_gateway: number;
            service_name: string;
            created_at: string;
            closed_at: string;
            invoice_token: string;
            manual_refund?: boolean;
          }
        ]
      | [];
    inspection_center: {
      id: number;
      title: string;
      slug: string;
    } | null;
    special_description: string;
    qa_kara_plus_link: string;
    qa_private_report_link: string;
    description: string;
    selected_package: number;
    concierge_sale_ref_id: string | null;
    post_token: string | null;
    time_slot_is_concierge_sale_expert: boolean;
    price: string;
    is_send_to_offer: boolean;
    price_with_price_rates: number;
    has_ticket: boolean;
    available_in_center?: boolean | null;
  };
}

export interface EvaluationV1InspectionDetailUpdate {
  customer_name: string;
  phone_number: string;
  city: number;
  center: string;
  visit_place: string;
  description?: string;
  state: string;
  brand_model: string;
  package: string;
  visit_time: string;
  concierge_sale?: string;
  operation_note?: string;
}

export const evaluationV1InspectionDetail = (
  params?: EvaluationV1InspectionDetailParams,
  signal?: AbortSignal
) => {
  return httpAPIGW<EvaluationV1InspectionDetail200>({
    url: endPointUrlNormalizer(restEndpoints.INSPECTION_DETAIL, {
      inspectionId: `${params?.inspection_id}`
    }),
    method: "get",
    signal
  });
};

export const getEvaluationV1InspectionDetailQueryKey = (
  params?: EvaluationV1InspectionDetailParams
) => [`/evaluation/v1/backoffice/inspection/`, params?.inspection_id || null];

export type EvaluationV1InspectionDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof evaluationV1InspectionDetail>>
>;
export type EvaluationV1InspectionDetailQueryError = ErrorType<unknown>;

export const useEvaluationV1InspectionDetail = <
  TData = Awaited<ReturnType<typeof evaluationV1InspectionDetail>>,
  TError = ErrorType<unknown>
>(
  params?: EvaluationV1InspectionDetailParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof evaluationV1InspectionDetail>>,
    TError,
    TData
  >
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryKey = getEvaluationV1InspectionDetailQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof evaluationV1InspectionDetail>>
  > = ({ signal }) => evaluationV1InspectionDetail(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof evaluationV1InspectionDetail>>,
    TError,
    TData
  >({
    queryFn,
    queryKey,
    ...options
  }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

// #region anonymized image
const anonymizedImage = (inspectionId: string) => {
  return httpAPIGW({
    method: "post",
    url: endPointUrlNormalizer(restEndpoints.ANONYMIZE_IMAGE, { inspectionId })
  });
};

export const useAnonymizedImage = () => {
  return useMutation({
    mutationFn: (inspectionId: string) => {
      return anonymizedImage(inspectionId);
    }
  });
};
// #endregion

export type RefundDiscountCodeParams = {
  id: number;
};

const refundPenaltyDiscountCode = (params: RefundDiscountCodeParams) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(restEndpoints.REFUND_PENALTY, {
      id: `${params.id}`
    })
  });
};

export const useRefundPenaltyDiscountCode = (
  options?: Omit<
    UseMutationOptions<
      unknown,
      AxiosError<unknown>,
      RefundDiscountCodeParams,
      unknown
    >,
    "mutationFn"
  >
) => {
  return useMutation({ ...options, mutationFn: refundPenaltyDiscountCode });
};

export type InspectionTicketParams = { id: number };

export type InspectionExpertTicketResponse = {
  created_at: string;
  id: number;
  inspection: number;
  modified_at: string;
  subject: string;
  description: string;
  has_resolved: boolean;
  expert: {
    report_name: string;
    report_phone_number: string;
  };
};

const getInspectionTicket = (params: InspectionTicketParams) => {
  return httpAPIGW<InspectionExpertTicketResponse[]>({
    method: "GET",
    url: endPointUrlNormalizer(restEndpoints.INSPECTION_TICKET, {
      id: `${params.id}`
    })
  }).then((res) => res.data);
};

export const useGetInspectionTicket = (
  params: InspectionTicketParams,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof getInspectionTicket>>,
      AxiosError<unknown>,
      Awaited<ReturnType<typeof getInspectionTicket>>,
      string[]
    >,
    "queryFn" | "queryKey"
  >
) => {
  return useQuery({
    ...options,
    queryFn: () => getInspectionTicket(params),
    queryKey: [`inspection-order-ticket-${params.id}`]
  });
};

export type UpdateInspectionTicketParams =
  Partial<InspectionExpertTicketResponse> & { id: number };

const updateInspectionTicket = (params: UpdateInspectionTicketParams) => {
  return httpAPIGW<InspectionExpertTicketResponse[]>({
    method: "PATCH",
    url: endPointUrlNormalizer(restEndpoints.INSPECTION_TICKET, {
      id: `${params.id}`
    }),
    data: params
  });
};

export const useUpdateInspectionTicket = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateInspectionTicket>>,
      AxiosError<unknown>,
      UpdateInspectionTicketParams
    >,
    "mutationFn"
  >
) => {
  return useMutation({
    ...options,
    mutationFn: updateInspectionTicket
  });
};

export type InspectionTicketListParams = {
  inspection: number;
  has_resolved?: string;
};
export type InspectionTicketListResponse = {
  count: number;
  results: InspectionExpertTicketResponse[];
};

const getInspectionTicketList = (params: InspectionTicketListParams) => {
  return httpAPIGW<InspectionTicketListResponse>({
    method: "GET",
    url: restEndpoints.TICKET_LIST,
    params
  }).then((res) => res.data);
};

export const getInspectionTicketListKey = (
  params: InspectionTicketListParams
) => [`inspection-ticket-list-${params.inspection}-${params.has_resolved}`];

export const useGetInspectionTicketList = (
  params: InspectionTicketListParams,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof getInspectionTicketList>>,
      AxiosError<unknown>,
      Awaited<ReturnType<typeof getInspectionTicketList>>,
      string[]
    >,
    "queryFn" | "queryKey"
  >
) => {
  return useQuery({
    ...options,
    queryFn: () => getInspectionTicketList(params),
    queryKey: getInspectionTicketListKey(params)
  });
};

// Get Report Differences

export interface ReportDifferencesParams {
  car_inspection_tokens: string;
}

export type IconType = {
  color: string;
  url: string;
  percentage?: number;
  number?: number;
  size?: number;
};
export type DetailRowType = {
  widget_type: "DETAIL_ROW";
  text_color: string;
  text: string;
  icon: IconType;
  score?: number;
};

export type InformationRowType = {
  color: string;
  text: string;
  value: string | number;
};

export type ExpandableRowItemType = {
  widget_type: "TITLE_ROW" | "EXPANDABLE_SUB_ROW";
  title: string;
  icon: IconType;
  text: string;
  text_color: string;
  inspection_detailed_items: DetailRowType[];
};

export type ExpandableRowType = {
  widget_type: "EXPANDABLE_ROW" | "INFORMATION_ROW";
  icon: IconType;
  text: string;
  items: ExpandableRowItemType[] | InformationRowType[];
};
export interface ReportDifferences200 {
  [key: number]: ExpandableRowType;
}

export const reportDifferences = (
  params?: ReportDifferencesParams,
  signal?: AbortSignal
) => {
  return httpAPIGW<ReportDifferences200>({
    url: restEndpoints.REPORT_DIFFERENCES,
    method: "get",
    params,
    signal
  });
};

export const getReportDifferencesQueryKey = (
  params?: ReportDifferencesParams
) => [
  `/evaluation/v1/inspection/report-differences/`,
  ...(params ? [params] : [])
];

export type ReportDifferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof reportDifferences>>
>;
export type ReportDifferencesQueryError = ErrorType<unknown>;

export const useReportDifferences = <
  TData = Awaited<ReturnType<typeof reportDifferences>>,
  TError = ErrorType<unknown>
>(
  params?: ReportDifferencesParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof reportDifferences>>,
    TError,
    TData
  >
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryKey = getReportDifferencesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof reportDifferences>>
  > = ({ signal }) => reportDifferences(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof reportDifferences>>,
    TError,
    TData
  >({
    queryFn,
    queryKey,
    ...options
  }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
