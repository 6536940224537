import { Rating, Stack, Typography } from "@mui/material";
import React from "react";

const CommentCard = ({
  content,
  score
}: {
  content: string;
  score: number;
}) => {
  return (
    <Stack
      justifyContent={"space-between"}
      bgcolor={"white"}
      p={2}
      border={"1px solid #EBEBEB"}
      borderRadius={"8px"}
      height={"100%"}
      gap={1}
    >
      <Rating
        name="size-small"
        value={score / 2}
        size="small"
        readOnly
        precision={0.5}
      />
      <Typography fontSize={14} fontWeight={400}>
        {content}
      </Typography>
    </Stack>
  );
};

export default CommentCard;
