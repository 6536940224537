import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetAdPerformance,
  useGetAdViews
} from "@/services/api/leasing/adPerformance";
import { useGetSaleDetailV2 } from "@/services/api/sales";
import createStyle from "@/utils/createStyle";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { DataLineChart } from "./DateLineChart";
import { useGetCarPostFeedback } from "@/services/api/concierge-sale/sales-expert-report";
import CarFeedbackCard from "./components/CarFeedbackCard";
import { PhoneEnabledOutlined } from "@mui/icons-material";
import { siteUrl } from "@/utils/env";

const styles = createStyle({
  main: { padding: "0 1.5rem", width: "100%" },
  titleRow: {
    flexDirection: "row",
    borderBottom: "1px solid #EBEBEB",
    padding: "1rem 0"
  },
  title: { fontSize: "1rem", fontWeight: "600" },
  h2: { fontSize: "0.875rem" },
  h3: { fontSize: "13px", color: "#6E6E6E" },
  centerH3: { fontSize: "13px", color: "#6E6E6E", margin: "auto" },
  loading: { margin: "auto" },
  chartTitle: { fontSize: "0.875rem", fontWeight: "600", margin: "1rem 0" },
  bottomBar: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid #EBEBEB",
    padding: "1.5rem",
    background: "white"
  },
  dataValueRow: {
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  divider: {
    borderRight: "1px solid #EBEBEB",
    margin: "0 1rem",
    minHeight: "1rem"
  }
});

const KarnamehPostStatistics = () => {
  const params = useParams();
  const token = params.id ? params.id : "";
  const { setHeaderTitle } = useRootLayout();
  const { data } = useGetSaleDetailV2(Number(token));
  const { data: postViews } = useGetAdViews(
    {
      token: data?.car_post_token ?? ""
    },
    {
      enabled: Boolean(data?.car_post_token),
      onError: () => {
        toast.error("مشکل در دریافت اطلاعات آگهی");
      }
    }
  );
  const { data: postInteractions } = useGetAdPerformance(
    data?.car_post_token ?? "",
    { enabled: Boolean(data?.car_post_token) }
  );

  const { data: carPostFeedback } = useGetCarPostFeedback(token);

  useEffect(() => {
    setHeaderTitle("عملکرد آگهی کارنامه");
  }, [setHeaderTitle]);

  return (
    <Stack width={"100%"} gap={2} overflow={"auto"}>
      <Stack sx={styles.main}>
        <Stack sx={styles.titleRow}>
          <Typography sx={styles.title}>عملکرد آگهی کارنامه</Typography>
        </Stack>
        <Stack sx={styles.titleRow}>
          <Stack sx={styles.dataValueRow}>
            <Typography sx={styles.h3}>تعداد بازدید</Typography>
            <Typography sx={styles.h2}>{postViews?.total_visits}</Typography>
          </Stack>
          <Stack sx={styles.divider} />
          <Stack sx={styles.dataValueRow}>
            <Typography sx={styles.h3}>دریافت شماره</Typography>
            <Typography sx={styles.h2}>
              {postInteractions?.callers_count}
            </Typography>
          </Stack>
        </Stack>
        <Typography sx={styles.chartTitle} fontWeight="600">
          نمودار بازدید روزانه
        </Typography>
        <Stack overflow={"hidden"}>
          <DataLineChart
            data={postViews?.visits
              .slice(Math.min(7, postViews?.visits.length) * -1) // reduce data size to last 7 item if data length is more than 7 item
              .map((item) => ({
                x: new Date(item.date).toLocaleDateString("fa-IR", {
                  month: "2-digit",
                  day: "2-digit"
                }),
                y: item.visits
              }))}
          />
        </Stack>
      </Stack>
      <Stack
        width={"100%"}
        height={"8px"}
        borderTop={"1px solid #EBEBEB"}
        borderBottom={"1px solid #EBEBEB"}
        bgcolor={"#FAFAFA"}
      />
      <Stack sx={styles.main}>
        <Typography fontSize={"16px"} fontWeight={600} pb={2} pt={1}>
          فیدبک‌ها
        </Typography>
        <Divider />
        <Stack py={2} gap={2}>
          <CarFeedbackCard
            title="کارشناس پاسخگو نیست"
            row={carPostFeedback?.not_answered?.detail.map((item) => ({
              right: (
                <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                  <PhoneEnabledOutlined color="secondary" fontSize="small" />
                  <Typography
                    color="text.secondary"
                    fontSize={12}
                    fontWeight={400}
                  >
                    <a
                      href={`tel:${item.phone_number}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {item.phone_number}
                    </a>
                  </Typography>
                </Stack>
              ),
              left: (
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color="text.secondary"
                >
                  {new Date(item.date).toLocaleDateString("fa-IR", {
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                </Typography>
              )
            }))}
          />

          <CarFeedbackCard
            title="اطلاعات خودرو اشتباه است"
            row={
              carPostFeedback?.wrong_info?.detail?.length
                ? [
                    {
                      right: (
                        <Stack
                          flexDirection={"row"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="text.secondary"
                          >
                            {`${carPostFeedback?.wrong_info?.count} نفر, آخرین تاریخ ثبت:`}
                          </Typography>
                        </Stack>
                      ),
                      left: (
                        <>
                          {carPostFeedback?.wrong_info?.detail && (
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color="text.secondary"
                            >
                              {carPostFeedback?.wrong_info?.detail.length > 0
                                ? new Date(
                                    Math.max(
                                      ...(
                                        carPostFeedback?.wrong_info.detail ?? [
                                          { date: new Date() }
                                        ]
                                      ).map((item) =>
                                        new Date(item.date).getTime()
                                      )
                                    )
                                  ).toLocaleDateString("fa-IR", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                  })
                                : "تاریخی ثبت نشده"}
                            </Typography>
                          )}
                        </>
                      )
                    }
                  ]
                : []
            }
          />
          <CarFeedbackCard
            title="خودرو ناموجود است"
            row={
              carPostFeedback?.not_available?.detail?.length
                ? [
                    {
                      right: (
                        <Stack
                          flexDirection={"row"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="text.secondary"
                          >
                            {`${carPostFeedback?.not_available?.count} نفر, آخرین تاریخ ثبت:`}
                          </Typography>
                        </Stack>
                      ),
                      left: (
                        <>
                          {carPostFeedback?.not_available?.detail && (
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color="text.secondary"
                            >
                              {carPostFeedback?.not_available?.detail.length > 0
                                ? new Date(
                                    Math.max(
                                      ...carPostFeedback.not_available.detail.map(
                                        (item) => new Date(item.date).getTime()
                                      )
                                    )
                                  ).toLocaleDateString("fa-IR", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                  })
                                : "تاریخی ثبت نشده"}
                            </Typography>
                          )}
                        </>
                      )
                    }
                  ]
                : []
            }
          />
        </Stack>
      </Stack>
      {data?.car_post_token && (
        <Stack sx={styles.bottomBar}>
          <Button
            onClick={() => {
              if (typeof data?.car_post_token == "string") {
                window.open(
                  `${siteUrl}/user-profile/car-post/details/${data?.car_post_token}`,
                  "_parent"
                );
              }
            }}
          >
            مدیریت آگهی
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default KarnamehPostStatistics;
