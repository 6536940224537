import { httpAPIGW } from "@/services/http-clients";
import { clutchEndPoints } from "@/services/http-clients/configs";
import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";

export type UploadImageParams = {
  file: File;
  bucket_name: "dev-test" | "concierge-sale-affidavit";
  anonymous?: boolean;
};

export type UploadImageResponse = {
  image_private_url: string;
  image_name: string;
};

const UploadImage = (data: UploadImageParams) => {
  const formData = new FormData();
  formData.append("uploading_file", data.file);
  formData.append("bucket_name", data.bucket_name);
  formData.append("anonymous", `${data.anonymous ?? false}`);

  return httpAPIGW<UploadImageResponse>({
    method: "POST",
    url: clutchEndPoints.UPLOAD_IMAGE,
    data: formData
  }).then((res) => res.data);
};

export const useUploadImage = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof UploadImage>>,
    AxiosError<unknown>,
    UploadImageParams
  >
) => {
  return useMutation({
    ...options,
    mutationFn: (data: UploadImageParams) => {
      return UploadImage(data);
    }
  });
};


export const getClutchPlace = (params: GetClutchPlaceParams) => {
  return httpAPIGW<GetClutchPlaceResponse>({
    url: clutchEndPoints.GET_PLACE,
    params,
  }).then((res) => res.data);
};

export const getClutchPlaceKey = (params: GetClutchPlaceParams) => [
  'getClutchPlace',
  params.level,
  params.old_id,
  params.parent,
  params.search,
  params.slug,
];

export const useGetClutchPlace = (
  params: GetClutchPlaceParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getClutchPlace>>>,
) => {
  return useQuery({
    queryFn: () => {
      return getClutchPlace(params);
    },
    queryKey: getClutchPlaceKey(params),
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};