import { useRootLayout } from "@/hooks/useRootLayout";
import { useGetCancelReason, usePostCancelReason } from "@/services/api/sales";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { useNavigate, useParams } from "react-router";

const CancelRequest: FC = () => {
  const navigate = useNavigate();
  const [cancelReason, setCancelReason] = useState<{ [key: string]: string }>(
    {}
  );
  const [formData, setFormData] = useState({
    content: "",
    cancel_reason: ""
  });
  const { data } = useGetCancelReason();
  const { setHeaderTitle } = useRootLayout();
  const { id } = useParams();
  const { mutateAsync, isLoading } = usePostCancelReason(String(id));

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("علت لغو");
    }
  }, []);

  useEffect(() => {
    if (data) {
      setCancelReason(data);
    }
  }, [data]);

  const reasons = Object.entries(cancelReason).map(([key, value]) => {
    return {
      key,
      value
    };
  });

  const changeHandler = (event: {
    target: { name: string; value: string };
  }) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitHandler = () => {
    mutateAsync(formData).then(() => {
      navigate("/concierge-sale/requests/");
    });
  };

  return (
    <Stack sx={{ width: "100%", px: "24px", py: "20px" }}>
      <FormControl sx={{ mb: "20px" }} fullWidth size="small">
        <InputLabel id="error-simple-select">دلیل لغو</InputLabel>
        <Select
          labelId="error-simple-select"
          id="error-simple-select"
          value={formData.cancel_reason}
          label="دلیل لغو"
          name="cancel_reason"
          onChange={changeHandler}
        >
          {reasons.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={{ mt: 3 }}
          id="cancel-desc"
          label="توضیحات"
          multiline
          rows={4}
          value={formData.content}
          name="content"
          onChange={changeHandler}
        />
      </FormControl>{" "}
      <Button
        disabled={Boolean(!formData.cancel_reason) || isLoading}
        onClick={submitHandler}
        sx={styles.cancelBtn}
      >
        {!isLoading && "لغو سفارش"}
        {isLoading && (
          <CircularProgress size={20} sx={{ color: "#00000050" }} />
        )}
      </Button>
    </Stack>
  );
};

export default CancelRequest;
