import { httpAPIGW } from "@/services/http-clients";
import { dealersEndPoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

//#region Get sales expert report
export type GetSalesExpertReport = {
  offerLevel: 1 | 2 | 3 | 4 | 5;
  salesExpertId?: string | null;
};
export type GetSalesExpertReportResponse = {
  total_deal_amount: any;
  expert_commission_amount: any;
  deal_count?: number;
  offer_approve_called?: number;
  offer_approve_accepted?: number;
};

const getSalesExpertReport = (params: GetSalesExpertReport) => {
  const queryParams: any = { offer_level: params.offerLevel };
  if (params.salesExpertId) {
    queryParams.sales_expert_id = params.salesExpertId;
  }

  return httpAPIGW<GetSalesExpertReportResponse>({
    method: "GET",
    url: dealersEndPoints.SALES_EXPERT_DASHBOARD,
    params: queryParams
  }).then((res) => res.data);
};

export const getSalesExpertReportKey = (params: GetSalesExpertReport) => [
  "getSalesExpertReportkey",
  params
];

export const useGetSalesExpertReport = (
  params: GetSalesExpertReport,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getSalesExpertReport>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getSalesExpertReport>>,
    ReturnType<typeof getSalesExpertReportKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getSalesExpertReportKey(params),
    queryFn: () => getSalesExpertReport(params)
  });
};

//#endregion

//#region Get sales expert daily score
export type GetSalesDailyReportResponse = {
  id: number;
  score: number;
  level: number;
  hour: number;
  created_at: string;
};

const getSalesExpertDailyScore = (params: GetSalesExpertReport) => {
  const queryParams: any = { offer_level: params.offerLevel };
  if (params.salesExpertId) {
    queryParams.sales_expert_id = params.salesExpertId;
  }
  return httpAPIGW<GetSalesDailyReportResponse[]>({
    method: "GET",
    url: dealersEndPoints.SALES_EXPERT_DAILY_SCORE_CHART,
    params: queryParams
  }).then((res) => res.data);
};

export const getSalesExpertDailyScoreKey = (params: GetSalesExpertReport) => [
  "getSalesExpertDailyScore",
  params
];

export const useGetSalesExpertDailyScore = (
  params: GetSalesExpertReport,

  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getSalesExpertDailyScore>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getSalesExpertDailyScore>>,
    ReturnType<typeof getSalesExpertDailyScoreKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getSalesExpertDailyScoreKey(params),
    queryFn: () => getSalesExpertDailyScore(params)
  });
};

//#endregion

//#region Get sales expert monthly score

const getSalesExpertMonthlyScore = (params?: string | null) => {
  const queryParams: { sales_expert_id?: string } = {};
  if (params) {
    queryParams.sales_expert_id = params;
  }
  return httpAPIGW<{ [key: string]: GetSalesExpertReportResponse }>({
    method: "GET",
    url: dealersEndPoints.SALES_EXPERT_DASHBOARD_MONTHLY,
    params: queryParams
  }).then((res) => res.data);
};

export const getSalesExpertMonthlyScoreKey = () => [
  "getSalesExpertMonthlyScore"
];

export const useGetSalesExpertMonthlyScore = (
  params?: string | null,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getSalesExpertMonthlyScore>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getSalesExpertMonthlyScore>>,
    ReturnType<typeof getSalesExpertMonthlyScoreKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getSalesExpertMonthlyScoreKey(),
    queryFn: () => getSalesExpertMonthlyScore(params)
  });
};

//#endregion

//#region Get sales expert monthly score

export type GetSupervisorAssistantsScoreResponse = {
  id?: number;
  total_commission_amount?: number;
  name?: string;
  karnameh_last_5_deal_count?: number;
  karnameh_deal_count?: number;
  karnameh_commission_amount?: number;
  personal_last_5_deal_count?: number;
  personal_deal_count?: number;
  personal_commission_amount?: number;
  total_last_5_deal_count?: number;
  total_deal_count?: number;
};

const getSupervisorAssistantsScore = () => {
  return httpAPIGW<GetSupervisorAssistantsScoreResponse[]>({
    method: "GET",
    url: dealersEndPoints.SUPERVISOR_DASHBOARD
  }).then((res) => res.data);
};

export const getSupervisorAssistantsScoreKey = () => [
  "getSupervisorAssistantsScoreKey"
];

export const useGetSupervisorAssistantsScore = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getSupervisorAssistantsScore>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getSupervisorAssistantsScore>>,
    ReturnType<typeof getSalesExpertMonthlyScoreKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getSupervisorAssistantsScoreKey(),
    queryFn: () => getSupervisorAssistantsScore()
  });
};

//#endregion

//#region Get sales expert monthly score

export type GetSupervisorTargetChartResponse = {
  target: number;
  karnameh_total_commission: number;
  personal_total_commission: number;
};

const getSupervisorTargetChart = () => {
  return httpAPIGW<GetSupervisorTargetChartResponse>({
    method: "GET",
    url: dealersEndPoints.SUPERVISOR_TARGET_CHART
  }).then((res) => res.data);
};

export const getSupervisorTargetChartKey = () => [
  "getSupervisorTargetChartKey"
];

export const useGetSupervisorTargetChart = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getSupervisorTargetChart>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getSupervisorTargetChart>>,
    ReturnType<typeof getSupervisorTargetChartKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getSupervisorTargetChartKey(),
    queryFn: () => getSupervisorTargetChart()
  });
};

//#endregion

interface FeedbackDetail {
  id: number;
  phone_number: string;
  description: string;
  date: string;
}

interface FeedbackCategory {
  count: number;
  detail: FeedbackDetail[];
}

interface CarPostFeedback {
  not_answered: FeedbackCategory;
  wrong_info: FeedbackCategory;
  not_available: FeedbackCategory;
}

const getCarPostFeedback = (id: string) => {
  return httpAPIGW<CarPostFeedback>({
    method: "GET",
    url: endPointUrlNormalizer(dealersEndPoints.CAR_POST_FEEDBACK, { id })
  }).then((res) => res.data);
};

export const getCarPostFeedbackKey = (params: string) => [
  "getCarPostFeedbackkey",
  params
];

export const useGetCarPostFeedback = (
  params: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCarPostFeedback>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getCarPostFeedback>>,
    ReturnType<typeof getCarPostFeedbackKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getCarPostFeedbackKey(params),
    queryFn: () => getCarPostFeedback(params)
  });
};
