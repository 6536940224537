import { useRootLayout } from "@/hooks/useRootLayout";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import styles from "./styles";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetConciergeSaleCarStatus,
  usePostConciergeSaleSendToPricing
} from "@/services/api/leasing";
import { makeUrl } from "@/utils/routing";
import { toast } from "react-toastify";
import { useGetSaleDetailV2 } from "@/services/api/sales";
import SeePrevCarStatusBottomSheet from "./SeePrevCarStatusBottomSheet";
import Spacer from "@/components/Spacer";
import axios from "axios";

const partsList = [
  "hood",
  "ceil",
  "trunk",
  "leftBackCol",
  "leftBackFender",
  "leftFrontDoor",
  "leftBackDoor",
  "leftFrontFender",
  "leftCenterCol",
  "leftFrontCol",
  "rightBackCol",
  "rightBackFender",
  "rightFrontDoor",
  "rightBackDoor",
  "rightFrontFender",
  "rightCenterCol",
  "rightFrontCol"
];

const CarStatus: FC = () => {
  const { setHeaderTitle } = useRootLayout();

  const [prevStatusOpen, setPrevStatusOpen] = useState(false);

  const [wantPricing, setWantPricing] = useState(false);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: saleOrderData } = useGetSaleDetailV2(Number(id), {
    enabled: Boolean(id)
  });

  const { mutateAsync: sendToPricing, isLoading: isSendToPricingLoading } =
    usePostConciergeSaleSendToPricing();

  const { data: carStatus } = useGetConciergeSaleCarStatus({
    conciergeSaleId: id as string
  });

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("وضعیت خودرو");
    }
  }, [setHeaderTitle]);

  const isCarStatusSubmitted = useMemo(() => {
    let isSubmitted = false;
    if (
      carStatus?.health_flags?.healthy_body ||
      carStatus?.health_flags?.room_changed
    ) {
      isSubmitted = true;
    }
    partsList.forEach((partKey) => {
      if (
        carStatus?.health_detail &&
        carStatus?.health_detail?.[
          partKey as keyof GetConciergeSaleCarStatusResponse["health_detail"]
        ] !== "intact"
      ) {
        isSubmitted = true;
      }
    });

    return isSubmitted;
  }, [
    carStatus?.health_detail,
    carStatus?.health_flags?.healthy_body,
    carStatus?.health_flags?.room_changed
  ]);

  const isMotorAndChassisSubmitted = useMemo(() => {
    if (
      carStatus?.health_detail?.chassis &&
      carStatus?.health_detail?.splash_shield &&
      carStatus?.health_detail?.motor
    ) {
      return true;
    }
    return false;
  }, [
    carStatus?.health_detail?.chassis,
    carStatus?.health_detail?.motor,
    carStatus?.health_detail?.splash_shield
  ]);

  return (
    <Stack
      sx={{
        width: "100%",
        px: "24px",
        py: "20px",
        height: "100vh",
        pb: "9rem",
        overflowY: "scroll"
      }}
    >
      <Typography fontSize="0.875rem" mb="2rem">
        جهت درخواست قیمت‌گذاری لطفا اطلاعات زیر را تکمیل نمایید:
      </Typography>

      <Link
        to={makeUrl(`/concierge-sale/requests/${id}/car-status/changed-parts`)}
      >
        <Stack sx={styles.menuItem}>
          <Typography fontSize="0.75rem" color={(th) => th.palette.grey[600]}>
            وضعیت بدنه
          </Typography>
          <Typography
            color={(th) =>
              isCarStatusSubmitted
                ? th.palette.primary.main
                : th.palette.common.black
            }
            ml="auto"
            fontSize="0.75rem"
          >
            {isCarStatusSubmitted ? "کامل شده" : "مشخص نشده"}
          </Typography>
          <ChevronLeftRoundedIcon sx={styles.arrowIcon} />
        </Stack>
      </Link>

      <Link
        to={makeUrl(
          `/concierge-sale/requests/${id}/car-status/motor-and-chassis`
        )}
      >
        <Stack sx={styles.menuItem}>
          <Typography fontSize="0.75rem" color={(th) => th.palette.grey[600]}>
            موتور و شاسی
          </Typography>
          <Typography
            color={(th) =>
              isMotorAndChassisSubmitted
                ? th.palette.primary.main
                : th.palette.common.black
            }
            ml="auto"
            fontSize="0.75rem"
          >
            {isMotorAndChassisSubmitted ? "کامل شده" : "مشخص نشده"}
          </Typography>
          <ChevronLeftRoundedIcon sx={styles.arrowIcon} />
        </Stack>
      </Link>

      <Spacer />

      <FormControlLabel
        value={wantPricing}
        onChange={(_, newChecked) => setWantPricing(newChecked)}
        control={<Checkbox size="small" />}
        label={
          <Typography fontSize="0.75rem">درخواست قیمت‌گذاری دارم</Typography>
        }
      />

      <Stack sx={styles.buttonContainer}>
        {typeof saleOrderData?.car_color === "string" &&
          typeof saleOrderData.car_status?.selected_part === "string" && (
            <Button
              onClick={() => setPrevStatusOpen(true)}
              color="secondary"
              variant="outlined"
              sx={{ mb: "0.5rem", width: "100%" }}
            >
              مشاهده وضعیت بدنه‌ی سابق
            </Button>
          )}
        <Button
          disabled={
            !isCarStatusSubmitted ||
            !isMotorAndChassisSubmitted ||
            isSendToPricingLoading
          }
          onClick={() => {
            if (wantPricing) {
              sendToPricing({ conciergeSaleId: id as string })
                .then(() => {
                  navigate(-1);
                  toast.success(
                    "وضعیت خودرو شما با موفقیت ثبت شد و جهت قیمت گذاری ارسال شد."
                  );
                })
                .catch((err) => {
                  if (axios.isAxiosError(err) && err.response?.status === 409) {
                    toast.error(
                      "درخواست قیمت گذاری شده است. برای آپدیت قیمت به گروه مربوطه در کلیک پیام دهید."
                    );
                  } else {
                    toast.error("درخواست شما با خطا مواجه شد.");
                  }
                });
            } else {
              navigate(-1);
            }
          }}
          fullWidth
        >
          ثبت وضعیت خودرو
        </Button>
      </Stack>

      <SeePrevCarStatusBottomSheet
        open={prevStatusOpen}
        onClose={() => setPrevStatusOpen(false)}
      />
    </Stack>
  );
};

export default CarStatus;
