import TimelineIcon from "@mui/icons-material/Timeline";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface ConcierSaleMenuItems {
  title: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, "svg">> & {
        muiName: string;
      })
    | null;
  path: string;
  is_external_link: boolean;
}

export const conciergeSaleMenu: ConcierSaleMenuItems[] = [
  {
    title: "گزارش عملکرد",
    icon: TimelineIcon,
    path: "/concierge-sale/supervisor/supervisor-performance-report",
    is_external_link: false
  }
];
