import ConciergeSaleLayout from "@/components/layouts/ConciergeSaleLayout/ConciergeSaleLayout";
import { Route } from "react-router";

import RequestDetail from "@/pages/ConciergeSale/requests/detail";
import CancelRequest from "@/pages/ConciergeSale/requests/tabs/details/info/cancelRequest";
import CarStatus from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus";
import SubmitDeals from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals";
import Adevertised from "@/pages/ConciergeSale/requests/tabs/requests/advertised";
import AllRequest from "@/pages/ConciergeSale/requests/tabs/requests/allRequests";
import InitialContact from "@/pages/ConciergeSale/requests/tabs/requests/initial";
import Negotiation from "@/pages/ConciergeSale/requests/tabs/requests/negotiation";
import Pricing from "@/pages/ConciergeSale/requests/tabs/requests/pricing";

import InspectionRequest from "@/pages/ConciergeSale/requests/InspectionRequest";
import GetAddress from "@/pages/ConciergeSale/requests/InspectionRequest/GetAddress";
import SelectTimeslot from "@/pages/ConciergeSale/requests/InspectionRequest/SelectTimeslot";

import ShareInspection from "@/pages/ConciergeSale/requests/share";

import ConciergeSaleOffer from "@/pages/concierge-sale-offer";
import ConciergeSaleSubmitOrder from "@/pages/concierge-sale-submit-order";
import ConciergeSaleSupervisor from "@/pages/concierge-sale-supervisor";
import ConciergeSaleIntro from "@/pages/ConciergeSale";
import AssistantPerformanceReport from "@/pages/ConciergeSale/assistant-performance-report";
import DivarLogin from "@/pages/ConciergeSale/divar-login";
import InspectionPayment from "@/pages/ConciergeSale/requests/InspectionRequest/Payment";
import SelectPackage from "@/pages/ConciergeSale/requests/InspectionRequest/SelectPackage";
import RegisterPostAddress from "@/pages/ConciergeSale/requests/posts/registerPostAddress";
import PostStatistics from "@/pages/ConciergeSale/requests/posts/statistics";
import ChangedParts from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus/steps/changedParts";
import MotorAndChassis from "@/pages/ConciergeSale/requests/tabs/details/info/carStatus/steps/motorAndChassis";
import PaymentSendSms from "@/pages/ConciergeSale/requests/tabs/details/info/submitDeals/views/sendSms";
import SupervisorPerformanceReport from "@/pages/ConciergeSale/supervisor-performance-report";
import CircularLoading from "@/pages/inspection-job-report-dynamic/views/categories/CircularLoading";
import { Suspense } from "react";
import CustomerReport from "@/pages/ConciergeSale/customer-report";
import KarnamehPostStatistics from "@/pages/ConciergeSale/requests/posts/karnamehStatistics";

const ConciergeSaleRoutes = () => {
  return (
    <>
      <Route path="concierge-sale/requests" element={<ConciergeSaleLayout />}>
        <Route index element={<InitialContact />} />
        <Route path="negotiation" element={<Negotiation />} />
        <Route path="advertised" element={<Adevertised />} />
        <Route path="all" element={<AllRequest />} />
      </Route>

      <Route path="concierge-sale">
        <Route
          index
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleIntro />
            </Suspense>
          }
        />

        <Route path="submit-order" element={<ConciergeSaleSubmitOrder />} />

        <Route
          path="offers"
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleOffer />
            </Suspense>
          }
        />

        <Route path="supervisor">
          <Route
            index
            element={
              <Suspense fallback={<CircularLoading />}>
                <ConciergeSaleSupervisor />
              </Suspense>
            }
          />

          <Route
            path="supervisor-performance-report"
            element={<SupervisorPerformanceReport />}
          />
        </Route>
        <Route
          path="assistant-performance-report"
          element={<AssistantPerformanceReport />}
        />

        <Route
          path="concierge-sale/index"
          element={
            <Suspense fallback={<CircularLoading />}>
              <ConciergeSaleIntro />
            </Suspense>
          }
        />
        <Route path="customer-report" element={<CustomerReport />} />

        <Route path="requests">
          <Route path=":id">
            <Route index element={<RequestDetail />} />

            <Route path="share" element={<ShareInspection />} />

            <Route path="car-status">
              <Route index element={<CarStatus />} />
              <Route path="changed-parts" element={<ChangedParts />} />
              <Route path="motor-and-chassis" element={<MotorAndChassis />} />
            </Route>

            <Route path="submit-deals">
              <Route index element={<SubmitDeals />} />
              <Route path="send-sms" element={<PaymentSendSms />} />
            </Route>

            <Route path="cancel-request" element={<CancelRequest />} />

            <Route path="inspection-request" element={<InspectionRequest />}>
              <Route path="package" element={<SelectPackage />} />
              <Route path="timeslot" element={<SelectTimeslot />} />
              <Route path="address" element={<GetAddress />} />
              <Route path="payment" element={<InspectionPayment />} />
            </Route>
            <Route
              path="register-post-address"
              element={<RegisterPostAddress />}
            />
            <Route path="post-statistics" element={<PostStatistics />} />
            <Route
              path="karnameh-post-statistics"
              element={<KarnamehPostStatistics />}
            />
          </Route>

          <Route path="pricing" element={<Pricing />} />
        </Route>
        <Route path="divar-login" element={<DivarLogin />} />
      </Route>
    </>
  );
};

export default ConciergeSaleRoutes;
