import createStyle from "@/utils/createStyle";

const styles = createStyle({
  searchContainer: {
    width: '100%',
    px: '1rem',
  },
  search: {
    width: '100%',
    mb: '1rem',
  },
  searchIcon: {
    color: (th) => th.palette.iconDefault.main,
    mr: '0.5rem',
  },
});

export default styles;
