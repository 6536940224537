import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { httpAPIGW } from "@/services/http-clients";
import { endPointUrlNormalizer } from "../http-clients/utils";
import { api2Endpoints, restEndpoints } from "../http-clients/configs";
import { AxiosError } from "axios";

export type FindCarsServiceNames =
  | "concierge_sell"
  | "concierge_buy"
  | "inspection";

export type VehicleType = "light_car" | "motor_cycle";
export interface Car {
  last_level: string;
  last_level_id: number;
  name: string;
  name_en: string;
  name_fa: string;
  vehicle_type: VehicleType;
}

export interface CarConfig {
  min_kilometers?: number;
  max_kilometers?: number;
  min_price?: number;
  max_price?: number;
  min_manufacture_year?: number;
  djalali_date?: number;
}

// #region Request: GET /find_cars/v1/fetch_cars, params: service_name

interface FindCarsV1FetchCarsParams {
  serviceName: FindCarsServiceNames;
}

interface FindCarsV1FetchCarsResponse {
  data: Car[];
}

export const findCarsV1FetchCars = (params: FindCarsV1FetchCarsParams) => {
  return httpAPIGW<FindCarsV1FetchCarsResponse>({
    method: "GET",
    url: restEndpoints.FETCH_CARS,
    params: { service_name: params.serviceName }
  });
};

export const findCarsV1FetchCarsKey = () => {
  return ["findCarsV1FetchCars", findCarsV1FetchCars];
};

export const useFindCarsV1FetchCars = (
  params: FindCarsV1FetchCarsParams,
  options?: Omit<
    UseQueryOptions<
      FindCarsV1FetchCarsResponse,
      unknown,
      FindCarsV1FetchCarsResponse,
      ReturnType<typeof findCarsV1FetchCarsKey>
    >,
    "queryFn" | "queryKey"
  >
) => {
  const queryKey = findCarsV1FetchCarsKey();
  return useQuery({
    ...options,
    queryFn: () => {
      return findCarsV1FetchCars(params).then((res) => res.data);
    },
    queryKey
  });
};

// #endregion

// #region Request: GET /find_cars/v1/search, params: service_name, text

interface FindCarsV1SearchParams {
  serviceName: FindCarsServiceNames;
  text: string;
}

interface FindCarsV1SearchResponse {
  data: Car[];
}

export const findCarsV1Search = (params: FindCarsV1SearchParams) => {
  return httpAPIGW<FindCarsV1SearchResponse>({
    method: "GET",
    url: restEndpoints.SEARCH_CAR,
    params: { text: params.text, service_name: params.serviceName }
  }).then((res) => {
    return res.data;
  });
};

export const useFindCarsV1Search = (
  initialParams: Omit<FindCarsV1SearchParams, "text">,
  options?: UseMutationOptions<
    FindCarsV1SearchResponse,
    unknown,
    FindCarsV1SearchParams,
    unknown
  >
) => {
  const mutationFn: MutationFunction<
    FindCarsV1SearchResponse,
    Omit<FindCarsV1SearchParams, "serviceName">
  > = (params) => {
    return findCarsV1Search({ ...initialParams, ...params });
  };
  return useMutation({
    mutationFn,
    ...options
  });
};

// #endregion

// #region GET /cars/v1/-/get-assistant-buy-configs, params: last_level, last_level_id
interface GetBuyAssisstantCarConfigParams {
  levelId: string;
  lastLevelId: number;
}

export const getBuyAssisstantCarConfig = ({
  levelId,
  lastLevelId
}: GetBuyAssisstantCarConfigParams) => {
  return httpAPIGW<CarConfig>({
    method: "GET",
    url: endPointUrlNormalizer(api2Endpoints.ASSISTANT_CONFIGS, {
      levelId,
      lastLevelId: `${lastLevelId}`
    })
  });
};

export const useGetBuyAssisstantCarConfig = (
  params: GetBuyAssisstantCarConfigParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>,
    string[]
  >
) => {
  const queryKey = [
    "getBuyAssisstantCarConfig",
    `${params.lastLevelId}`,
    `${params.levelId}`
  ];

  return useQuery({
    ...options,
    queryFn: () => getBuyAssisstantCarConfig(params),
    queryKey
  });
};

// #endregion

export interface ExactCarDetails {
  data: {
    last_level: string;
    last_level_id: number;
    name_fa: string;
    name_en: string;
  };
}

interface GetExactSearchParams {
  text: string;
  service_name: "concierge_sell" | "concierge_buy" | "inspection";
}

export const getExactSearch = (params: GetExactSearchParams) => {
  return httpAPIGW<ExactCarDetails | null>({
    url: restEndpoints.SEARCH_EXACT_CAR,
    method: "GET",
    params: params
  })
    .then((data) => {
      if (!data || !data.data || !data.data?.data) {
        return null;
      }

      return {
        last_level: data.data.data.last_level,
        last_level_id: data.data.data.last_level_id,
        name: data.data.data.name_fa,
        name_en: data.data.data.name_en
      } as Car;
    })
    .then((res) => {
      return res;
    });
};

// #region Request: GET /find_cars/v1/search, params: service_name, text

interface FindCarsV1ExactParams {
  serviceName: FindCarsServiceNames;
  text: string;
}

interface FindCarsV1ExactResponse {
  data: Car;
}

export const findCarsV1Exact = (params: FindCarsV1ExactParams) => {
  return httpAPIGW<FindCarsV1ExactResponse>({
    method: "GET",
    url: restEndpoints.SEARCH_EXACT_CAR,
    params: {
      service_name: params.serviceName,
      text: params.text
    }
  }).then((res) => {
    return res.data;
  });
};

export const useFindCarsV1Exact = (
  initialParams: Omit<FindCarsV1ExactParams, "text">,
  options?: UseMutationOptions<
    FindCarsV1ExactResponse,
    unknown,
    FindCarsV1ExactParams,
    unknown
  >
) => {
  const mutationFn: MutationFunction<
    FindCarsV1ExactResponse,
    Omit<FindCarsV1ExactParams, "serviceName">
  > = (params) => {
    return findCarsV1Exact({ ...initialParams, ...params });
  };
  return useMutation({
    mutationFn,
    ...options
  });
};

// #endregion
