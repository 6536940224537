import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BottomSheetContext } from "@/components/BottomSheetModal/configs";
import { FC, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import styles from "./styles";
import useLogic from "./useLogic";

interface PropTypes {
  open: boolean;
  onClose?: () => void;
  fullHeight?: boolean;
  maxWidth?: string;
}

const BottomSheetModal: FC<PropsWithChildren<PropTypes>> = (props) => {
  const {
    open,
    children,
    fullHeight = false,
    maxWidth = "512px",
    onClose
  } = props;

  const { show, onCloseHandler } = useLogic(open, onClose);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const portalElm =
    typeof window !== "undefined" ? document.getElementById("portal") : null;

  if ((!open && !show) || !portalElm) {
    return null;
  }

  return createPortal(
    <BottomSheetContext.Provider
      value={{
        open,
        close: onCloseHandler
      }}
    >
      <Box
        component="div"
        onClick={onCloseHandler}
        sx={{
          ...styles.backdrop,
          pointerEvents: open && show ? "all" : "none",
          opacity: open && show ? 1 : 0
        }}
      />
      <Stack
        sx={styles.container}
        style={{
          borderTopLeftRadius: !isMd ? (fullHeight ? 0 : "1.5rem") : "1.5rem",
          borderTopRightRadius: !isMd ? (fullHeight ? 0 : "1.5rem") : "1.5rem",
          maxWidth: !isMd ? "unset" : maxWidth,
          pointerEvents: open && show ? "all" : "none",
          opacity: open && show ? 1 : 0,
          height: !isMd ? (fullHeight ? "100vh" : "unset") : "unset",
          transform: !isMd
            ? `translateY(${open && show ? 0 : "50%"})`
            : `translateY(-50%) translateX(50%) scale(${
                open && show ? 1 : 0.8
              })`
        }}
      >
        {children}
      </Stack>
    </BottomSheetContext.Provider>,
    portalElm
  );
};

export default BottomSheetModal;
