import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import {
  useGetSaleDetailV2,
  usePostConciergeSaleShare,
  ConciergeSaleShare
} from "@/services/api/sales";
import { useNavigate, useParams } from "react-router";
import { toEngDigits } from "@/utils/toEnglishDigit";

const ShareInspection: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = usePostConciergeSaleShare(Number(id));
  const { data } = useGetSaleDetailV2(Number(id), {
    enabled: typeof id !== "undefined"
  });
  const [formData, setFormData] = useState<ConciergeSaleShare>({
    phone_number: "",
    lead_info_and_price: false,
    car_post_links: false,
    last_inspection: false,
    leasing_landing: false,
    last_inspection_report_link: null
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("به اشتراک‌گذاری سفارش");
    }
  }, [setHeaderTitle]);

  const inspectionsList = data?.inspections.filter((item) => item.report_link);
  const submitHandler = () => {
    mutateAsync({
      ...formData
    }).then(() => setIsModalOpen(true));
  };

  return (
    <>
      <Stack sx={{ width: "100%", minHeight: "100vh", p: 3 }}>
        <Typography fontSize={16} color="#202021" mb={2.5}>
          لطفا شماره موردنظر را وارد نمایید:
        </Typography>
        <TextField
          inputProps={{ inputMode: "numeric", maxLength: 11 }}
          onChange={(event) =>
            setFormData((form) => ({
              ...form,
              phone_number: toEngDigits(event.target.value)
            }))
          }
          size="small"
          label="شماره موبایل"
          sx={{ mb: 3 }}
        />
        <Typography fontSize={16} color="#202021" mb={1.5} lineHeight={1.7}>
          لطفا مواردی که میخواهید به اشتراک بگذارید را انتخاب نمایید:
        </Typography>

        <FormGroup sx={{ color: "#202021" }}>
          <FormControlLabel
            control={<Checkbox />}
            name="lead_info_and_price"
            onChange={(event) =>
              setFormData((form) => ({
                ...form,
                lead_info_and_price: (event.target as HTMLInputElement).checked
              }))
            }
            label={<Typography fontSize={16}>اطلاعات لید</Typography>}
          />
          <FormControlLabel
            control={<Checkbox />}
            disabled={typeof data?.car_post === null}
            name="car_post_links"
            onChange={(event) =>
              setFormData((form) => ({
                ...form,
                car_post_links: (event.target as HTMLInputElement).checked
              }))
            }
            label={
              <Typography
                color={typeof data?.car_post === null ? "#A9A8AA" : "initial"}
                fontSize={16}
              >
                لینک آگهی {typeof data?.car_post === null && "(ثبت نشده)"}
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox />}
            disabled={!inspectionsList?.length}
            name="last_inspection"
            onChange={(event) =>
              setFormData((form) => ({
                ...form,
                last_inspection: (event.target as HTMLInputElement).checked,
                last_inspection_report_link: inspectionsList?.length
                  ? (inspectionsList[0].report_link as string)
                  : null
              }))
            }
            label={
              <Typography
                color={inspectionsList?.length ? "initial" : "#A9A8AA"}
                fontSize={16}
                lineHeight={1.7}
              >
                آخرین گزارش کارشناسی{"  "}
                {inspectionsList?.length
                  ? `(${new Date(
                      inspectionsList[0].created_at as string
                    ).toLocaleDateString("fa", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric"
                    })} - ${new Date(
                      inspectionsList[0].created_at as string
                    ).toLocaleTimeString("fa", {
                      hour: "numeric",
                      minute: "2-digit"
                    })})`
                  : "(ندارد)"}
                {"  "}
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox />}
            name="leasing_landing"
            disabled={!data?.is_leasing_available}
            onChange={(event) =>
              setFormData((form) => ({
                ...form,
                leasing_landing: (event.target as HTMLInputElement).checked
              }))
            }
            label={
              <Typography
                color={data?.is_leasing_available ? "initial" : "#A9A8AA"}
                fontSize={16}
              >
                صفحه لندینگ لیزینگ و شرایط{" "}
                {!data?.is_leasing_available && "(ندارد)"}{" "}
              </Typography>
            }
          />
        </FormGroup>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          p: 3,
          bgcolor: "#fafafa"
        }}
      >
        <Button
          disabled={
            !(
              formData.leasing_landing ||
              formData.lead_info_and_price ||
              formData.last_inspection ||
              formData.car_post_links
            ) ||
            (formData.phone_number as string).length < 11 ||
            isLoading
          }
          size="medium"
          onClick={submitHandler}
        >
          {!isLoading && "تأیید و ارسال پیامک"}
          {isLoading && (
            <CircularProgress size={20} sx={{ color: "#00000050" }} />
          )}
        </Button>
      </Stack>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "background.paper",
            outline: "none",
            borderRadius: "8px",
            px: 4,
            py: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="body1" sx={{ lineHeight: "1.8" }}>
            پیامک با موفقیت ارسال شد.
          </Typography>
          <Button
            sx={{ mt: 1, px: 3, height: "auto" }}
            onClick={() => {
              setIsModalOpen(false);
              navigate(`/concierge-sale/requests/${id}`);
            }}
          >
            تایید
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ShareInspection;
