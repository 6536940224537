import { httpAPIGW } from "@/services/http-clients";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { UseQueryOptions, useMutation, useQuery } from "react-query";

const bulkEndPoints = {
  CREATE_PAYMENTS: `buck/api/payment-service/payments/create-invoice/:service_slug/`,
  GET_PAYMENTS: `buck/api/payment-service/payments/service-item-id/:id/`
};

//#region create invoice

export interface CreateInvoice {
  phone_number?: string | null;
  name?: string | null;
  amount?: number | null;
  customer_switch_user_id?: number | null;
  service_item_id?: number | null;
  callback?: string;
  service_name?: string;
  template?: "concierge-sale-deal-payment";
  params?: {
    customer_name: string;
    concierge_sale_id: number;
    car_brand_model?: string;
    amount?: number | null;
  };
}

const postCreateInvoice = async (id: number, data: CreateInvoice) => {
  return httpAPIGW<CreateInvoice>({
    method: "POST",
    url: endPointUrlNormalizer(bulkEndPoints.CREATE_PAYMENTS, {
      service_slug: "dealers-commission"
    }),
    data
  });
};

const postCreateInvoiceKey = "postCreateInvoice";

export const usePostCreateInvoice = (id: number) => {
  return useMutation({
    mutationKey: [postCreateInvoiceKey],
    mutationFn: (data: CreateInvoice) => {
      return postCreateInvoice(id, data);
    }
  });
};

//#endregion

//#region Get Payments List

export interface GetPaymentListsParams {
  id?: number | string;
  service_slug?: string;
}

export interface GetPaymentListsResponse {
  amount: number;
  id: number;
  invoice_token: string;
  payment_intent: string;
  payment_link: string;
  created_at: string;
  modified_at: string;
  name: string;
  phone_number: string;
  state:
    | "in-progress"
    | "failed"
    | "success"
    | "cancelled-by-user"
    | "reversed";
}
[];

const getPaymentLists = async (params: GetPaymentListsParams) => {
  return httpAPIGW<GetPaymentListsResponse>({
    method: "GET",
    url: endPointUrlNormalizer(bulkEndPoints.GET_PAYMENTS, {
      id: params.id as string
    }),
    paramsSerializer: {
      indexes: null
    },
    params: {
      service_slug: params?.service_slug
    }
  });
};

const getPaymentListsKey = "getPaymentLists";

export const useGetPaymentLists = (
  params: GetPaymentListsParams,
  options?: UseQueryOptions
) => {
  return useQuery({
    queryKey: [getPaymentListsKey, params.id],
    staleTime: 0,
    queryFn: () => {
      return getPaymentLists(params)
        .then((res) => {
          return res.data as GetPaymentListsResponse;
        })
        .catch(() => {
          return [] as GetPaymentListsResponse[];
        });
    },
    ...options
  });
};

//#endregion
