import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Stack, TextField } from "@mui/material";

import {
  ChangeEvent,
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import styles from "./styles";
import {
  CitySelectInternalContext,
  extractPlaceLeafs,
  shapeValue
} from "./configs";
import Layout from "./components/Layout";
import ActiveFilters from "./components/ActiveFilters";
import SearchResults from "./components/SearchResults";
import DefaultMethod from "./components/DefaultMethod";
import Footer from "./components/Footer";
import { useGetClutchPlace } from "@/services/api/clutch";

interface PropTypes extends Omit<ComponentProps<typeof Layout>, "children"> {
  isMultiple?: boolean;
  value: number[] | number | string[] | string | null;
  onMultipleChange?: (places: ClutchPlace[]) => void;
  onChange?: (places: ClutchPlace | null) => void;
  withSlug?: boolean;
}

const CitySelect: FC<PropTypes> = (props) => {
  const {
    open,
    onClose: propsOnClose,
    isMultiple = false,
    value: propValue,
    onMultipleChange: propsOnMultipleChange,
    onChange: propsOnChange,
    withSlug = false
  } = props;

  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [values, setValues] = useState<ClutchPlace[]>([]);

  const [searchingValue, setSearchingValue] = useState("");

  const [isWaitingForSubmit, setIsWaitingForSubmit] = useState(false);

  const { data: placeList, isSuccess: isPlaceListSuccess } = useGetClutchPlace(
    {}
  );

  console.log({ placeList });

  const functionRefs = useRef({
    onClose: propsOnClose,
    onMultipleChange: propsOnMultipleChange,
    onChange: propsOnChange
  });

  useEffect(() => {
    functionRefs.current = {
      onClose: propsOnClose,
      onMultipleChange: propsOnMultipleChange,
      onChange: propsOnChange
    };
  }, [propsOnChange, propsOnClose, propsOnMultipleChange]);

  useEffect(() => {
    if (open && isPlaceListSuccess) {
      setValues(shapeValue(propValue, placeList, withSlug));
    }
  }, [isPlaceListSuccess, open, placeList, propValue, withSlug]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => {
        setShow(open);
      },
      open ? 50 : 300
    );
  }, [open]);

  const onValueChange = useCallback(
    (newValue: ClutchPlace) => {
      if (isPlaceListSuccess) {
        let currentValues = extractPlaceLeafs(placeList, values);
        const newValueItems = extractPlaceLeafs(placeList, [newValue]);
        let shouldSetIsWaitingTrue = false;

        if (isMultiple) {
          newValueItems.forEach((newValueItem) => {
            const newValueItemIndexInCurrentValues = currentValues.findIndex(
              (currentValue) => currentValue.id === newValueItem.id
            );
            if (newValueItemIndexInCurrentValues >= 0) {
              currentValues.splice(newValueItemIndexInCurrentValues, 1);
            } else {
              currentValues.push(newValueItem);
            }
          });
        } else {
          if (
            newValueItems.every((newValueItem) =>
              currentValues.some(
                (currentValueItem) => currentValueItem.id === newValueItem.id
              )
            ) &&
            newValueItems.length === currentValues.length
          ) {
            currentValues = [];
          } else {
            shouldSetIsWaitingTrue = true;
            currentValues = [...newValueItems];
          }
        }

        const newValueLeafs = extractPlaceLeafs(placeList, currentValues);

        const parents: ClutchPlace[] = [];
        const leafs: ClutchPlace[] = [];

        placeList.forEach((place) => {
          if (
            place.has_children &&
            newValueLeafs.some(
              (leaf) => !leaf.has_children && leaf.parent_ids.includes(place.id)
            )
          ) {
            parents.push(place);
          }
        });
        placeList.forEach((place) => {
          if (
            !place.has_children &&
            parents.some((parent) => place.parent_ids.includes(parent.id))
          ) {
            leafs.push(place);
          }
        });

        const result: ClutchPlace[] = [];

        parents.forEach((parentItem) => {
          let isActive = true;
          leafs.forEach((leaf) => {
            const isLeafForThisParent = leaf.parent_ids.includes(parentItem.id);
            if (isLeafForThisParent) {
              if (
                !newValueLeafs.some(
                  (newValueLeaf) => newValueLeaf.id === leaf.id
                )
              ) {
                isActive = false;
              }
            }
          });
          if (isActive) {
            result.push(parentItem);
          }
        });

        newValueLeafs.forEach((newValueLeaf) => {
          const shouldAddToResult = !result
            .filter((resultItem) => resultItem.has_children)
            .some((parentItem) => {
              return newValueLeaf.parent_ids.includes(parentItem.id);
            });
          if (shouldAddToResult) {
            result.push(newValueLeaf);
          }
        });

        if (shouldSetIsWaitingTrue) {
          setIsWaitingForSubmit(true);
        }

        setValues(result);
      }
    },
    [isMultiple, isPlaceListSuccess, placeList, values]
  );

  const onSearchChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchingValue(event.target.value);
    },
    []
  );

  const onSubmitHandler = useCallback(() => {
    if (isMultiple) {
      if (typeof functionRefs.current.onMultipleChange === "function") {
        functionRefs.current.onMultipleChange(values);
      }
    } else {
      if (typeof functionRefs.current.onChange === "function") {
        functionRefs.current.onChange(values?.[0] || null);
      }
    }
    functionRefs.current.onClose();
  }, [isMultiple, values]);

  const onCancelHandler = useCallback(() => {
    functionRefs.current.onClose();
  }, []);

  useEffect(() => {
    if (isWaitingForSubmit) {
      setIsWaitingForSubmit(false);
      onSubmitHandler();
    }
  }, [isWaitingForSubmit, onSubmitHandler]);

  if (!open && !show) {
    return null;
  }

  return (
    <CitySelectInternalContext.Provider
      value={{ isMultiple, values, onValueChange }}
    >
      <Layout open={open} onClose={onCancelHandler}>
        {/* active filters */}
        <ActiveFilters />
        {/* search & results of search */}
        <Stack sx={styles.searchContainer}>
          <TextField
            sx={styles.search}
            value={searchingValue}
            label={isMultiple ? "جستجو" : "جستجوی شهر"}
            size="small"
            InputProps={{
              startAdornment: <SearchRoundedIcon sx={styles.searchIcon} />
            }}
            onChange={onSearchChangeHandler}
            autoComplete="off"
          />
          <SearchResults searchingValue={searchingValue} />
        </Stack>
        {/* items */}
        {searchingValue.length < 2 && <DefaultMethod />}
        <Footer
          onSubmitClick={onSubmitHandler}
          onCancelClick={onCancelHandler}
        />
      </Layout>
    </CitySelectInternalContext.Provider>
  );
};

export default CitySelect;
