import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { httpAPIGW } from "../http-clients";
import { buckEndpoints } from "../http-clients/configs";
import { endPointUrlNormalizer } from "../http-clients/utils";

//#region Post Create Invoice

export interface CreateInvoice {
  first_name?: string;
  last_name?: string;
  phone_number?: string | null;
  name?: string | null;
  amount?: number | null;
  customer_switch_user_id?: number | null;
  service_item_id?: string | null;
  callback?: string;
  service_name?: string;
  template?:
    | "concierge-sale-deal-payment"
    | "leasing-deposit"
    | "leasing-validation-payment"
    | "leasing-cash-out";
  payment_gateway?: string;
  params?: {
    customer_name?: string;
    concierge_sale_id?: number;
    car_brand_model?: string;
    amount?: number | null;
    leasing_uuid?: string;
    link?: string;
  };
  service_slug?: string;
}

const postCreateInvoice = async (service_slug: string, data: CreateInvoice) => {
  return httpAPIGW<CreateInvoice>({
    method: "POST",
    url: endPointUrlNormalizer(buckEndpoints.CREATE_PAYMENTS, {
      service_slug: data.service_slug || service_slug
    }),
    data
  });
};

const postCreateInvoiceKey = "postCreateInvoice";

export const usePostCreateInvoice = (service_slug: string) => {
  return useMutation({
    mutationKey: [postCreateInvoiceKey],
    mutationFn: (data: CreateInvoice) => {
      return postCreateInvoice(service_slug, data);
    }
  });
};

//#endregion

//#region Get All Payments List

export interface GetAllPaymentsListParams {
  service_slug: string[];
}

export interface GetAllPaymentsListResponse {
  id: number;
  invoice_token: string;
  amount: number;
  state:
    | "in-progress"
    | "failed"
    | "success"
    | "cancelled-by-user"
    | "reversed"
    | "in-progress-refund"
    | "failed-refund";
  service_name: string;
  service_slug: string;
  payment_link: string;
  payment_intent: string | null;
  name: string;
  phone_number: string;
  created_at: string;
  modified_at: string;
  closed_at: string | null;
  is_refund: boolean;
}

export const getAllPaymentsList = (
  id: string,
  params: GetAllPaymentsListParams
) => {
  return httpAPIGW<GetAllPaymentsListResponse[]>({
    method: "GET",
    url: endPointUrlNormalizer(buckEndpoints.GET_PAYMENTS_LIST, {
      id
    }),
    params: {
      service_slug: params.service_slug.join(",")
    }
  }).then((res) => res.data);
};

export const getAllPaymentsListKey = (
  id: string,
  params: GetAllPaymentsListParams
) => {
  return ["getAllPaymentsList", id, params.service_slug];
};

export const useGetAllPaymentsList = (
  id: string,
  params: GetAllPaymentsListParams,
  options?: Omit<
    UseQueryOptions<
      GetAllPaymentsListResponse[],
      unknown,
      GetAllPaymentsListResponse[],
      ReturnType<typeof getAllPaymentsListKey>
    >,
    "queryFn" | "queryKey"
  >
) => {
  const queryKey = getAllPaymentsListKey(id, params);
  return useQuery({
    ...options,
    queryKey,
    queryFn: () => getAllPaymentsList(id, params)
  });
};

//#endregion
