import { Messaging } from "firebase/messaging";
import {
  getToken,
  getNotificationState,
  messaging,
  requestPermission
} from "@/firebaseConfig";
import useCookie from "@/hooks/useCookie";
import { FC, ReactNode, useEffect, useState } from "react";
import { usePushToken } from "@/services/api/notification";
import { isTwaApp } from "@/utils/twaUtils";
import { useNotificationState } from "@/store/slices/notification";
import BottomSheetHeader from "@/components/BottomSheetHeader";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material";
import { setValueToLocalStorage } from "@/utils/localStorageManager";
import { NOTIFICATION_PERMISSION } from "@/utils/consts";
import BottomSheetModal from "@/components/BottomSheetModal";
import { useLocation } from "react-router";
import {
  getDeviceIdFromCookie,
  getFcmTokenFromCookie
} from "@/utils/cookieManager";
import { getIsLoggedIn } from "@/utils/auth";

interface Props {
  children: ReactNode;
}

const vapidKey =
  "BA_oXdLVTT27I70gZTdI0lue7NJ2xSqG6mgtBgfZGoYf1pddqdiwfzQKS0lYr-2rVQgToOTBtAFgPf8n0PKK0HI";

const AppPushNotif: FC<Props> = ({ children }) => {
  const { mutateAsync } = usePushToken();
  const deviceId = getDeviceIdFromCookie();
  const fcmToken = getFcmTokenFromCookie();
  const loginToken = useCookie("kpapp-access-token");

  const { permissionState, setNotificationState } = useNotificationState();
  const { pathname } = useLocation();

  const [showNotifModal, setShowNotifModal] = useState<boolean>(
    permissionState === "default" &&
      pathname !== "/profile" &&
      pathname !== "/login"
  );

  useEffect(() => {
    if (isTwaApp() || !getIsLoggedIn()) return;
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((status) => {
          status.onchange = async () => {
            setNotificationState(
              status.state === "prompt" ? "default" : status.state
            );
            if (status.state === "granted") {
              try {
                if (getNotificationState() !== "granted") return;
                const token = await getToken(messaging as Messaging, {
                  vapidKey
                });
                if (token && loginToken) {
                  await mutateAsync({
                    token,
                    device_id: deviceId ?? "No Device ID",
                    app_id: isTwaApp() ? "P" : "PW"
                  });
                } else {
                  console.error("No registration token available.");
                }
              } catch (error) {
                console.error(
                  "An error occurred while retrieving token.",
                  error
                );
              }
            }
          };
        })
        .catch((error) => {
          console.error("Error querying notification permission:", error);
        });
    } else {
      console.warn("Permissions API is not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (!getIsLoggedIn() || isTwaApp()) return;
    const submitPushToken = async () => {
      if (permissionState === "granted") {
        try {
          if (getNotificationState() !== "granted") return;
          const token = await getToken(messaging as Messaging, {
            vapidKey
          });
          if (token && loginToken) {
            await mutateAsync({
              token,
              device_id: deviceId ?? "No Device ID",
              app_id: isTwaApp() ? "P" : "PW"
            });
          } else {
            console.error("No registration token available.");
          }
        } catch (error) {
          console.error("An error occurred while retrieving token.", error);
        }
      }
    };
    if (permissionState === "granted") {
      submitPushToken();
    }
  }, [permissionState]);

  return (
    <>
      {children}
      <BottomSheetModal
        open={showNotifModal}
        onClose={() => setShowNotifModal(false)}
      >
        <BottomSheetHeader title="دریافت نوتیفیکیشن" />
        <DialogContent>
          <Typography fontSize="0.875rem" fontWeight="400">
            برای دریافت پیام‌ها لازم است که امکان دریافت پیام در اپلیکیشن را
            فعال کنید.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            justifyContent: "center"
          }}
        >
          <Button
            sx={{
              fontSize: "0.875rem",
              fontWeight: "400"
            }}
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => {
              setValueToLocalStorage(NOTIFICATION_PERMISSION, "postponed");
              setShowNotifModal(false);
            }}
          >
            انصراف
          </Button>
          <Button
            onClick={() => {
              requestPermission().then((res) => {
                setValueToLocalStorage(
                  NOTIFICATION_PERMISSION,
                  res ? "granted" : "postponed"
                );
                setShowNotifModal(false);
              });
            }}
            sx={{
              fontSize: "0.875rem",
              fontWeight: "400"
            }}
            fullWidth
          >
            فعال‌سازی
          </Button>
        </DialogActions>
      </BottomSheetModal>
    </>
  );
};

export default AppPushNotif;
