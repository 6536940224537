import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  SwipeableDrawer,
  Typography,
  Button,
  Stack,
  CircularProgress,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useErrorHandler from "@/hooks/useErrorHandler";
import toFarsiNumberText from "@/utils/toFarsiNumberText";

import {
  useGetCalculatePrice,
  useGetSaleDetailV2,
  usePostNegotiatedPrice
} from "@/services/api/sales";
import { toEngDigits } from "@/utils/toEnglishDigit";
import { GlobalErrorModel } from "@/utils/error-handling";
import { AxiosError } from "axios";

interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  title: string;
  id: number;
}

interface PriceForm {
  price: number | null;
  advertisement_price: number | null;
  karnameh_commission: number | null;
  karnameh_commission_percentage?: number | null;
}

const fieldNames = ["karnameh_commission"];

const AgreedPriceModal: FC<Props> = ({ open, onOpen, onClose, title, id }) => {
  const [formData, setFormData] = useState<PriceForm>({
    price: 0,
    advertisement_price: 0,
    karnameh_commission: 0,
    karnameh_commission_percentage: 0
  });

  const [isChanged, setIsChanged] = useState(false);
  const { data: detailData } = useGetSaleDetailV2(Number(id));
  const { data, refetch } = useGetCalculatePrice(
    formData.price,
    formData.karnameh_commission ? formData.karnameh_commission : 0,
    id as number
  );
  const {
    mutateAsync,
    isLoading,
    error: postNegotiatiedPriceError
  } = usePostNegotiatedPrice(id);

  const errorsList = useMemo(
    () => [
      ((postNegotiatiedPriceError as AxiosError) || undefined)?.response?.data
    ],
    [postNegotiatiedPriceError]
  );

  const { errors, resolveAll, resolveField } = useErrorHandler(
    errorsList as GlobalErrorModel[],
    fieldNames
  );

  useEffect(() => {
    if (detailData?.price_agreements.length) {
      setFormData(detailData?.price_agreements[0]);
    }
  }, [detailData, id]);

  useEffect(() => {
    if (data?.karnameh_commission || isChanged) {
      setFormData(data as PriceForm);
    }
  }, [data, isChanged]);

  const changeHandler = (event: {
    target: { name: string; value: number | string };
  }) => {
    setFormData((f) => ({
      ...f,
      advertisement_price: 0,
      karnameh_commission_percentage: 0,
      [event.target.name]: event.target.value
      // [event.target.name === "price" ? "advertisement_price" : "price"]: 0
    }));
    resolveField(event.target.name);
  };

  const submitHandler = () => {
    resolveAll();
    mutateAsync(formData).then(() => {
      refetch();
      onClose();
    });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3
          }}
        >
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={onClose} />
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ width: "100%" }}
            label="سهم فروشنده"
            value={formData.price || ""}
            name="price"
            onChange={changeHandler}
            variant="outlined"
            size="small"
            type="tel"
            inputProps={{ inputMode: "numeric" }}
          />

          <Typography
            variant="body1"
            sx={{ mt: "5px", mb: "20px", fontSize: "13px" }}
          >
            {formData.price
              ? `${toFarsiNumberText(
                  parseInt(toEngDigits(String(formData.price)))
                )}`
              : ""}
          </Typography>

          <TextField
            sx={{ width: "100%", mb: errors.karnameh_commission.error ? 1 : 0 }}
            label="سهم کارنامه"
            value={formData.karnameh_commission || ""}
            name="karnameh_commission"
            onChange={changeHandler}
            variant="outlined"
            size="small"
            type="tel"
            inputProps={{ inputMode: "numeric" }}
            {...errors.karnameh_commission}
          />

          {!errors.karnameh_commission.error && (
            <Typography
              variant="body1"
              sx={{ mt: "5px", mb: "20px", fontSize: "13px" }}
            >
              {formData.karnameh_commission
                ? `${toFarsiNumberText(
                    parseInt(toEngDigits(String(formData.karnameh_commission)))
                  )} ${
                    formData?.karnameh_commission_percentage
                      ? `(${formData.karnameh_commission_percentage} درصد)`
                      : ""
                  }`
                : ""}
            </Typography>
          )}

          <TextField
            sx={{ width: "100%" }}
            disabled
            label="قیمت فروش توافقی (آگهی)"
            value={formData?.advertisement_price || ""}
            name="advertisement_price"
            InputProps={{
              readOnly: true
            }}
            onChange={changeHandler}
            variant="outlined"
            size="small"
            type="tel"
            inputProps={{ inputMode: "numeric" }}
          />

          <Typography
            variant="body1"
            sx={{ mt: "5px", mb: "20px", fontSize: "13px" }}
          >
            {formData.advertisement_price
              ? `${toFarsiNumberText(
                  parseInt(toEngDigits(String(formData.advertisement_price)))
                )}`
              : ""}
          </Typography>

          <Stack
            sx={{
              mb: 2,
              mt: 3,
              textAlign: "center",
              borderBottom: "1px solid #EBEBEB",
              pb: 3
            }}
          >
            <Button
              onClick={() => {
                setIsChanged(true);
                refetch().finally(() => setIsChanged(false));
              }}
              component="span"
              sx={{ fontWeight: "bold", color: "#00A754" }}
              variant="text"
            >
              محاسبه قیمت
            </Button>
          </Stack>

          <Stack sx={{ flexDirection: "row", alignItems: "center", mb: 10 }}>
            <Typography fontSize={18}>
              {detailData?.is_leasing_available
                ? "امکان فروش اقساطی دارد."
                : "امکان فروش اقساطی ندارد."}
            </Typography>
          </Stack>

          <Stack
            sx={{
              position: "absolute",
              mb: 2,
              bottom: 0,
              left: 0,
              right: 0,
              px: 2
            }}
          >
            <Button
              sx={{ width: "100%" }}
              disabled={Boolean(!formData?.advertisement_price) || isLoading}
              onClick={submitHandler}
              size="large"
            >
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: "#00000050" }} />
              ) : (
                "ثبت تغییرات و ارسال پیامک"
              )}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default AgreedPriceModal;
