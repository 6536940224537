import { useRootLayout } from "@/hooks/useRootLayout";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  GetSalesExpertReport,
  GetSalesExpertReportResponse,
  useGetSalesExpertDailyScore,
  useGetSalesExpertMonthlyScore,
  useGetSalesExpertReport
} from "@/services/api/concierge-sale/sales-expert-report";
import LevelTab from "./components/LevelTabs";
import UserPerformanceByLevel from "./components/UserPerformanceByLevel";
import SingleBarChart from "./components/charts/SingleBarChart";
import MixBarChart from "./components/charts/MixBarChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DataLineChart from "./components/charts/DataLineChart";
import { useSearchParams } from "react-router-dom";
import BottomSheetModal from "@/components/BottomSheetModal";
import BottomSheetHeader from "@/components/BottomSheetHeader";

const AssistantPerformanceReport = () => {
  const [searchParams] = useSearchParams();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const { setHeaderTitle } = useRootLayout();
  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("گزارش عملکرد");
    }
  }, [setHeaderTitle]);

  const [userlevelMonthly, setUserLevelMonthly] =
    useState<GetSalesExpertReport["offerLevel"]>(1);

  const [userlevelDaily, setUserLevelDaily] =
    useState<GetSalesExpertReport["offerLevel"]>(1);

  const { data, isLoading } = useGetSalesExpertReport({
    offerLevel: userlevelMonthly,
    salesExpertId: searchParams.get("id")
  });

  const { data: monthlyData } = useGetSalesExpertMonthlyScore(
    searchParams.get("id") ?? ""
  );

  const { data: dailyData } = useGetSalesExpertDailyScore({
    offerLevel: userlevelDaily,
    salesExpertId: searchParams.get("id")
  });

  const convertToChartData = (data?: {
    [key: string]: GetSalesExpertReportResponse;
  }) => {
    if (!data) return [];
    return Object.entries(data).map(([key, value]) => ({
      month: key,
      deal_count: value.deal_count,
      expert_commission_amount: value.expert_commission_amount,
      deal_amount: value.total_deal_amount - value.expert_commission_amount,
      total_deal_amount: value.total_deal_amount
    }));
  };

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      overflow={"auto"}
      gap={1}
      sx={{ background: (th) => th.palette.background.default }}
    >
      <Stack
        bgcolor={"white"}
        width={"100%"}
        px={2}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          py={2}
          gap={1}
        >
          <DashboardIcon color="action" />
          <Typography fontSize={"14px"} fontWeight={600}>
            آمار لیدهای ۵ روز اخیر
          </Typography>
        </Stack>
        <Divider />
        <Stack gap={1} py={2} px={1}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={"12px"} fontWeight={400}>
              تعداد لیدهای اعلام آمادگی شده
            </Typography>
            <Typography fontSize={"16px"} fontWeight={600}>
              {data?.offer_approve_called ?? 0}
            </Typography>
          </Stack>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={"12px"} fontWeight={400}>
              تعداد لیدهای دریافتی{" "}
            </Typography>
            <Typography fontSize={"16px"} fontWeight={600}>
              {data?.offer_approve_accepted ?? 0}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        bgcolor={"white"}
        width={"100%"}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack px={2}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              width={"100%"}
              py={2}
              gap={1}
            >
              <DashboardIcon color="action" />
              <Typography fontSize={"14px"} fontWeight={600}>
                عملکرد شما در هر سطح{" "}
              </Typography>
            </Stack>
            <Button variant="text" onClick={() => setOpenDetailsModal(true)}>
              <InfoOutlinedIcon color="action" />
            </Button>
          </Stack>
          <Divider />
        </Stack>
        <LevelTab value={userlevelMonthly} setValue={setUserLevelMonthly} />
        <UserPerformanceByLevel data={data} isLoading={isLoading} />
      </Stack>
      <Stack
        bgcolor={"white"}
        width={"100%"}
        px={2}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          py={2}
          gap={1}
        >
          <BarChartIcon color="action" />
          <Typography fontSize={"14px"} fontWeight={600}>
            تعداد فروش (۳ ماه اخیر){" "}
          </Typography>
        </Stack>
        <Divider />
        <Stack width={"100%"}  >
          <SingleBarChart data={convertToChartData(monthlyData)} />
        </Stack>
      </Stack>
      <Stack
        bgcolor={"white"}
        width={"100%"}
        px={2}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          py={2}
          gap={1}
        >
          <BarChartIcon color="action" />
          <Typography fontSize={"14px"} fontWeight={600}>
            مبلغ فروش (۳ ماه اخیر)
          </Typography>
        </Stack>
        <Divider />
        <Stack width={"100%"} height={"100%"}>
          <MixBarChart data={convertToChartData(monthlyData)} />
        </Stack>
      </Stack>
      <Stack
        bgcolor={"white"}
        width={"100%"}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack px={4}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              width={"100%"}
              py={2}
              gap={1}
            >
              <DashboardIcon color="action" />
              <Typography fontSize={"14px"} fontWeight={600}>
              روند تغییر امتیاز آفر در ۲۴ ساعت اخیر              </Typography>
            </Stack>
          </Stack>
          <Divider />
        </Stack>
        <LevelTab value={userlevelDaily} setValue={setUserLevelDaily} />
        <DataLineChart data={dailyData} />
      </Stack>
      <BottomSheetModal
        open={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
      >
        <BottomSheetHeader title={"نحوه‌ محاسبه وزن"}></BottomSheetHeader>
        <Stack px={4} py={2}>
          <Typography fontSize={"14px"} fontWeight={400}>
            هر چه خودرو گران‌تر و کارکرد سالانه آن کمتر باشد، وزن بیشتری خواهد
            داشت.
          </Typography>
        </Stack>
      </BottomSheetModal>
    </Stack>
  );
};

export default AssistantPerformanceReport;
