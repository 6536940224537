import createStyle from "@/utils/createStyle";

const styles = createStyle({
  container: {
    width: '100%',
    position: { xs: 'fixed', md: 'absolute' },
    bottom: 0,
    px: '1rem',
    py: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderTop: (th) => `1px solid ${th.palette.surface.paper}`,
    backgroundColor: (th) => th.palette.surface.default,
  },
  cancelButton: {
    flexGrow: 1,
    flexBasis: '50%',
    mr: '0.5rem',
  },
  submitButton: {
    flexGrow: 1,
    flexBasis: '50%',
    ml: '0.5rem',
  },
});

export default styles;
