import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './styles';

interface PropTypes {
  open: boolean;
  onClose: () => void;
}

const Layout: FC<PropsWithChildren<PropTypes>> = (props) => {
  const { open, onClose, children } = props;

  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () => {
        setShow(open);
      },
      open ? 50 : 300,
    );
  }, [open]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (open && show) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'unset';
      }
    }
  }, [open, show]);

  const portalElm =
    typeof window !== 'undefined' ? document.getElementById('portal') : null;

  if ((!open && !show) || !portalElm) {
    return null;
  }

  const isVisible = open && show;

  return createPortal(
    <>
      <Box
        sx={styles.backdrop}
        component="div"
        onClick={onClose}
        style={{
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'all' : 'none',
        }}
      />
      <Stack
        sx={styles.container}
        style={{
          opacity: isVisible ? 1 : 0,
          pointerEvents: isVisible ? 'all' : 'none',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <Stack sx={styles.header}>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
            <Typography ml="0.5rem">انتخاب شهر</Typography>
          </Stack>

          {children}
        </Stack>
      </Stack>
    </>,
    portalElm,
  );
};

export default Layout;
