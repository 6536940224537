import styled from "@emotion/styled";
import { MailOutlined } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";

const StyledTableRow = styled(TableRow)(() => ({
  border: "none",
  backgroundColor: "#FAFAFA",
  "&:nth-of-type(odd)": {
    backgroundColor: "white"
  }
}));

interface RowItem {
  right: React.ReactNode;
  left: React.ReactNode;
}

const CarFeedbackCard = ({
  title,
  row
}: {
  title: string;
  row?: RowItem[];
}) => {
  if (!row || !row.length) return null;

  return (
    <Stack
      border={"1px solid #EBEBEB"}
      borderRadius={"8px"}
      overflow={"hidden"}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}
        py={2}
        mx={2}
        borderBottom={"1px solid #EBEBEB"}
      >
        <MailOutlined color="secondary" fontSize="small" />
        <Typography fontSize={"14px"} fontWeight={600}>
          {title}
        </Typography>
      </Stack>

      <Stack>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", border: "none" }}
        >
          <Table aria-label="customized table">
            <TableBody>
              {row.map((item, i) => {
                return (
                  <StyledTableRow key={i + String(item.right)}>
                    <TableCell>{item.right}</TableCell>
                    <TableCell align="right">{item.left}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default CarFeedbackCard;
