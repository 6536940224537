import { useRootLayout } from "@/hooks/useRootLayout";
import { Divider, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import HorizontalMixBarChart from "./components/charts/HorizontalMixBarChart";
import { useGetSupervisorTargetChart } from "@/services/api/concierge-sale/sales-expert-report";
import AssistantsTabel from "./components/AssistantsTabel";

const SupervisorPerformanceReport = () => {
  const { setHeaderTitle } = useRootLayout();

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("گزارش عملکرد");
    }
  }, [setHeaderTitle]);

  const { data: supervisorTargetChart } = useGetSupervisorTargetChart();

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      overflow={"auto"}
      gap={1}
      sx={{ background: (th) => th.palette.background.default }}
    >
      <Stack
        bgcolor={"white"}
        width={"100%"}
        px={2}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          py={2}
          gap={1}
        >
          <BarChartIcon color="action" />
          <Typography fontSize={"14px"} fontWeight={600}>
            میزان فروش دوره جاری{" "}
          </Typography>
        </Stack>
        <Divider />
        <Stack width={"100%"} height={"100%"}>
          <HorizontalMixBarChart data={supervisorTargetChart} />
        </Stack>
      </Stack>
      <Stack
        bgcolor={"white"}
        width={"100%"}
        sx={{
          borderBottom: "1px solid #EBEBEB",
          borderTop: "1px solid #EBEBEB"
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          py={2}
          px={2}
          gap={1}
        >
          <BarChartIcon color="action" />
          <Typography fontSize={"14px"} fontWeight={600}>
            میزان فروش هر دستیار{" "}
          </Typography>
        </Stack>
        <Divider />
        <Stack width={"100%"} height={"100%"}>
          <AssistantsTabel />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SupervisorPerformanceReport;
