import { httpAPIGW } from "@/services/http-clients";
import { leasingEndpoints, restEndpoints } from "@/services/http-clients/configs";
import { UseQueryOptions, useQuery } from "react-query";

// GET CITIES
export interface City {
  id: number;
  name: string;
  en_name: string;
}

export interface GetCitiesResponse {
  data: City[];
}

export const getCitiesFn = async () => {
  const response = await httpAPIGW<GetCitiesResponse>({
    url: leasingEndpoints.CITIES
  });

  return response.data;
};

export const getCitiesKey = () => {
  return [leasingEndpoints.CITIES];
};

export const useGetCities = (options?: UseQueryOptions<GetCitiesResponse>) => {
  return useQuery({
    queryFn: getCitiesFn,
    queryKey: getCitiesKey(),
    ...options
  });
};

interface RestCitiesResponse {
  data: {
    pk: number;
    name: string,
    en_name: string;
  }[]
}

export const getRestCitiesFn = () => {
  return httpAPIGW<RestCitiesResponse>({
    method: 'GET',
    url: restEndpoints.CITIES
  }).then((res) => res.data)
}

export const restCitiesKey = [restEndpoints.CITIES]

export const useGetRestCities = () => {
  return useQuery({
    queryFn: () => getRestCitiesFn(),
    queryKey: restCitiesKey
  })
}