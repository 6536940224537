import { useRootLayout } from "@/hooks/useRootLayout";
import { useGetPostsStatistics } from "@/services/api/concierge-sale/dealers";
import createStyle from "@/utils/createStyle";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { DataLineChart } from "./DateLineChart";

const styles = createStyle({
  main: { padding: "0 1.5rem", width: "100%" },
  titleRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #EBEBEB",
    padding: "1rem 0"
  },
  title: { fontSize: "1rem", fontWeight: "600" },
  h2: { fontSize: "0.875rem" },
  h3: { fontSize: "13px", color: "#6E6E6E" },
  centerH3: { fontSize: "13px", color: "#6E6E6E", margin: "auto" },
  loading: { margin: "auto" },
  chartTitle: { fontSize: "0.875rem", fontWeight: "600", margin: "1rem 0" },
  bottomBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid #EBEBEB",
    padding: "1.5rem"
  }
});

const PostStatistics = () => {
  const params = useParams();
  const { setHeaderTitle } = useRootLayout();
  const { data, isLoading } = useGetPostsStatistics(
    { concierge_sale: params.id ? +params.id : -1 },
    {
      enabled: Boolean(params.id),
      onError: () => {
        toast.error("مشکل در دریافت اطلاعات آگهی");
      }
    }
  );
  const divarData = data?.find((item) => item.third_party === "D");

  useEffect(() => {
    setHeaderTitle("عملکرد آگهی دیوار");
  }, [setHeaderTitle]);

  return (
    <Stack sx={styles.main}>
      {isLoading ? (
        <CircularProgress color="secondary" sx={styles.loading} />
      ) : divarData?.total_views != undefined ? (
        <>
          <Stack sx={styles.titleRow}>
            <Typography sx={styles.title}>عملکرد آگهی دیوار</Typography>
          </Stack>
          <Stack sx={styles.titleRow}>
            <Typography sx={styles.h3}>تعداد بازدید</Typography>
            <Typography sx={styles.h2}>{divarData.total_views}</Typography>
          </Stack>
          <Typography sx={styles.chartTitle} fontWeight="600">
            نمودار بازدید روزانه
          </Typography>
          <DataLineChart
            data={divarData.dates.map((item, index) => ({
              x: new Date(item).toLocaleDateString("fa-IR", {
                month: "2-digit",
                day: "2-digit"
              }),
              y: divarData.values[index]
            }))}
          />
          <Stack sx={styles.bottomBar}>
            <Button href={`${divarData.link}`}>مدیریت آگهی</Button>
          </Stack>
        </>
      ) : (
        <Typography sx={styles.centerH3}>
          عملکرد این آگهی موجود نیست!
        </Typography>
      )}
    </Stack>
  );
};

export default PostStatistics;
