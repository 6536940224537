import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  SwipeableDrawer,
  Typography,
  Stack,
  Snackbar,
  IconButton,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  useGetSaleDetailV2,
  usePostAttachDivarAds
} from "@/services/api/sales";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
}

const InspectionInfoModal: FC<Props> = ({ title, showModal, open }) => {
  const { id } = useParams();
  const { data } = useGetSaleDetailV2(Number(id), { enabled: Boolean(id) });
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        sx={{
          maxHeight: "70dvh",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fafafa",
          overflowY: "scroll",
          p: 2.5
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3
          }}
        >
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "column" }}>
          {data?.inspections?.length ? (
            data?.inspections.map((item) => (
              <InspectionRequest key={item.id} {...item} />
            ))
          ) : (
            <Typography variant="body1" fontSize={14} sx={{ pb: 5 }}>
              کارشناسی برای خودرو انجام نشده است.
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          bgcolor: "#fff",
          py: 2,
          px: 2.5,
          boxShadow: "0px -1px 8px 0px #0000001F"
        }}
      >
        <Button
          onClick={() =>
            navigate(
              `/concierge-sale/requests/${id}/inspection-request/package`
            )
          }
          size="large"
        >
          ثبت درخواست کارشناس جدید
        </Button>
      </Stack>
    </SwipeableDrawer>
  );
};

export default InspectionInfoModal;

interface ExpertProps {
  id: number;
  name: string;
  phone_number: string;
  created_at: string;
  report_link: string;
  state: string;
}

const InspectionRequest: FC<ExpertProps> = ({
  id,
  name,
  phone_number,
  created_at,
  report_link,
  state
}) => {
  const [toast, setToast] = useState(false);
  const [attached, setAttached] = useState<string | null>(null);
  const { id: expertId } = useParams();
  const { mutateAsync: postAttachDivar } = usePostAttachDivarAds(
    Number(expertId),
    Number(id)
  );

  const copyToClipBoard = async (data: string) => {
    await navigator.clipboard.writeText(data).then(() => setToast(true));
  };

  const renderBadge = (state: string) => {
    switch (state) {
      case "U":
        return "نامشخص";
      case "R":
        return "درخواست جدید";
      case "A":
        return "تخصیص داده شد";
      case "C":
        return "تماس گرفته شد";
      case "O":
        return "تخصیص نهایی";
      case "P":
        return "جستجوی کارشناس";
      case "E":
        return "اعزام به محل";
      case "I":
        return "کارشناس رسید";
      case "S":
        return "شروع کارشناسی";
      case "D":
        return "تایید شد";
      case "Q":
        return "منتظر کنترل کیفی";
      default:
        return "وضعیت مشخص نشده است";
    }
  };

  return (
    <>
      <Stack
        sx={{
          bgcolor: "#fff",
          border: "1px solid #EBEBEB",
          borderRadius: "4px",
          p: 2,
          mb: 2
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #E2E2E2",
            pb: 1.5
          }}
        >
          <Typography component="span" fontSize="14px">
            تاریخ
          </Typography>
          {created_at ? (
            <Typography component="span" fontSize="14px">
              {new Date(created_at).toLocaleDateString("fa", {
                weekday: "long",
                day: "numeric",
                month: "long"
              })}
              {" - "}
              ساعت{" "}
              {new Date(created_at).toLocaleTimeString("fa", {
                hour: "numeric",
                minute: "numeric"
              })}
            </Typography>
          ) : (
            <Typography
              component="span"
              fontSize="14px"
              sx={{ color: "#6E6E6E" }}
            >
              ثبت نشده است
            </Typography>
          )}
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #E2E2E2",
            alignItems: "center",
            py: 1.5
          }}
        >
          <Stack sx={{ bgcolor: "#EBEBEB", px: 2, py: 0.5, borderRadius: 5 }}>
            <Typography fontSize={14}>{renderBadge(state)}</Typography>
          </Stack>
          <Typography component="span" fontSize="14px">
            {id}
          </Typography>
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: report_link ? "1px solid #E2E2E2" : 0,
            py: 0.5
          }}
        >
          {name ? (
            <>
              {" "}
              <Typography component="span" fontSize="14px">
                {name}
              </Typography>
              <Stack sx={{ flexDirection: "row" }}>
                <IconButton sx={{ ml: 1 }}>
                  <a
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                    href={`sms:${phone_number}`}
                  >
                    <ChatBubbleOutlineIcon
                      sx={{ color: "#6E6E6E", fontSize: "20px" }}
                    />
                  </a>
                </IconButton>
                <IconButton sx={{ ml: 1 }}>
                  <a
                    style={{ display: "flex", alignItems: "center" }}
                    href={`tel:${phone_number}`}
                  >
                    <PhoneIcon sx={{ color: "#6E6E6E", fontSize: "20px" }} />
                  </a>
                </IconButton>
              </Stack>
            </>
          ) : (
            <Typography
              component="span"
              fontSize="14px"
              sx={{ py: 1, color: "#6E6E6E" }}
            >
              کارشناس تعیین نشده است
            </Typography>
          )}
        </Stack>

        {report_link && (
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 1
            }}
          >
            <Typography component="span" fontSize="14px">
              گزارش کارشناسی
            </Typography>

            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <IconButton
                sx={{ ml: 1 }}
                onClick={() =>
                  postAttachDivar(null)
                    .then(() => setAttached("success"))
                    .catch(() => setAttached("fail"))
                }
              >
                <LinkOutlinedIcon sx={{ fontSize: "19px", color: "#6E6E6E" }} />
              </IconButton>

              <IconButton
                sx={{ ml: 1 }}
                onClick={() => window.open(report_link, "_parent")}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "19px", color: "#6E6E6E" }}
                />
              </IconButton>
              <IconButton
                onClick={() => copyToClipBoard(report_link)}
                sx={{ ml: 1 }}
              >
                <ContentCopyIcon sx={{ color: "#6e6e6e", fontSize: "19px" }} />
              </IconButton>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Snackbar
        autoHideDuration={1000}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => setToast(false)}
        message="لینک گزارش کارشناسی با موفقیت کپی شد"
      />

      <Snackbar
        autoHideDuration={1500}
        ContentProps={{
          sx: {
            background: "#00a754"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={attached === "success" && Boolean(attached)}
        onClose={() => setAttached(null)}
        message="گزارش کارشناسی با موفقیت پیوست شد"
      />

      <Snackbar
        autoHideDuration={1500}
        ContentProps={{
          sx: {
            background: "#DF1D17"
          }
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={attached === "fail" && Boolean(attached)}
        onClose={() => setAttached(null)}
        message="خطایی در پیوست گزارش کارشناسی به وجود آمده"
      />
    </>
  );
};
