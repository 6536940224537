import { useRootLayout } from "@/hooks/useRootLayout";
import { queryClient } from "@/providers/AppQueryProvider";
import {
  getPostsStatisticKey,
  useGetPostsStatistics,
  useRegisterPostsUrl,
  useUpdatePostsUrl
} from "@/services/api/concierge-sale/dealers";
import createStyle from "@/utils/createStyle";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const styles = createStyle({
  main: { padding: "20px 1.5rem 0", width: "100%" },
  title: { fontSize: "0.75rem" },
  input: {
    marginTop: "1.25rem"
  },
  bottomBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid #EBEBEB",
    padding: "1.5rem"
  }
});

const RegisterPostAddress = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setHeaderTitle } = useRootLayout();
  const urlRef = useRef({ divar: "", bama: "" });

  const { data, isLoading, isRefetching, status } = useGetPostsStatistics(
    { concierge_sale: params.id ? +params.id : -1 },
    { enabled: Boolean(params.id) }
  );
  const { mutate: registerPostUrl } = useRegisterPostsUrl({
    onSuccess: () => {
      toast.success("لینک آگهی ذخیره شد");
    },
    onError: () => {
      toast.error("مشکلی در ذخیره لینک آگهی");
    }
  });
  const { mutate: updatePostUrl } = useUpdatePostsUrl({
    onSuccess: () => {
      toast.success("لینک آگهی به‌روز شد");
      queryClient.invalidateQueries(
        getPostsStatisticKey({
          concierge_sale: params.id ? +params.id : -1
        })
      );
    },
    onError: () => {
      toast.error("مشکلی در به‌روز رسانی لینک آگهی");
    }
  });

  const isDataLoading = (isLoading || isRefetching) && status !== "idle";

  const divarData = data?.find((item) => item.third_party == "D");
  const divarDataLink = divarData?.link;
  const bamaData = data?.find((item) => item.third_party == "B");
  const bamaDataLink = bamaData?.link;

  const submitHandler = () => {
    const divarUrl = urlRef.current.divar;
    const bamaUrl = urlRef.current.bama;
    const saleId = params.id;

    if (!saleId) return;
    if (divarUrl) {
      if (divarDataLink) {
        updatePostUrl({
          concierge_sale: +saleId,
          link: divarUrl,
          third_party: "D",
          id: divarData.id
        });
      } else {
        registerPostUrl({
          concierge_sale: +saleId,
          link: divarUrl,
          third_party: "D"
        });
      }
    }
    if (bamaUrl) {
      if (bamaDataLink) {
        updatePostUrl({
          concierge_sale: +saleId,
          link: bamaUrl,
          third_party: "B",
          id: bamaData.id
        });
      } else {
        registerPostUrl({
          concierge_sale: +saleId,
          link: bamaUrl,
          third_party: "B"
        });
      }
    }
    navigate(-1);
  };

  useEffect(() => {
    setHeaderTitle("لینک آگهی‌ها");
  }, [setHeaderTitle]);

  return (
    <Stack sx={styles.main}>
      <Typography sx={styles.title}>لطفا لینک آگهی‌ها را وارد کنید:</Typography>
      {isDataLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <TextField
            sx={styles.input}
            label="لینک آگهی دیوار"
            size="small"
            defaultValue={divarDataLink}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              urlRef.current.divar = event.target.value;
            }}
          />
          <TextField
            sx={styles.input}
            label="لینک آگهی باما"
            size="small"
            defaultValue={bamaDataLink}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              urlRef.current.bama = event.target.value;
            }}
          />
        </>
      )}
      <Stack sx={styles.bottomBar}>
        <Button onClick={submitHandler}>ثبت لینک آگهی‌ها</Button>
      </Stack>
    </Stack>
  );
};

export default RegisterPostAddress;
