import { FC, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import ConciergeSaleIcon from "@/assets/images/car-sale-icon.svg";
import { conciergeSaleMenu } from "@/pages/ConciergeSale/config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { usePostDivarPost } from "@/services/api/concierge-sale/divar-post";

const ConciergeSaleIntro: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const navigate = useNavigate();

  const { mutateAsync } = usePostDivarPost();

  const [isLoggedIn, setIsLoggedIn] = useState("");

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("دستیار فروش");
    }
  }, [setHeaderTitle]);

  useEffect(() => {
    if (!isLoggedIn)
      mutateAsync({
        action: "check_token_expiration"
      }).then((res) => {
        setIsLoggedIn(res?.data?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        p: 3,
        height: "100vh",
        overflowY: "scroll",
        bgcolor: "#FAFAFA"
      }}
    >
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        <img
          style={{ width: 220 }}
          src={ConciergeSaleIcon}
          alt="Concierge Sale Icon"
        />
      </Stack>
      <Stack sx={{ mt: 3 }}>
        {conciergeSaleMenu.map(
          ({ title, icon: Icon, path, is_external_link, is_login_divar }) => (
            <Stack
              onClick={
                is_external_link
                  ? () => window.open(path, "_parent")
                  : is_login_divar && isLoggedIn !== "نیازمند ورود"
                  ? () => null
                  : () => navigate(path)
              }
              sx={{
                background: "#fff",
                mb: 2,
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
                p: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer"
              }}
              key={title}
            >
              <Stack sx={{ flexDirection: "row" }}>
                {Icon && <Icon sx={{ mr: 1, color: "#6E6E6E" }} />}
                <Typography>{title}</Typography>
              </Stack>
              {is_login_divar && isLoggedIn !== "نیازمند ورود" ? (
                <Typography
                  fontSize="12px"
                  color={(th) => th.palette.iconDeactive.main}
                >
                  {isLoggedIn}
                </Typography>
              ) : is_login_divar ? (
                <Stack direction="row" alignItems="center">
                  <Typography
                    fontSize="12px"
                    color={(th) => th.palette.iconDeactive.main}
                  >
                    {isLoggedIn}
                  </Typography>
                  <KeyboardArrowLeftIcon sx={{ color: "#323232" }} />
                </Stack>
              ) : (
                <KeyboardArrowLeftIcon sx={{ color: "#323232" }} />
              )}
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};

export default ConciergeSaleIntro;
